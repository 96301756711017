/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Facebook24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.78 13.25L17.34 9.65625H13.78V7.3125C13.78 6.29688 14.26 5.35938 15.86 5.35938H17.5V2.27344C17.5 2.27344 16.02 2 14.62 2C11.7 2 9.78 3.75781 9.78 6.88281V9.65625H6.5V13.25H9.78V22H13.78V13.25H16.78Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Facebook24,
  sm: Facebook24,
  lg: Facebook24,
  xl: Facebook24,
  xxl: Facebook24,
  xxxl: Facebook24,
};

export const FacebookIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'facebook-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
