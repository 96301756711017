import { useEffect, useMemo } from 'react';

import { useAlgoliaMenuProducts } from '@jane/search/hooks';
import type { AppMode, Id, MenuProduct } from '@jane/shared/models';

import type { BaseCartTopper } from '../../data-access/fetchCartToppers';
import { trackCartTopperMissingInAlgolia } from '../../mixpanel/events';
import { trackPriceExceptions } from './priceChecking';
import { useFetchCartToppers } from './queries';

interface Params {
  appMode: AppMode;
  cartProductIds: number[];
  fetchOnce?: boolean;
  janeDeviceId: string;
  maxProducts?: number;
  storeId: Id;
}
export interface CartTopper {
  menuProduct: MenuProduct;
}

export const useCartToppers = ({
  appMode,
  janeDeviceId,
  storeId,
  cartProductIds,
  maxProducts = 16,
  fetchOnce = true,
}: Params): CartTopper[] => {
  const { data = { cartToppers: [], maxPrice: 0 } } = useFetchCartToppers({
    appMode,
    cartProductIds,
    fetchOnce,
    janeDeviceId,
    maxProducts,
    storeId,
  });
  const rankedProducts = data.cartToppers;
  const { maxPrice } = data;
  const productIds = useMemo(
    () => rankedProducts.map((rankedProduct) => rankedProduct.product_id),
    [rankedProducts]
  );
  const menuProducts = useAlgoliaMenuProducts({ productIds, storeId });

  const cartToppers = useMemo(
    () =>
      menuProducts ? buildCartToppers({ menuProducts, rankedProducts }) : [],
    [menuProducts, rankedProducts]
  );

  useEffect(() => {
    trackPriceExceptions({
      cartToppers: cartToppers.map((cartTopper) => cartTopper.menuProduct),
      maxPrice,
      storeId,
    });
  }, [cartToppers, maxPrice, storeId]);

  return cartToppers || [];
};

const buildCartToppers = ({
  menuProducts,
  rankedProducts,
}: {
  menuProducts: MenuProduct[];
  rankedProducts: BaseCartTopper[];
}): CartTopper[] => {
  const cartToppers: CartTopper[] = [];
  rankedProducts?.forEach((rankedProduct) => {
    const index = menuProducts.findIndex(
      (menuProduct) => rankedProduct.product_id === menuProduct.id
    );
    if (index !== -1) {
      const menuProduct = menuProducts[index];
      const { flight, dmMeta } = rankedProduct;
      const menuProductWithFlightAndDmMeta: MenuProduct = {
        ...menuProduct,
        dmMeta,
        flight,
      };
      cartToppers.push({
        menuProduct: menuProductWithFlightAndDmMeta,
      });
    } else {
      trackCartTopperMissingInAlgolia({
        adToken: rankedProduct.dmMeta?.adToken,
        flightId: rankedProduct.flight?.id,
        productId: rankedProduct.product_id,
      });
    }
  });
  return cartToppers;
};
