import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import { Box, Button, JaneGoldIcon, Typography } from '@jane/shared/reefer';

import type { JaneGoldPdpCalloutProps } from '../janeGoldCallout.types';
import { JaneGoldLearnMoreLink } from '../janeGoldLearnMoreLink';
import { JaneGoldTermsLink } from '../janeGoldTermsLink';
import {
  StyledPdpContainer,
  StyledPdpDescriptionWrapper,
  StyledPdpInnerContainer,
  StyledPdpTitleDescriptionWrapper,
} from './janeGoldPdpCallout.styles';

/**
 * PDP variant of the Jane Gold callout action component. If the user doesn't have a linked bank, it will display a description, `Learn more` link and `Link bank account` button. If the user has a linked bank, it will only display the title.
 */
export const JaneGoldPdpCallout = ({
  description,
  onLinkAccountClick,
  title,
}: JaneGoldPdpCalloutProps) => {
  const { isGuestUser, userHasLinkedBank } = useJaneGoldData();

  return (
    <Box p={24} borderRadius="lg" background="grays-ultralight">
      <StyledPdpContainer alignItems="center">
        <StyledPdpInnerContainer alignItems="center">
          <JaneGoldIcon altText="jane-gold-icon" size="md" />
          <StyledPdpTitleDescriptionWrapper
            userHasLinkedBank={userHasLinkedBank}
            flexDirection="column"
          >
            <Typography variant="body-bold">{title}</Typography>
            {!userHasLinkedBank && (
              <StyledPdpDescriptionWrapper>
                <Typography as="span" variant="body" color="grays-mid">
                  {description}&nbsp;
                  <JaneGoldLearnMoreLink
                    userHasLinkedBank={userHasLinkedBank}
                  />
                  &nbsp;
                  {isGuestUser && <JaneGoldTermsLink />}
                </Typography>
              </StyledPdpDescriptionWrapper>
            )}
          </StyledPdpTitleDescriptionWrapper>
        </StyledPdpInnerContainer>

        {!userHasLinkedBank && (
          <Box width={165}>
            <Button
              onClick={onLinkAccountClick}
              full
              variant="secondary"
              label="Link bank account"
            />
          </Box>
        )}
      </StyledPdpContainer>
    </Box>
  );
};
