import { useEffect } from 'react';

import { useCustomerSelector } from '../../../../customer';
import { useSource } from '../../../../hooks/useSource';
import { getCartLineItems } from '../../../../lib/getCartLineItems';
import { getPosCartSource } from '../../../../sources/posCart';

export const useGetCartLineItems = () => {
  const { cart, deliveryAddress } = useCustomerSelector(
    ({ cart: { cart, deliveryAddress } }) => ({
      cart,
      deliveryAddress,
    })
  );

  const { data: posCartData, fetch } = useSource(
    getPosCartSource(cart.uuid, deliveryAddress)
  );

  const cartLineItems = getCartLineItems({
    cart,
    posCartTotals: posCartData?.pos_cart?.totals,
    tipAmount: parseFloat(cart.tip_amount),
    roundingAmount: posCartData?.pos_cart?.totals?.rounding_amount,
  });

  useEffect(() => {
    fetch();
  }, [cart]);

  return cartLineItems;
};
