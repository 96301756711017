/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Tag24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.41418 9.41431C8.51875 9.41431 9.41418 8.51888 9.41418 7.41431C9.41418 6.30974 8.51875 5.41431 7.41418 5.41431C6.30962 5.41431 5.41418 6.30974 5.41418 7.41431C5.41418 8.51888 6.30962 9.41431 7.41418 9.41431Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4142 15.2427C23.9763 13.6806 23.9763 11.148 22.4142 9.58588L14.4142 1.58588C13.664 0.835736 12.6466 0.414307 11.5858 0.414307H4.41418C2.20505 0.414307 0.414185 2.20517 0.414185 4.41431V11.5859C0.414185 12.6467 0.835612 13.6642 1.58576 14.4143L9.58576 22.4143C11.1479 23.9764 13.6805 23.9764 15.2426 22.4143L22.4142 15.2427ZM13.8284 21.0001C13.0473 21.7811 11.781 21.7811 11 21.0001L2.99997 13.0001C2.6249 12.625 2.41418 12.1163 2.41418 11.5859V4.41431C2.41418 3.30974 3.30962 2.41431 4.41418 2.41431H11.5858C12.1162 2.41431 12.6249 2.62502 13 3.00009L21 11.0001C21.781 11.7811 21.781 13.0475 21 13.8285L13.8284 21.0001Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Tag24,
  sm: Tag24,
  lg: Tag24,
  xl: Tag24,
  xxl: Tag24,
  xxxl: Tag24,
};

export const TagIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'tag-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
