import { useQuery } from '@tanstack/react-query';
import type { ZodObject, ZodRawShape } from 'zod';

import type { SearchOptions } from '@jane/search/types';

import { search } from './client';

type FacetOptions = Pick<
  SearchOptions,
  | 'aroundLatLng'
  | 'aroundPrecision'
  | 'aroundRadius'
  | 'optionalFilters'
  | 'userToken'
>;

export interface FacetProps<T extends ZodRawShape> {
  enabled?: boolean;
  indexPrefix?: string;
  options?: FacetOptions;
  requestedFacets?: string[];
  schemaObject?: ZodObject<T>;
  searchText?: string;
  staticFilters?: string;
}

export const facetQueryBody = <T extends ZodRawShape>(
  params: FacetProps<T>
) => ({
  queryFn: () => fetchAlgoliaFacets(params),
  queryKey: ['facets', JSON.stringify(params)],
  enabled: params.enabled,
});

export function useFacets<T extends ZodRawShape>(params: FacetProps<T>) {
  return useQuery(facetQueryBody(params));
}

export function useGoldFacets<T extends ZodRawShape>(params: FacetProps<T>) {
  const { data: facets, isLoading } = useQuery(facetQueryBody(params));

  let finalFacets = facets;
  if (finalFacets && !finalFacets['has_brand_discount']) {
    finalFacets = { has_brand_discount: { true: 0 } };
  }

  return { data: finalFacets, isLoading };
}

export async function fetchAlgoliaFacets<T extends ZodRawShape>({
  indexPrefix,
  options,
  requestedFacets = ['*'],
  schemaObject,
  searchText,
  staticFilters,
  ...searchState
}: FacetProps<T>) {
  const { facets } = await search<T>({
    facets: requestedFacets,
    indexPrefix: indexPrefix ?? 'menu-products-',
    options,
    searchText,
    staticFilters,
    ...searchState,
  });

  return schemaObject ? await schemaObject.parseAsync(facets) : facets;
}
