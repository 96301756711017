import { useLocation } from 'react-router-dom';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';

// If you have a page or component that should not show these server notifications,
// add the logic here and then the final boolean check as an OR chain in hideNotifications
export const useHideNotifications = () => {
  const location = useLocation();
  const janePay = useFlag(FLAGS.janePay);

  const isCheckout = ['/cart/checkout', '/cart/guest_checkout'].some((url) =>
    location.pathname.includes(url)
  );

  const hideOnNewCheckoutPage = janePay && isCheckout;

  const hideNotifications = hideOnNewCheckoutPage;

  return hideNotifications;
};
