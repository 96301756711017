import { useEffect } from 'react';

import { useJaneUser } from '@jane/shared/data-access';
import {
  Flex,
  Form,
  Link,
  Typography,
  emailRegex,
  useFormContext,
} from '@jane/shared/reefer';

import { ContinueWith } from '../components/continueWith';

interface SignInProps {
  error: string | null;

  /** In a blessed state, the user has a log out button */
  logOut?(): void;

  /** Function to open Apple login when button is clicked */
  onAppleClick(): void;

  /** Function to navigate to forgot password screen when link is clicked */
  onForgotPassword(): void;

  /** Function to open Google login when button is clicked */
  onGoogleClick(): void;

  /** Function passed to determine what happens when sign in issubmitted */
  onSubmit(user: SignInFormData): void;

  /** Boolean to determine if forgot password screen was visited before this */
  resetPassword: boolean;

  /** Boolean values checking Boost menu configuration */
  showAppleLogin: boolean;
  showGoogleLogin: boolean;
}

export type SignInFormData = {
  email: string;
  password: string;
};

const EmailField = () => {
  const { data: userData } = useJaneUser();
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('email', userData?.user.email || '');
  }, [userData?.user.email, setValue]);

  return (
    <Form.TextField
      type="email"
      autocomplete="off"
      label="Email"
      name="email"
      required
      errorMessage="Please enter a valid email address."
      errorOnBlur
      pattern={emailRegex}
      disableMobileInputStyling
      defaultValue={userData?.user.email || ''}
      my={24}
    />
  );
};

export const SignIn = ({
  error,
  logOut,
  onSubmit,
  onAppleClick,
  onGoogleClick,
  onForgotPassword,
  resetPassword,
  showAppleLogin,
  showGoogleLogin,
}: SignInProps) => {
  const { data: userData } = useJaneUser();

  return (
    <Form name="sign-in" onSubmit={onSubmit}>
      <Flex width="100%" flexDirection="column" alignItems="center">
        {resetPassword ? (
          <Typography textAlign="center" mb={24} data-testid="check-email">
            Check your email for password reset instructions.
          </Typography>
        ) : null}
        {(showAppleLogin || showGoogleLogin) && (
          <ContinueWith
            onAppleClick={onAppleClick}
            onGoogleClick={onGoogleClick}
            showAppleLogin={showAppleLogin}
            showGoogleLogin={showGoogleLogin}
          />
        )}
        <Form.ErrorBanner />
        <EmailField />
        <Form.TextField
          autocomplete="off"
          disableMobileInputStyling
          label="Password"
          mb={24}
          name="password"
          pattern={/.*/}
          required
          type="password"
        />
        {error && (
          <Typography color="error" mt={-12} mb={12}>
            {typeof error === 'string'
              ? error
              : 'There was an error logging in.'}
          </Typography>
        )}
        <Form.SubmitButton
          full
          variant="primary"
          label="Sign in"
          data-testid="login-submit-btn"
        />
        <Link mt={16} mb={24} onClick={onForgotPassword}>
          Forgot password
        </Link>
        <Typography variant="body" textAlign="center" color="grays-mid">
          Jane keeps personal information safe and secure.
        </Typography>
        {userData?.user?.id && logOut && (
          <Typography as="span" mt={16}>
            Not {userData.user.nickname}?&nbsp;
            <Link onClick={logOut}>Log out</Link>
          </Typography>
        )}
      </Flex>
    </Form>
  );
};
