/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Verified24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5866 14.9402L24 11.8253L21.5866 8.71039L21.7082 4.77185L17.9249 3.67038L15.7082 0.412598L12 1.74528L8.2918 0.412598L6.07512 3.67038L2.2918 4.77185L2.41335 8.71038L0 11.8253L2.41335 14.9402L2.2918 18.8787L6.07512 19.9802L8.29179 23.238L12 21.9053L15.7082 23.238L17.9249 19.9802L21.7082 18.8787L21.5866 14.9402ZM17.7032 9.53628C18.0959 9.14792 18.0994 8.51476 17.711 8.12209C17.3226 7.72941 16.6895 7.72591 16.2968 8.11427L9.9 14.4188L7.70319 12.2681C7.31051 11.8798 6.67736 11.8833 6.289 12.2759C5.90064 12.6686 5.90413 13.3018 6.29681 13.6901L9.19681 16.5363C9.58642 16.9216 10.2136 16.9216 10.6032 16.5363L17.7032 9.53628Z"
      fill="#5E1FF0"
    />
  </svg>
);

const SIZE_MAP = {
  md: Verified24,
  sm: Verified24,
  lg: Verified24,
  xl: Verified24,
  xxl: Verified24,
  xxxl: Verified24,
};

export const VerifiedIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'verified-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
