import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const AeropayWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 416 86"
    xmlSpace="preserve"
    width="100%"
    height="100%"
    {...props}
  >
    <g id="a">
      <g>
        <g>
          <path
            fill="white"
            d="M134.5,63.9v-2l1.2-1.6l0.2-0.7c-0.5,0.6-1.1,1.1-1.7,1.6c-2.9,2.2-6.8,3.3-11.4,3.3c-2.7,0-5.3-0.5-7.8-1.6 c-2.5-1-4.7-2.5-6.6-4.4s-3.4-4.1-4.4-6.6s-1.5-5.1-1.5-7.8c-0.1-2.7,0.4-5.4,1.5-7.9c1-2.5,2.6-4.8,4.5-6.7 c1.8-1.9,4.1-3.5,6.5-4.5s5.1-1.5,7.8-1.5c4.7,0,8.6,1.2,11.6,3.5c0.5,0.4,1.1,0.9,1.5,1.4l-0.2-0.6l-1.3-1.6v-2.3h10.9v40H134.5 L134.5,63.9z M123.8,33.3c-1.4,0-2.8,0.2-4.1,0.8c-1.3,0.5-2.4,1.3-3.4,2.4c-1.9,2.1-3,4.8-3,7.6s1.1,5.6,3,7.6 c1,1,2.1,1.8,3.4,2.3s2.7,0.8,4.1,0.8s2.9-0.2,4.2-0.8c1.3-0.5,2.6-1.3,3.6-2.4c1-1,1.8-2.2,2.3-3.5s0.8-2.7,0.8-4.1 c0-1.4-0.3-2.8-0.8-4.1c-0.6-1.3-1.4-2.5-2.4-3.5s-2.2-1.8-3.5-2.4C126.6,33.6,125.2,33.3,123.8,33.3L123.8,33.3z"
          />
          <path
            fill="white"
            d="M190.9,55.3c-4.2,6.3-10.5,9.5-19,9.5c-6.3,0-11.4-2-15.3-5.9c-3.8-4.1-5.8-9.4-5.8-14.9s2.1-10.8,5.9-14.9 c3.9-4,9-6,15.2-6s11.1,1.9,14.8,5.7s5.5,8.7,5.5,14.6c0,0.5,0,1,0,1.6s-0.1,1.1-0.1,1.5s0,0.7,0,0.7h-30.6 c0.5,2.4,1.8,4.6,3.7,6.1c2,1.5,4.4,2.3,6.9,2.2c2.1,0.1,4.1-0.3,6-1.3c1.9-0.9,3.4-2.3,4.6-4L190.9,55.3L190.9,55.3z M172,32 c-5.1,0-8.5,2.4-10,7.3h19.4c-0.4-2.2-1.7-4.1-3.4-5.4C176.2,32.6,174.1,31.9,172,32L172,32z"
          />
          <path
            fill="white"
            d="M208.8,63.9h-10.9v-40h10.9v4.3l-0.6,1.9c0,0,0.6-0.9,0.7-0.9c1.3-1.9,3-3.4,5-4.4s4.3-1.5,6.5-1.5v10.9 c-3.9,0-6.7,1-8.7,3s-2.9,4.8-2.9,8.4V63.9L208.8,63.9z"
          />
          <path
            fill="white"
            d="M244.6,23.1c2.8-0.1,5.7,0.4,8.3,1.5c2.6,1,5,2.6,7.1,4.6c2,1.9,3.5,4.2,4.6,6.8c1.1,2.5,1.6,5.3,1.6,8 s-0.5,5.5-1.6,8s-2.6,4.9-4.6,6.8c-4.2,3.9-9.6,6-15.4,6.1c-5.7,0-11.2-2.1-15.4-6c-1.9-2-3.5-4.3-4.5-6.8 c-1.1-2.5-1.6-5.3-1.6-8c0-2.8,0.6-5.5,1.6-8s2.6-4.9,4.6-6.8c2-2,4.4-3.6,7-4.6C238.9,23.5,241.8,23,244.6,23.1L244.6,23.1z  M252.3,36.1c-2-2-4.8-3.2-7.7-3.2s-5.6,1.1-7.7,3.2c-1,1-1.8,2.3-2.4,3.7s-0.8,2.8-0.7,4.3c-0.1,1.5,0.2,2.9,0.7,4.3 s1.3,2.6,2.3,3.7c1,1,2.1,1.8,3.4,2.4c1.3,0.5,2.7,0.8,4.1,0.8c1.4,0,2.9-0.2,4.2-0.8s2.6-1.4,3.6-2.4c2-2.1,3.2-5,3.2-7.9 C255.4,41.1,254.3,38.3,252.3,36.1L252.3,36.1L252.3,36.1z"
          />
          <path
            fill="white"
            d="M292.1,64.5c-2.6,0.1-5.2-0.4-7.7-1.4c-2.4-1-4.6-2.5-6.5-4.3l-0.7-1l0.7,1.9v17.7H271v-53l6.9-0.7v5.2 l-0.5,1.7l0.5-0.9c3.5-3.9,8.2-5.9,14.1-5.9c2.6,0,5.3,0.5,7.7,1.5c2.4,1,4.6,2.5,6.5,4.4c3.8,3.9,5.9,9,5.9,14.4 s-2.1,10.6-5.9,14.4c-1.8,1.9-4,3.4-6.5,4.4S294.7,64.6,292.1,64.5L292.1,64.5z M291.7,30.3c-3.7,0-7.3,1.5-9.9,4.1 s-4.1,6.2-4.1,9.9s1.5,7.3,4.1,9.9c2.6,2.6,6.2,4.1,9.9,4.1c1.8,0,3.6-0.3,5.3-1c1.7-0.7,3.2-1.7,4.5-3.1 c2.5-2.7,3.9-6.2,3.9-9.9s-1.4-7.2-3.9-9.9c-1.3-1.3-2.8-2.4-4.5-3.1C295.3,30.6,293.5,30.2,291.7,30.3z"
          />
          <path
            fill="white"
            d="M350.9,63.9v-5.3c-3.6,4-8.3,5.9-14.2,5.9c-2.6,0-5.3-0.5-7.7-1.5c-2.4-1-4.6-2.5-6.5-4.4 c-3.8-3.8-5.9-9-5.9-14.4s2.1-10.6,5.9-14.4c1.8-1.9,4-3.4,6.5-4.4s5.1-1.5,7.7-1.5c2.6-0.1,5.3,0.4,7.7,1.4 c2.5,1,4.7,2.5,6.5,4.4v-5.2h6.8V64L350.9,63.9L350.9,63.9z M337.1,30.3c-1.8,0-3.6,0.3-5.3,1c-1.7,0.7-3.2,1.7-4.5,3.1 c-2.5,2.7-3.9,6.2-3.9,9.9s1.4,7.2,3.9,9.9c1.3,1.3,2.8,2.4,4.5,3.1s3.5,1,5.3,1c3.7,0,7.3-1.5,9.9-4.1s4.1-6.2,4.1-9.9 s-1.5-7.3-4.1-9.9C344.4,31.8,340.8,30.3,337.1,30.3L337.1,30.3L337.1,30.3z"
          />
          <path
            fill="white"
            d="M399.6,24.4l-14,38.4c-1.9,5.2-4.2,8.9-7,11.2s-6.4,3.4-10.9,3.4H366v-6.2h1.8c4.8,0,8.4-2.7,10.7-8 l-15.8-38.8h7.6L382,54.3l10.5-29.9H399.6L399.6,24.4z"
          />
        </g>
        <path
          fill="white"
          d="M34.9,49L34.9,49c2.4-1.3,42.6-23.9,43.6-24.4c0.3-0.3,0.8-0.4,1.2-0.6c0.4-0.2,0.9-0.1,1.2,0.1 c0.3,0.3,0.4,0.7,0.3,1.1c-0.2,0.3-0.4,0.7-0.8,0.9c-0.5,0.3-42.7,26.7-44.5,27.8c-1.2,0.8-2.3,1.6-2.4,3.2 c-0.1,0.8,0.3,1.4,0.8,2c0.5,0.4,1.3,0.7,2,0.7s1.4-0.1,2.1-0.1c2.1,0,4.3,0.8,6,2.1c2,1.7,3.7,3.7,4.9,6c0.8,1.3,2.1,3.7,3.9,3.7 h0.1l0.6,0.1c1.5,0.1,2.8-1.3,3.7-2.6c4-5.2,38.1-50.7,39.5-53c0.5-0.8,0.4-2.3-1-2.5c-1-0.2-65.1,18.8-68,19.7l-3.3,1.1 c-0.3,0.1-0.6,0.2-0.9,0.3L23,34.9c-0.6,0.2-1.2,0.3-1.7,0.6c-1.3,0.4-2.6,0.9-3.7,1.4c-0.3,0.2-0.7,0.4-0.9,0.7 c-0.3,0.3-0.3,0.8-0.3,1.2c0,0.9,0.5,1.6,1.4,2c1.2,0.5,2.5,1,3.7,1.4c3.5,1.3,6.6,3.4,8.8,6.3c0.4,0.6,0.8,1.1,1.6,1.2 c0.6,0.1,1.2,0,1.8-0.2L34.9,49z"
        />
      </g>
    </g>
    <g id="c" />
  </svg>
);
export const AeropayLogoWhite = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={AeropayWhite}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
