import { config } from '@jane/shared/config';
import type { AppMode, Id, PendingCartProduct } from '@jane/shared/models';
import { getAnonymousUserId } from '@jane/shared/util';

interface BranchPurchaseInformation {
  count: number;
  productBrandName: string;
  productIds: number[];
}

export interface BrandPurchase {
  [key: number]: BranchPurchaseInformation;
}

export const buildBrandPurchases = (
  products: PendingCartProduct[]
): BrandPurchase =>
  products.reduce<BrandPurchase>((reducer, product) => {
    if (product.brand && product.product_brand_id) {
      const brandName = product.brand.trim();
      const brandId = product.product_brand_id;
      reducer[brandId] = reducer[brandId] || ({} as BranchPurchaseInformation);
      reducer[brandId]['count'] = reducer[brandId]['count'] || 0;
      reducer[brandId]['count'] += product.count;
      reducer[brandId]['productBrandName'] = brandName;
      reducer[brandId]['productIds'] = reducer[brandId]['productIds'] || [];
      reducer[brandId]['productIds'] = [
        ...reducer[brandId]['productIds'],
        ...Array(product.count).fill(product.id),
      ];
    } else {
      // TODO Log?
    }

    return reducer;
  }, {});

interface ConvertAdProps {
  appMode: AppMode;
  cartId: Id;
  janeDeviceId: string;
  products: PendingCartProduct[];
  storeId: Id;
}
export type TrackConversionPayload = {
  appMode: AppMode;
  cartId: Id;
  distinctId: string;
  itemsSold: number;
  janeDeviceId: string;
  productBrandId: number;
  productBrandName: string;
  productIds: number[];
  storeId: Id;
};
export const convertAd = async ({
  appMode,
  cartId,
  janeDeviceId,
  products,
  storeId,
}: ConvertAdProps) => {
  if (!janeDeviceId) return;

  const brandPurchases = buildBrandPurchases(products);

  const conversionUrl = `${config.dmUrl}/client_events/3`;
  await Promise.all(
    Object.keys(brandPurchases).map(async (brandIdString) => {
      const productBrandId = parseInt(brandIdString, 10);
      const conversionPayload: TrackConversionPayload = {
        appMode,
        cartId,
        distinctId: getAnonymousUserId(),
        itemsSold: brandPurchases[productBrandId].count,
        janeDeviceId,
        productBrandId,
        productBrandName: brandPurchases[productBrandId].productBrandName,
        productIds: brandPurchases[productBrandId].productIds,
        storeId,
      };

      await fetch(conversionUrl, {
        body: JSON.stringify(conversionPayload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'text/plain',
        },
        method: 'POST',
      });
    })
  );
};
