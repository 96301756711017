import { getWeightLabel } from '@jane/search/util';
import {
  AddIcon,
  Box,
  Button,
  Card,
  Link,
  Typography,
} from '@jane/shared/reefer';
import type { Breadcrumb, MenuProduct, PriceId } from '@jane/shared/types';
import { formatCurrency, productPhotoMetadata } from '@jane/shared/util';

import { StyledCard, StyledLink } from './quickAddCard.styles';

export interface QuickAddCardProps {
  addToCart: () => void;
  breadcrumbs?: Breadcrumb[];
  cartCount?: number;
  onClick?: () => void;
  pdpUrl: string;
  priceId: PriceId;
  product: MenuProduct;
}

export const QuickAddCard = ({
  addToCart,
  breadcrumbs,
  cartCount = 0,
  onClick,
  pdpUrl,
  priceId,
  product,
}: QuickAddCardProps) => {
  const { url } = productPhotoMetadata(product);
  const { name } = product;
  const price = product[`price_${priceId}`];
  const unit = getWeightLabel(
    ['half_gram', 'half gram'].includes(priceId)
      ? '0.5g'
      : priceId.replace(/_/g, ' ')
  );

  const handleClick = () => {
    onClick && onClick();
  };

  const ButtonIcon = () =>
    cartCount ? (
      <Typography color="text-inverse" variant="body-bold">
        {cartCount}
      </Typography>
    ) : (
      <AddIcon color="text-inverse" />
    );

  return (
    <StyledCard
      flat
      width="100%"
      height="100%"
      border="transparent-black-ten"
      data-testid="quick-add-card"
    >
      <StyledLink
        to={pdpUrl}
        {...(breadcrumbs && { state: { breadcrumbs } })}
        onClick={handleClick}
        ariaLabel={`view ${name} product details`}
        variant="minimal"
      >
        <Card.Image alt={`${name}-image`} height="198px" src={url} responsive />
      </StyledLink>
      <Box position="static" ml="auto" mr={8} mt={-56}>
        <Button.Icon
          ariaLabel={`add ${name} to cart`}
          onClick={addToCart}
          variant="primary"
          icon={<ButtonIcon />}
        />
      </Box>
      <Link
        to={pdpUrl}
        {...(breadcrumbs && { state: { breadcrumbs } })}
        onClick={handleClick}
        ariaLabel={`view ${name} product details`}
        variant="minimal"
      >
        <Card.Content>
          <Typography maxLines={2} variant="body-bold">
            {name}
          </Typography>

          <Typography variant="body-bold" color="primary">
            {formatCurrency(price)}/{unit}
          </Typography>
        </Card.Content>
      </Link>
    </StyledCard>
  );
};
