import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIframeScroll, useIsInIframe } from '@jane/shared-ecomm/hooks';
import {
  Button,
  ErrorIcon,
  Flex,
  Modal as ReeferModal,
  Typography,
} from '@jane/shared/reefer';

import { closeModal } from '../../common/redux/application';
import { useCustomerDispatch } from '../../customer/dispatch';
import {
  clearCart,
  clearRemovedCrmRedemptions,
  deleteUnavailableProducts,
  dismissCheckoutError,
  removeBrandSpecialHasChangedFlag,
  removeSpecialHasChangedFlag,
  unavailableCheckoutFixed,
} from '../../customer/redux/cart';
import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { getUnavailableProductIds } from '../../lib/cart';
import { get } from '../../redux-util/selectors';
import CartAlertsForm from './form';

const CartAlerts = ({
  goBack,
  isCheckoutAlert,
  onClose,
}: {
  goBack?: () => void;
  isCheckoutAlert?: boolean;
  onClose?: () => void;
}) => {
  const navigate = useNavigate();
  const { cart, isFixingUnavailableCheckout } = useCustomerSelector(
    get('cart')
  );
  const { store } = useCustomerSelector(get('store'));

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const isInIframe = useIsInIframe();
  const dispatch = useCustomerDispatch();

  const {
    brand_discounts_total,
    brand_special_has_changed,
    special_has_changed,
    unavailable_products,
    removed_crm_redemptions,
  } = cart;

  const onClick = useCallback(
    (doClose: boolean) => {
      if (unavailable_products.length) {
        const ids = getUnavailableProductIds(unavailable_products as any);
        dispatch(deleteUnavailableProducts(ids));
      }

      if (brand_special_has_changed) {
        dispatch(removeBrandSpecialHasChangedFlag());
      }

      if (special_has_changed) {
        dispatch(removeSpecialHasChangedFlag());
      }

      if (removed_crm_redemptions) {
        dispatch(clearRemovedCrmRedemptions());
      }

      if (isCheckoutAlert) {
        dispatch(dismissCheckoutError());
      }

      if (isFixingUnavailableCheckout) {
        dispatch(unavailableCheckoutFixed());

        // cart has been reseted by checkout
        if (cart.uuid === '' && unavailable_products.length === 0) {
          if (goBack) {
            goBack();
          }
          // cart was emptied by the cartdrawer
        } else if (cart.uuid && isEmpty(cart.products)) {
          dispatch(clearCart());
        }
      }

      // if we have an onClose behavior ignore the redux dispatch
      if (doClose && !onClose) {
        dispatch(closeModal());
      } else if (onClose) {
        onClose();
      }
    },
    [navigate]
  );

  useIframeScroll(isInIframe);

  if (isEmbeddedMode && !isCheckoutAlert) {
    return (
      <div className="former-modal__container">
        <CartAlertsForm
          isFixingUnavailableCheckout={isFixingUnavailableCheckout}
          cart={cart}
          store={store}
          onContinueCheckout={() => onClick(false)}
        />
      </div>
    );
  }

  if (isCheckoutAlert && isEmbeddedMode) {
    return (
      <ReeferModal
        appId="root"
        open
        onRequestClose={onClose}
        variant="dialogue"
        overflow="hidden"
        topOverride={isInIframe ? '160px' : undefined}
      >
        <ReeferModal.Header title="Your bag has changed" />
        <ReeferModal.Content>
          <CartAlertsForm
            noTitle
            isFixingUnavailableCheckout={isFixingUnavailableCheckout}
            cart={cart}
            store={store}
            onContinueCheckout={() => onClick(false)}
          />
        </ReeferModal.Content>
      </ReeferModal>
    );
  }

  const getTitle = () => {
    switch (true) {
      case brand_special_has_changed && brand_discounts_total === 0:
        return 'Cash back unavailable';
      case brand_special_has_changed && brand_discounts_total !== 0:
        return 'Bag updated';
      case isFixingUnavailableCheckout:
        return 'Your bag has changed';
      default:
        return 'Bag may have changed';
    }
  };

  return (
    <ReeferModal open variant="dialogue" onRequestClose={() => onClick(true)}>
      <ReeferModal.Content>
        <Flex width="279px" flexDirection="column" alignItems="center">
          <ErrorIcon size="xxl" color="primary" mb={24} />
          <Typography variant="header-bold" textAlign="center" mb={8} branded>
            {getTitle()}
          </Typography>
          <CartAlertsForm
            cart={cart}
            isFixingUnavailableCheckout={isFixingUnavailableCheckout}
            noButton
            noTitle
            onContinueCheckout={() => onClick(true)}
            store={store}
          />

          <Button
            full
            variant="primary"
            label={brand_special_has_changed ? 'OK' : 'Got it!'}
            onClick={() => onClick(true)}
          />
        </Flex>
      </ReeferModal.Content>
    </ReeferModal>
  );
};

export default CartAlerts;
