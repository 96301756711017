/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Google24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2041 10.1819V14.0546H17.6958C17.4546 15.3001 16.731 16.3547 15.6456 17.0637L18.9573 19.5819C20.8868 17.8365 22 15.2729 22 12.2274C22 11.5184 21.9351 10.8365 21.8144 10.182L12.2041 10.1819Z"
      fill="#4285F4"
    />
    <path
      d="M6.48523 13.9033L5.73832 14.4636L3.09448 16.4818C4.77352 19.7454 8.21483 22 12.2037 22C14.9587 22 17.2685 21.1091 18.9569 19.5818L15.6452 17.0636C14.7361 17.6636 13.5765 18.0273 12.2037 18.0273C9.55062 18.0273 7.29651 16.2728 6.4894 13.9091L6.48523 13.9033Z"
      fill="#34A853"
    />
    <path
      d="M3.09454 7.51831C2.39885 8.86371 2 10.3819 2 12.0001C2 13.6182 2.39885 15.1364 3.09454 16.4818C3.09454 16.4909 6.48979 13.9 6.48979 13.9C6.28571 13.3 6.16508 12.6637 6.16508 12C6.16508 11.3362 6.28571 10.6999 6.48979 10.0999L3.09454 7.51831Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2039 5.98182C13.7067 5.98182 15.0425 6.49089 16.1093 7.47272L19.0313 4.60912C17.2595 2.99097 14.959 2 12.2039 2C8.21504 2 4.77352 4.24545 3.09448 7.51819L6.48962 10.1C7.29663 7.73635 9.55083 5.98182 12.2039 5.98182Z"
      fill="#EA4335"
    />
  </svg>
);

const SIZE_MAP = {
  md: Google24,
  sm: Google24,
  lg: Google24,
  xl: Google24,
  xxl: Google24,
  xxxl: Google24,
};

export const GoogleIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'google-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
