import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { useCallback, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

import type { Hit } from '@jane/search/types';
import { useDeepCompareMemo } from '@jane/shared-ecomm/hooks';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import type { CardLocation } from '@jane/shared-ecomm/tracking';
import {
  EventNames,
  coreProductProperties,
  track,
  trackClickedAd,
  trackImpressedInlineProduct,
  trackProductListingTap,
  trackUserSessionProductCardImpression,
} from '@jane/shared-ecomm/tracking';
import { LegacyMenuProductCard } from '@jane/shared/components';
import type { LegacyMenuProductCardProps } from '@jane/shared/components';
import {
  useGetJaneDeviceId,
  useUserSegmentIds,
} from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type {
  AdData,
  AdFlight,
  AppMode,
  Id,
  MenuProduct,
  Product,
  Store,
  StoreSpecial,
  Zone,
} from '@jane/shared/models';
import { ZoneCart, ZoneStoreMenuInline } from '@jane/shared/models';
import { Box } from '@jane/shared/reefer';
import type { UserSegmentIds } from '@jane/shared/types';
import { deserializeCannabinoids } from '@jane/shared/util';

import { useCustomerDispatch } from '../../customer/dispatch';
import {
  attemptToAddToCart,
  closeCart,
  deleteCartItem,
} from '../../customer/redux/cart';
import { setProductDetailBreadcrumbs } from '../../customer/redux/search';
import { useCustomerSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import { doesSpecialApply } from '../../lib/storeSpecial';
import { get } from '../../redux-util/selectors';
import { BundleSpecialEnticementContent } from '../bundleSpecialEnticementContent';
import { EnticementBubble } from '../enticementBubble';
import { DEFAULT_PRODUCT_CARD_WIDTH } from './productKindBucketCarousel';

export const findSpecialForProduct = (
  menuProduct: MenuProduct,
  specials: StoreSpecial[] = [],
  appMode: AppMode
) =>
  specials
    .filter(Boolean)
    .find((special: StoreSpecial) =>
      doesSpecialApply(appMode, special, menuProduct)
    );

interface TrackingProps {
  bucketName?: string;
  carouselView?: boolean;
  columnPosition?: number;
  creativeIds?: number[];
  currentCycleIndex?: number;
  flightId?: number;
  index?: number;
  indexName: string;
  listView: boolean;
  menuProduct:
    | Hit<(MenuProduct & { root_types: string[] }) | Product>
    | MenuProduct;
  rowPosition?: number;
  userSegments?: UserSegmentIds;
}

export const buildClickTrackingEvent = ({
  store,
  menuProduct,
  carouselView,
  rowPosition,
  columnPosition,
  index,
  indexName,
  listView,
  flightId,
  creativeIds,
  bucketName,
  currentCycleIndex,
  userSegments,
}: TrackingProps & { store: Store }) => ({
  objectIds: [
    'objectID' in menuProduct
      ? menuProduct.objectID
      : menuProduct.id.toString(),
  ],
  storeId: store.id.toString() || undefined,
  storeCity: store.city || undefined,
  storeState: store.state || undefined,
  bestSelling:
    'root_types' in menuProduct
      ? menuProduct.root_types?.includes('best_selling')
      : false,
  ...coreProductProperties(menuProduct, userSegments),
  ...additionalTrackingProperties({
    carouselView,
    menuProduct,
    columnPosition,
    rowPosition,
    index,
    indexName,
    listView,
    flightId,
    creativeIds,
    bucketName,
    currentCycleIndex,
  }),
});

const additionalTrackingProperties = ({
  carouselView,
  menuProduct,
  columnPosition,
  rowPosition,
  index,
  indexName,
  listView,
  flightId,
  creativeIds,
  bucketName,
  currentCycleIndex,
}: TrackingProps) => {
  const sharedProperties = {
    event:
      EventNames.ClickedMenuProductCard as EventNames.ClickedMenuProductCard,
    category: menuProduct.kind,
    flightId,
    creativeIds,
    bucketName,
    currentCycleIndex,
  };

  return carouselView
    ? {
        index: undefined,
        columnPosition,
        rowPosition,
        indexName,
        cardLocation: 'grid carousel' as CardLocation,
        ...sharedProperties,
      }
    : {
        index,
        cardLocation: (listView ? 'list' : 'grid table') as CardLocation,
        rowPosition: undefined,
        columnPosition: undefined,
        indexName,
        ...sharedProperties,
      };
};

const InViewFlex = styled(InView)({
  display: 'flex',
  flexDirection: 'column',
});

export type HitProductCardProps = Omit<
  LegacyMenuProductCardProps,
  | 'appMode'
  | 'brand'
  | 'cartIsOpen'
  | 'cartProducts'
  | 'cartStoreId'
  | 'currentSpecial'
  | 'itemWidth'
  | 'onClick'
  | 'product'
  | 'routePartnerHostedConfig'
  | 'userLocation'
> & {
  algoliaIndexName: string;
  bucketName?: string;
  carouselView?: boolean;
  columnPosition?: number;
  currentCycleIndex?: number;
  flightProps?: AdData['flight'];
  hit: Hit<MenuProduct> | MenuProduct;
  index?: number;
  itemWidth?: number;
  numColumns?: number;
  rowPosition?: number;
  specials?: StoreSpecial[];
  zone: Zone;
};

const HitProductCard = ({
  algoliaIndexName,
  bucketName,
  columnPosition,
  currentCycleIndex,
  flightProps,
  hit,
  index,
  rowPosition,
  specials,
  zone,
  numColumns,
  productInstance,
  ...props
}: HitProductCardProps) => {
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  const { userLocation, userPreferences = {} } = useUserPreferences();
  const { storeFulfillmentType, storeSearchRadius, storeType } =
    userPreferences;
  const { pathname } = useLocation();

  const {
    appMode,
    brand,
    cart,
    store: cartStore,
    userId,
  } = useCustomerSelector(
    ({
      embeddedApp: { appMode },
      brand: { brand },
      cart: { cart },
      customer: { id },
    }) => ({
      appMode,
      brand,
      cart,
      store: cart.store,
      userId: id,
    })
  );
  const cartIsOpen = cart.is_open;
  const cartProducts = cart.products;
  const cartStoreId = cartStore?.id;

  const { touchedProductId, bundlePossibilities } = useCustomerSelector(
    get('bundlePossibilities')
  );
  const { janeDeviceId } = useCustomerSelector(get('application'));

  const { store, carouselView, listView, itemWidth } = props;

  const {
    city: storeCity,
    state: storeState,
    name: storeName,
    id: storeId,
  } = store;

  const [bundleSpecial, setBundleSpecial] = useState<StoreSpecial | undefined>(
    undefined
  );

  const menuProduct = useDeepCompareMemo<any>(() => {
    const mp = {
      ...deserializeCannabinoids(hit, 'menuProduct'),
      id: hit['product_id'],
    };
    return omit(mp, 'product_id');
  }, [hit]);

  flightProps = flightProps || menuProduct.flight;

  useEffect(() => {
    const matchesTouchedProduct = touchedProductId === menuProduct.id;
    const bundleSpecial =
      specials && specials.length > 0 && matchesTouchedProduct
        ? specials.find((s) => s.id === bundlePossibilities.special_id)
        : undefined;

    setBundleSpecial(bundleSpecial);
  }, [touchedProductId, bundlePossibilities]);

  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);

  const { data: jdid } = useGetJaneDeviceId();
  const { data: userSegments } = useUserSegmentIds(jdid);

  const clickTrackingEvent = buildClickTrackingEvent({
    indexName: algoliaIndexName,
    flightId: flightProps?.id,
    creativeIds: flightProps?.creative_ids,
    store,
    menuProduct,
    carouselView,
    rowPosition,
    index,
    columnPosition,
    listView,
    bucketName,
    currentCycleIndex,
    userSegments: janeGoldSegmentation ? userSegments : undefined,
  });

  const enticementBubbleWidth = itemWidth || DEFAULT_PRODUCT_CARD_WIDTH;

  const trackImpression = useCallback(
    async (isInView: boolean) => {
      if (!isInView) return;

      trackUserSessionProductCardImpression({
        adToken: menuProduct.dmMeta?.adToken,
        flightId: flightProps?.id,
        kevelToken: flightProps?.kevel_token,
        model: flightProps?.model,
        myHighD: menuProduct.dmMeta?.myHighD,
        productId: menuProduct.id,
        storeId,
        position: {
          x: columnPosition ?? (index && numColumns ? index % numColumns : 0),
          y:
            rowPosition ??
            (index && numColumns ? Math.floor(index / numColumns) : 0),
        },
        location: zone,
      });

      const shouldTrackDmSdkImpression = productInstance !== undefined;
      if (shouldTrackDmSdkImpression) {
        productInstance.impress();
      }

      const isCorrectZone = [ZoneStoreMenuInline, ZoneCart].includes(zone);
      const shouldTrackNonSdkImpression =
        !shouldTrackDmSdkImpression && isCorrectZone;
      if (shouldTrackNonSdkImpression) {
        trackNonSdkImpression({
          appMode,
          bucketName,
          flight: flightProps,
          janeDeviceId,
          menuProduct,
          storeId,
          userId,
          zone,
        });
      }
    },
    [
      appMode,
      bucketName,
      flightProps,
      janeDeviceId,
      menuProduct,
      storeId,
      productInstance,
      userId,
      zone,
    ]
  );

  const hideEnticements = zone === ZoneCart && store.in_stacking_beta;

  return (
    <InViewFlex
      data-testid="hit-product-card"
      delay={1000}
      onChange={(isInView) => trackImpression(isInView)}
      threshold={0.5}
      triggerOnce
    >
      <LegacyMenuProductCard
        appMode={appMode}
        brand={brand}
        cartIsOpen={cartIsOpen}
        cartProducts={cartProducts}
        cartStoreId={cartStoreId}
        currentSpecial={findSpecialForProduct(menuProduct, specials, appMode)}
        product={menuProduct}
        productInstance={productInstance}
        onAddToCart={(addData) => {
          dispatch(
            attemptToAddToCart({
              ...addData,
              menuProduct: addData.menuProduct as MenuProduct,
              navigate,
              source: pathname,
              userPreferences: {
                cityState: userLocation.cityState,
                storeFulfillmentType: userPreferences.storeFulfillmentType,
                storeSearchRadius: userPreferences.storeSearchRadius,
                storeType: userPreferences.storeType,
                zipcode: userLocation.zipcode,
              },
              userSegments: janeGoldSegmentation ? userSegments : undefined,
            })
          );
        }}
        onDeleteFromCart={(deleteData) => {
          dispatch(
            deleteCartItem(deleteData.itemId, deleteData.selectedWeight)
          );
        }}
        onCloseCart={() => dispatch(closeCart())}
        onSetBreadcrumbs={(searchState) =>
          dispatch(setProductDetailBreadcrumbs(searchState))
        }
        onClick={() => {
          if (productInstance !== undefined) {
            productInstance.click();
          } else {
            trackClickedAd({
              flight: flightProps,
              dmMeta: menuProduct.dmMeta,
              janeDeviceId: janeDeviceId,
              productId: menuProduct.id,
              menuProductBrandId: menuProduct.product_brand_id,
            });
          }
          track(clickTrackingEvent);
          trackProductListingTap({
            cityState: userLocation?.cityState,
            product: menuProduct,
            source: pathname,
            storeId: String(storeId),
            storeName,
            storeCity,
            storeState,
            storeFulfillmentType,
            storeSearchRadius,
            storeType,
            userSegments: janeGoldSegmentation ? userSegments : undefined,
            zipcode: userLocation?.zipcode,
          });
        }}
        {...props}
        routeAppMode={paths.getAppMode()}
        routePartnerHostedConfig={paths.getPartnerHostedConfig()}
        store={store}
        userLocation={userLocation}
      />
      {!hideEnticements && bundleSpecial && !listView && (
        <Box
          px={8}
          maxWidth={enticementBubbleWidth}
          minWidth={enticementBubbleWidth}
        >
          <EnticementBubble onClose={() => setBundleSpecial(undefined)}>
            <BundleSpecialEnticementContent
              appMode={appMode}
              bundlePossibilities={bundlePossibilities}
              special={bundleSpecial}
              store={store}
            />
          </EnticementBubble>
        </Box>
      )}
    </InViewFlex>
  );
};

export default HitProductCard;

interface TrackNonSdkImpressionProps {
  appMode: AppMode;
  bucketName: string;
  flight?: AdFlight;
  janeDeviceId: string;
  menuProduct: MenuProduct;
  storeId: Id;
  userId: number;
  zone: Zone;
}
const trackNonSdkImpression = ({
  appMode,
  bucketName,
  flight,
  janeDeviceId,
  menuProduct,
  storeId,
  userId,
  zone,
}: TrackNonSdkImpressionProps) => {
  const placementRow = zone === ZoneCart ? 'cartToppers' : bucketName;
  if (!menuProduct.dmMeta?.adToken) {
    return;
  }
  trackImpressedInlineProduct({
    adToken: menuProduct.dmMeta.adToken,
    appMode,
    flight,
    janeDeviceId,
    myHighD: menuProduct.dmMeta.myHighD,
    placementRow,
    product: menuProduct,
    storeId,
    userId,
    zone,
  });
};
