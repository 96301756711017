import { useTheme as useEmotionTheme } from '@emotion/react';

import type { CustomThemeConfig, ReeferTheme } from '@jane/shared/reefer';

export const LEGACY_COLORS = {
  black: '#1A3C34',
  fashionPink: '#1A3C34',
  grassGreen: '#2BB967',
  midnightGreen: '#1A3C34',
  white: '#FFFFFF',
};

// eslint-disable-next-line sort-exports/sort-exports
export const DEFAULT_THEME: CustomThemeConfig = {
  colors: {
    secondary: LEGACY_COLORS.midnightGreen,
    info: LEGACY_COLORS.fashionPink,
    text: {
      info: LEGACY_COLORS.white,
      inverse: LEGACY_COLORS.white,
    },
  },
  components: {
    Typography: {
      fonts: {
        branded: {
          fontFamily:
            'Jane Default, Source Sans Pro, Helvetica, Arial, sans-serif',
        },
      },
      variants: {},
    },
  },
};

// eslint-disable-next-line sort-exports/sort-exports
export const DEFAULT_BUSINESS_THEME: CustomThemeConfig = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primary: LEGACY_COLORS.grassGreen,
    secondary: LEGACY_COLORS.black,
  },
};

export const DEFAULT_OPERATOR_THEME: CustomThemeConfig = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primary: LEGACY_COLORS.black,
    secondary: LEGACY_COLORS.black,
  },
};

export const DEFAULT_RETAIL_THEME: CustomThemeConfig = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primary: LEGACY_COLORS.black,
  },
  components: {
    Typography: {
      fonts: {
        branded: {
          fontFamily:
            'Euclid Circular B, Source Sans Pro, Helvetica, Arial, sans-serif',
        },
      },
      variants: {},
    },
  },
};

export const useTheme = (fallbackTheme = DEFAULT_THEME) =>
  (useEmotionTheme() || fallbackTheme) as ReeferTheme;
