import { nanoid } from 'nanoid';

import type { PosCheckoutParams } from './posCheckout';
import { sendPosCheckout } from './posCheckout';
import type {
  FetchRecommendedPDPRowProps,
  RecommendedPDPRow,
} from './recommendedPDPRow';
import { RecommendedPDPRowClient } from './recommendedPDPRow';
import type {
  FetchCartTopperRowProps,
  FetchRecommendedRowProps,
  FetchRecommendedSortProps,
  SmartSort,
} from './smartSort';
import { SmartSortClient } from './smartSort';
import type { FetchTopOfMenuRowProps, TopOfMenuRow } from './topOfMenuRow';
import { TopOfMenuRowClient } from './topOfMenuRow';
import { appModeHeadless } from './types/appModes';
import type {
  JaneDMConfig,
  JaneDMIdentifiers,
  RequiredConfig,
} from './types/config';
import {
  placementCartToppers,
  placementMenuInlineTable,
  placementRecommendedRow,
} from './types/placements';
import { debounceAsync } from './utils/debounceAsync';
import { SdkError } from './utils/sdkError';
import { Storage } from './utils/storage';
import { JDM_VERSION } from './utils/version';

const FETCH_PLACEMENT_DEBOUNCE_TIME = 1000;

export class JaneDM {
  protected config: RequiredConfig;
  private topOfMenuRowClient: TopOfMenuRowClient;
  private smartSortClient: SmartSortClient;
  private recommendedPDPRowClient: RecommendedPDPRowClient;

  public fetchTopOfMenuRow: (
    props: FetchTopOfMenuRowProps
  ) => Promise<TopOfMenuRow>;

  public fetchRecommendedPDPRow: (
    props: FetchRecommendedPDPRowProps
  ) => Promise<RecommendedPDPRow>;

  public fetchRecommendedRow: (
    props: FetchRecommendedRowProps
  ) => Promise<SmartSort>;

  public fetchRecommendedSort: (
    props: FetchRecommendedSortProps
  ) => Promise<SmartSort>;

  public fetchCartTopperRow: (
    props: FetchCartTopperRowProps
  ) => Promise<SmartSort>;

  public sendPosCheckout: (props: PosCheckoutParams) => Promise<void>;

  constructor(props: JaneDMConfig) {
    this.config = {
      apiKey: props.apiKey,
      appMode: props.appMode ?? appModeHeadless,
      endpoint: props.endpoint ?? 'https://dmerch-demo.nonprod-iheartjane.com',
      identifier: this.generateAndMergeIdentifier(props.identifier),
      source: props.source ?? 'sdk',
      version: props.version ?? JDM_VERSION,
    };

    this.topOfMenuRowClient = new TopOfMenuRowClient();
    this.smartSortClient = new SmartSortClient();
    this.recommendedPDPRowClient = new RecommendedPDPRowClient();

    this.fetchTopOfMenuRow = debounceAsync<typeof this._fetchTopOfMenuRow>(
      this._fetchTopOfMenuRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.fetchRecommendedPDPRow = debounceAsync<
      typeof this._fetchRecommendedPDPRow
    >(this._fetchRecommendedPDPRow.bind(this), FETCH_PLACEMENT_DEBOUNCE_TIME);

    this.fetchRecommendedRow = debounceAsync<typeof this._fetchRecommendedRow>(
      this._fetchRecommendedRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.fetchRecommendedSort = debounceAsync<
      typeof this._fetchRecommendedSort
    >(this._fetchRecommendedSort.bind(this), FETCH_PLACEMENT_DEBOUNCE_TIME);

    this.fetchCartTopperRow = debounceAsync<typeof this._fetchCartTopperRow>(
      this._fetchCartTopperRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.sendPosCheckout = debounceAsync<typeof this._sendPosCheckout>(
      this._sendPosCheckout.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );
  }

  public getJaneDeviceId() {
    return this.config.identifier.jdid;
  }

  private async _fetchTopOfMenuRow(
    props: FetchTopOfMenuRowProps
  ): Promise<TopOfMenuRow> {
    return this.topOfMenuRowClient.createTopOfMenuRow({
      ...props,
      ...this.config,
    });
  }

  private async _fetchRecommendedPDPRow(
    props: FetchRecommendedPDPRowProps
  ): Promise<RecommendedPDPRow> {
    return await this.recommendedPDPRowClient.createRecommendedPDPRow({
      ...props,
      ...this.config,
    });
  }

  private async _fetchRecommendedRow(
    props: FetchRecommendedRowProps
  ): Promise<SmartSort> {
    return await this.smartSortClient.createSmartSort({
      ...props,
      ...this.config,
      placement: placementRecommendedRow,
      title: 'For you',
    });
  }

  private async _fetchRecommendedSort(
    props: FetchRecommendedSortProps
  ): Promise<SmartSort> {
    return await this.smartSortClient.createSmartSort({
      ...props,
      ...this.config,
      placement: placementMenuInlineTable,
    });
  }

  private async _fetchCartTopperRow(
    props: FetchCartTopperRowProps
  ): Promise<SmartSort> {
    return await this.smartSortClient.createSmartSort({
      ...props,
      ...this.config,
      placement: placementCartToppers,
    });
  }

  private async _sendPosCheckout(props: PosCheckoutParams) {
    return sendPosCheckout({ ...props, ...this.config });
  }

  private initJaneDeviceId(): string {
    let jdid = Storage.get('jdid');

    if (!jdid || jdid === 'null' || jdid === 'undefined') {
      jdid = nanoid();
      Storage.set('jdid', jdid);
    }

    return jdid;
  }

  private generateAndMergeIdentifier(
    originalIdentifier: JaneDMIdentifiers | undefined
  ): JaneDMIdentifiers {
    const isSsr = typeof window === 'undefined';

    if (!isSsr) {
      const jdid = originalIdentifier?.jdid || this.initJaneDeviceId();
      return { ...originalIdentifier, jdid };
    } else {
      if (!originalIdentifier) {
        throw new SdkError(
          'Identifier must be provided for server-side rendering'
        );
      }

      return originalIdentifier;
    }
  }
}
