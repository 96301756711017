/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const TwitterX24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.24156 12.6401L1 2H7.5279L12.6153 8.57628L18.0505 2.02961H21.6457L14.3537 10.8233L23 22H16.4916L10.9829 14.8882L5.10186 21.9803H1.48716L9.24156 12.6401ZM17.4402 20.0286L5.00269 3.97141H6.57852L19.0004 20.0286H17.4402Z"
      fill="#1C1C1C"
    />
  </svg>
);

const SIZE_MAP = {
  md: TwitterX24,
  sm: TwitterX24,
  lg: TwitterX24,
  xl: TwitterX24,
  xxl: TwitterX24,
  xxxl: TwitterX24,
};

export const TwitterXIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'twitter-xicon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
