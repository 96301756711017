import { Suspense } from 'react';

import type { LoadingWrapperVariant } from '../loadingWrapper';
import { LoadingWrapper } from '../loadingWrapper';
import { withScrollRestoration } from '../withScrollRestoration';

export const Loader =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Component: React.FC, variant?: LoadingWrapperVariant) => (props: any) => {
    const { restoreScroll = false, ...componentProps } = props;

    const WrappedComponent = restoreScroll
      ? withScrollRestoration(Component)
      : Component;

    return (
      <Suspense
        fallback={<LoadingWrapper isLoading variant={variant || 'full-page'} />}
      >
        <WrappedComponent {...componentProps} />
      </Suspense>
    );
  };
