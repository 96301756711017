import type { SearchProps } from '../params/algolia/client';

export type JaneSearchState<T> = Pick<
  SearchProps<T>,
  | 'currentSort'
  | 'filters'
  | 'bucketFilters'
  | 'rangeFilters'
  | 'searchText'
  | 'page'
>;

export const JANE_SEARCH_STATE_KEYS = [
  'bucketFilters',
  'currentSort',
  'filters',
  'page',
  'rangeFilters',
  'searchText',
];
