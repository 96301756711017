import {
  Box,
  Button,
  ChevronDownIcon,
  Flex,
  List,
  Popover,
  TrashIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { PopoverContextProps } from '@jane/shared/reefer';

import { QuantityButton, StyledPopover } from './cartDrawerItems.styles';

const BUTTON_SIZE = '96px';

interface CartDrawerItemActionsProps {
  count: number;
  handleEditQuantity: (qty: number) => void;
  handleRemoveProduct: () => void;
  maxQuantity: number;
  name: string;
}
export const CartDrawerItemActions = ({
  handleRemoveProduct,
  handleEditQuantity,
  count,
  name,
  maxQuantity,
}: CartDrawerItemActionsProps) => {
  const isMobile = useMobileMediaQuery({});
  const quantityOptions = new Array(maxQuantity)
    .fill(true)
    .map((_, i) => i + 1);

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignSelf="flex-end"
      maxWidth={isMobile ? '100%' : '160px'}
    >
      <Box width={isMobile ? BUTTON_SIZE : '48px'} mr={16}>
        <Button.Icon
          full
          icon={<TrashIcon altText={`remove-${name}`} />}
          onClick={handleRemoveProduct}
          variant="tertiary"
          data-testid="remove-cart-item"
        />
      </Box>
      <Flex width={isMobile ? '100%' : BUTTON_SIZE} grow basis="0">
        <StyledPopover
          label="Select quantity"
          disableMobileStyling
          alignment={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          target={
            <QuantityButton
              full
              label={count.toString()}
              variant="tertiary"
              endIcon={<ChevronDownIcon size="sm" />}
            />
          }
        >
          {({ closePopover }: PopoverContextProps) => (
            <Popover.Content padding={false}>
              <List label="quantity selector">
                {quantityOptions.map((qty) => (
                  <List.Item
                    onClick={() => {
                      handleEditQuantity(qty);
                      closePopover();
                    }}
                    key={qty}
                    px={16}
                  >
                    <Typography>{qty}</Typography>
                  </List.Item>
                ))}
              </List>
            </Popover.Content>
          )}
        </StyledPopover>
      </Flex>
    </Flex>
  );
};
