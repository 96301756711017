import type { ActiveFilters as ActiveFilterType } from '@jane/search/types';
import {
  Button,
  DismissIcon,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { IconComponentProps, MarginProperties } from '@jane/shared/reefer';

import type { ProductFilterBarProps } from './productFilterBar.types';

interface ActiveFilterProps
  extends Pick<
    ProductFilterBarProps,
    'activeFilters' | 'onChange' | 'onDeselect'
  > {
  myHighMenu?: boolean;
}

export const ActiveFilters = ({
  activeFilters,
  onChange,
  onDeselect,
  myHighMenu,
}: ActiveFilterProps) => {
  const isMobile = useMobileMediaQuery({});
  const filterButtons = activeFilters.map((filter) => (
    <ActiveFilterButton
      key={filter.label}
      activeFilter={filter}
      onChange={onChange}
      mr={16}
      mb={16}
    />
  ));

  return activeFilters.length > 0 ? (
    <Flex
      alignItems="center"
      flexWrap="wrap"
      px={myHighMenu ? (isMobile ? 24 : 64) : 24}
    >
      {filterButtons}
      <Link onClick={() => onDeselect('all')} ml={12} mb={16}>
        <Typography>Clear all</Typography>
      </Link>
    </Flex>
  ) : null;
};

export const ActiveFilterButton = ({
  activeFilter,
  onChange,
  ...margins
}: {
  activeFilter: ActiveFilterType;
  onChange: ProductFilterBarProps['onChange'];
} & MarginProperties) => {
  const { icon, key, label, value } = activeFilter;
  const Icon = icon as IconComponentProps;
  const buttonLabel = key === 'has_brand_discount' ? 'Jane Gold' : label;

  return (
    <Button
      key={buttonLabel}
      label={buttonLabel}
      endIcon={icon ? <Icon size="sm" /> : undefined}
      startIcon={<DismissIcon altText={`Clear ${buttonLabel} filter`} />}
      onClick={() => onChange(key, value)}
      variant="secondary"
      {...margins}
    />
  );
};
