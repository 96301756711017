import React, { createContext, useContext } from 'react';

import type { AppMode, Store, StoreSpecial } from '@jane/shared/models';

interface StoreMenuContextProps {
  appMode?: AppMode;
  children?: React.ReactNode;
  handleSetListView?: (listView: boolean) => void;
  listView?: boolean;
  specials?: StoreSpecial[];
  store?: Store;
  storeHeaderHeight?: number;
}

export const StoreMenuContext = createContext<StoreMenuContextProps>({});

export const StoreMenuProvider = ({
  appMode,
  children,
  handleSetListView,
  listView,
  specials,
  store,
  storeHeaderHeight,
}: StoreMenuContextProps) => {
  return (
    <StoreMenuContext.Provider
      value={{
        appMode,
        handleSetListView,
        listView,
        specials,
        store,
        storeHeaderHeight,
      }}
    >
      {children}
    </StoreMenuContext.Provider>
  );
};

export const useStoreMenuContext = () => useContext(StoreMenuContext);
