import type {
  DeliveryValidation,
  ReservationMode,
  Store,
} from '@jane/shared/models';

import { useCustomerSelector } from '../../../../customer';
import { generateCartLimitReport } from '../../../../lib/cartLimitPolicy';
import type { CartLineItems } from '../../../../lib/getCartLineItems';
import minimumDeliveryOrder from '../../../../lib/minimumDeliveryOrder';
import { minimumPickupOrder } from '../../../../lib/minimumPickupOrder';
import type OrderingSchedule from '../../../../lib/orderingSchedule';
import type StoreSchedule from '../../../../lib/storeSchedule';

interface useIsCheckoutDisabledProps {
  cartLineItems: CartLineItems;
  deliveryValidationData: DeliveryValidation;
  schedules: OrderingSchedule;
  store: Store;
}
export const useIsCheckoutDisabled = ({
  cartLineItems,
  deliveryValidationData,
  store,
  schedules,
}: useIsCheckoutDisabledProps) => {
  const {
    cart,
    isLoading: isLoadingCart,
    salesTaxLoading,
  } = useCustomerSelector(({ cart: { cart, isLoading, salesTaxLoading } }) => ({
    cart,
    isLoading,
    salesTaxLoading,
  }));

  const { reservation_mode } = cart;

  const cartReport =
    store && store.cart_limit_policy
      ? generateCartLimitReport({
          cart,
          store,
        })
      : null;

  const reservationSchedule = (
    reservationMode: ReservationMode
  ): StoreSchedule => schedules[`${reservationMode}Schedule`];

  const storeIsAcceptingReservations =
    reservationSchedule(reservation_mode)?.isCurrentlyAcceptingReservations();

  const pastLastCall =
    reservationSchedule(reservation_mode)?.currentTime?.unix() >=
    reservationSchedule(reservation_mode)?.today.lastCallTime?.unix();

  const acceptOfflineOrders =
    reservationSchedule(reservation_mode)?.allowOffHoursOrdering;

  const canDeliver =
    reservation_mode === 'delivery'
      ? minimumDeliveryOrder({
          discountedSubtotal: cartLineItems.discountedSubtotal,
          store,
          deliveryValidateData: deliveryValidationData,
        }) && !!deliveryValidationData?.can_deliver
      : true;

  const cartReportOver = cartReport && cartReport.over;

  const minimumPickupOrderMet = minimumPickupOrder(
    cartLineItems.discountedSubtotal,
    store
  );

  const invalidDeliveryAddress =
    !deliveryValidationData?.can_deliver && reservation_mode === 'delivery';

  const checkoutDisabled =
    isLoadingCart ||
    salesTaxLoading ||
    !storeIsAcceptingReservations ||
    (pastLastCall && !acceptOfflineOrders) ||
    !canDeliver ||
    cartReportOver ||
    !minimumPickupOrderMet ||
    invalidDeliveryAddress;

  return checkoutDisabled;
};
