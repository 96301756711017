import { useCallback, useState } from 'react';

import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import { EventNames, linkBankAccountEvent } from '@jane/shared-ecomm/tracking';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Box } from '@jane/shared/reefer';

import { BankLinkingModal } from '../bankLinkingModal';
import { LogInModal } from '../logInModal';
import type { JaneGoldCalloutProps } from './janeGoldCallout.types';
import { JaneGoldCartCallout } from './janeGoldCartCallout';
import { JaneGoldCheckoutCallout } from './janeGoldCheckoutCallout';
import { JaneGoldCheckoutSummaryCallout } from './janeGoldCheckoutSummaryCallout';
import { JaneGoldPdpCallout } from './janeGoldPdpCallout';

/**
 * A component used for representing Jane Gold callout action. This callout has three different variants,
 * depending on the context where it is used. Different variants render different content and calls to action, depending on if the user has a linked bank or not.
 */
export function JaneGoldCallout({
  authentication,
  defaultOpen = false,
  discountLabel,
  feeLabel,
  headlessLoginUrl,
  onLinkAccountClick,
  totalLabel,
  variant = 'pdp',
  ...props
}: JaneGoldCalloutProps) {
  const { isGuestUser, userData, userHasLinkedBank } = useJaneGoldData();
  const [openModal, setOpenModal] = useState(defaultOpen);
  const janeGoldServiceFee = useFlag(FLAGS.janeGoldServiceFee);

  const pdpDiscountLabelString = discountLabel
    ? ` ${discountLabel}`
    : ' cash back';

  const pdpCalloutTitle = userHasLinkedBank
    ? `Buy this item and get${pdpDiscountLabelString} deposited to your linked bank account`
    : `Buy this item and get${pdpDiscountLabelString}`;
  const pdpCalloutDescription =
    'Jane Gold is a way to earn exclusive cash back rewards from your favorite cannabis brands.';

  const cartCalloutTitle = 'Earn cash back with Jane Gold';
  const checkoutCalloutTitle = `Earn Jane Gold cash back`;
  const cartCheckoutCalloutDescription = userHasLinkedBank
    ? 'Your brand-sponsored cash back reward will be deposited to your bank account in 1-3 days.'
    : 'Link your bank account and join Jane Gold to earn exclusive cash back rewards from this order.';

  const hasFeeBreakdown =
    janeGoldServiceFee && Boolean(feeLabel && Number(feeLabel) > 0);

  const handleOnLinkAccountClick = useCallback(() => {
    linkBankAccountEvent({
      email: userData?.user.email || '',
      event: EventNames.LinkBankAccountTap,
      source: variant,
      username: userData?.user.nickname || '',
    });
    onLinkAccountClick && onLinkAccountClick();

    setOpenModal(true);
  }, [userData, variant]);

  return (
    <Box {...props}>
      {variant === 'pdp' && (
        <JaneGoldPdpCallout
          description={pdpCalloutDescription}
          onLinkAccountClick={handleOnLinkAccountClick}
          title={pdpCalloutTitle}
        />
      )}
      {variant === 'cart' && (
        <JaneGoldCartCallout
          description={cartCheckoutCalloutDescription}
          discountLabel={discountLabel}
          feeLabel={feeLabel}
          hasBreakdown={hasFeeBreakdown}
          headlessLoginUrl={headlessLoginUrl}
          onLinkAccountClick={handleOnLinkAccountClick}
          totalLabel={totalLabel}
          title={cartCalloutTitle}
        />
      )}
      {variant === 'checkout' && (
        <JaneGoldCheckoutCallout
          description={cartCheckoutCalloutDescription}
          discountLabel={discountLabel}
          feeLabel={feeLabel}
          hasBreakdown={hasFeeBreakdown}
          headlessLoginUrl={headlessLoginUrl}
          onLinkAccountClick={handleOnLinkAccountClick}
          title={checkoutCalloutTitle}
          totalLabel={totalLabel}
        />
      )}
      {variant === 'checkoutSummary' && (
        <JaneGoldCheckoutSummaryCallout
          discountLabel={discountLabel}
          onLinkAccountClick={handleOnLinkAccountClick}
        />
      )}

      {openModal && !isGuestUser && (
        <BankLinkingModal
          open={openModal}
          onRequestClose={() => setOpenModal(false)}
          location={variant}
        />
      )}

      {openModal && authentication && isGuestUser && (
        <LogInModal
          errors={authentication.errors}
          location={variant}
          open={true}
          onAppleClick={authentication.onAppleClick}
          onGoogleClick={authentication.onGoogleClick}
          onRequestClose={() => {
            authentication.resetError();
            setOpenModal(false);
          }}
          onSignInSubmit={authentication.login}
          onCreateAccountSubmit={authentication.register}
          onForgotPasswordSubmit={authentication.resetPassword}
          showAppleLogin={authentication.showAppleLogin}
          showGoogleLogin={authentication.showGoogleLogin}
        />
      )}
    </Box>
  );
}
