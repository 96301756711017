/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const AllProducts24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.97449 8.91497L8.8468 8.91497L6.42158 4.16153L3.97449 8.91497ZM9.68654 10.715C10.6469 10.715 11.2683 9.70038 10.8318 8.84494L7.5689 2.44963C7.09273 1.51634 5.76006 1.51391 5.2805 2.44546L1.98817 8.84077C1.54767 9.69644 2.1689 10.715 3.1313 10.715L9.68654 10.715Z"
      fill="#5E1FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7146 14.6578H15.4289C15.0028 14.6578 14.6574 15.0032 14.6574 15.4292V19.7149C14.6574 20.141 15.0028 20.4864 15.4289 20.4864H19.7146C20.1406 20.4864 20.486 20.141 20.486 19.7149V15.4292C20.486 15.0032 20.1406 14.6578 19.7146 14.6578ZM15.4289 12.8578C14.0087 12.8578 12.8574 14.0091 12.8574 15.4292V19.7149C12.8574 21.1351 14.0087 22.2864 15.4289 22.2864H19.7146C21.1347 22.2864 22.286 21.1351 22.286 19.7149V15.4292C22.286 14.0091 21.1347 12.8578 19.7146 12.8578H15.4289Z"
      fill="#5E1FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64753 14.8512L4.3562 15.9547L3.79029 18.4341L5.37594 20.4224H7.91912L9.50477 18.4341L8.93886 15.9547L6.64753 14.8512ZM7.20538 13.122C6.85286 12.9522 6.44221 12.9522 6.08968 13.122L3.35207 14.4404C2.99954 14.6101 2.74351 14.9312 2.65644 15.3127L1.98031 18.275C1.89324 18.6565 1.98462 19.0568 2.22857 19.3627L4.12306 21.7383C4.36701 22.0443 4.737 22.2224 5.12827 22.2224H8.16679C8.55806 22.2224 8.92804 22.0443 9.172 21.7383L11.0665 19.3627C11.3104 19.0568 11.4018 18.6565 11.3148 18.275L10.6386 15.3127C10.5516 14.9312 10.2955 14.6101 9.94299 14.4404L7.20538 13.122Z"
      fill="#5E1FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6574 6.42925C14.6574 8.03877 15.9622 9.34354 17.5717 9.34354C19.1812 9.34354 20.486 8.03877 20.486 6.42925C20.486 4.81974 19.1812 3.51497 17.5717 3.51497C15.9622 3.51497 14.6574 4.81974 14.6574 6.42925ZM17.5717 1.71497C14.9681 1.71497 12.8574 3.82562 12.8574 6.42925C12.8574 9.03288 14.9681 11.1435 17.5717 11.1435C20.1753 11.1435 22.286 9.03288 22.286 6.42925C22.286 3.82562 20.1753 1.71497 17.5717 1.71497Z"
      fill="#5E1FF0"
    />
  </svg>
);

const AllProducts48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.586 17.3997L17.0733 17.3997L11.8532 7.23276L6.586 17.3997ZM18.8808 21.2496C20.9479 21.2496 22.2853 19.0796 21.3459 17.2499L14.3227 3.57123C13.2978 1.57507 10.4293 1.56988 9.3971 3.56231L2.31059 17.241C1.36244 19.0712 2.6996 21.2496 4.7711 21.2496L18.8808 21.2496Z"
      fill="#5E1FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.4652 29.683H31.2405C30.3235 29.683 29.5801 30.4217 29.5801 31.3329V40.4995C29.5801 41.4107 30.3235 42.1494 31.2405 42.1494H40.4652C41.3822 42.1494 42.1256 41.4107 42.1256 40.4995V31.3329C42.1256 30.4217 41.3822 29.683 40.4652 29.683ZM31.2405 25.833C28.1837 25.833 25.7057 28.2954 25.7057 31.3329V40.4995C25.7057 43.537 28.1837 45.9994 31.2405 45.9994H40.4652C43.522 45.9994 46 43.537 46 40.4995V31.3329C46 28.2954 43.522 25.833 40.4652 25.833H31.2405Z"
      fill="#5E1FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3396 30.0963L7.40765 32.4565L6.18957 37.7596L9.60256 42.0124H15.0766L18.4896 37.7596L17.2715 32.4565L12.3396 30.0963ZM13.5403 26.3978C12.7815 26.0347 11.8976 26.0347 11.1388 26.3978L5.24633 29.2176C4.48755 29.5807 3.93645 30.2674 3.74904 31.0833L2.29371 37.4193C2.10631 38.2352 2.30299 39.0915 2.82809 39.7458L6.90583 44.8269C7.43093 45.4812 8.22729 45.8623 9.06948 45.8623H15.6097C16.4519 45.8623 17.2482 45.4812 17.7733 44.8269L21.8511 39.7458C22.3762 39.0915 22.5728 38.2352 22.3854 37.4193L20.9301 31.0833C20.7427 30.2674 20.1916 29.5807 19.4328 29.2176L13.5403 26.3978Z"
      fill="#5E1FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5801 12.0832C29.5801 15.5257 32.3885 18.3164 35.8528 18.3164C39.3172 18.3164 42.1256 15.5257 42.1256 12.0832C42.1256 8.64067 39.3172 5.84995 35.8528 5.84995C32.3885 5.84995 29.5801 8.64067 29.5801 12.0832ZM35.8528 2C30.2487 2 25.7057 6.5144 25.7057 12.0832C25.7057 17.652 30.2487 22.1664 35.8528 22.1664C41.457 22.1664 46 17.652 46 12.0832C46 6.5144 41.457 2 35.8528 2Z"
      fill="#5E1FF0"
    />
  </svg>
);

const SIZE_MAP = {
  md: AllProducts24,
  sm: AllProducts24,
  xl: AllProducts48,
  lg: AllProducts48,
  xxl: AllProducts48,
  xxxl: AllProducts48,
};

export const AllProductsIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'all-products-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
