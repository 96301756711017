import merge from 'lodash/merge';
import noop from 'lodash/noop';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import type { PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { JANE_SEARCH_STATE_KEYS } from '@jane/search/types';
import type {
  SearchState as InstantSearchState,
  JaneSearchState,
} from '@jane/search/types';
import { config } from '@jane/shared/config';
import { encodeQuery, parseSearch } from '@jane/shared/util';

interface SearchContext<T> {
  indexPrefix: string;
  instantSearchState: InstantSearchState;
  resetSearchState: (nextSearchState?: Partial<JaneSearchState<T>>) => void;
  searchState: JaneSearchState<T>;
  setSearchState: (searchState: Partial<JaneSearchState<T>>) => void;
}

const buildSearchState = <T,>(
  initialSearchParams: JaneSearchState<T> = {}
): JaneSearchState<T> => ({
  filters: {},
  bucketFilters: {},
  rangeFilters: {},
  searchText: '',
  page: 0,
  ...initialSearchParams,
});

const SearchContext = createContext<SearchContext<any>>({
  indexPrefix: '',
  instantSearchState: {},
  resetSearchState: noop,
  searchState: buildSearchState<any>(),
  setSearchState: noop,
});

export const useSearchContext = () => useContext(SearchContext);

export const SearchConsumer = SearchContext.Consumer;

type SearchProviderProps<T> = PropsWithChildren<{
  indexPrefix: string;
  initialSearchState?: Partial<JaneSearchState<T>>;
  noUrlChanges?: boolean;
  onSearchStateChange?: (nextSearchState: JaneSearchState<T>) => void;
}>;

export const getSearchStateForUrl = <T,>({
  page,
  ...searchState
}: JaneSearchState<T>) =>
  pick(
    omitBy(searchState, (value) => !value),
    JANE_SEARCH_STATE_KEYS
  );

const searchStateFromLocationSearch = (locationSearch: string) => {
  const searchParams = parseSearch(locationSearch);

  deleteIncompleteCurrentSort(searchParams);
  deleteDefaultCurrentSort(searchParams);

  return isInstantSearchState(searchParams)
    ? instantSearchStateToSearchState(searchParams)
    : searchParams;
};

const deleteIncompleteCurrentSort = (
  searchParams: Record<string, any>
): void => {
  const { currentSort } = searchParams;
  if (currentSort) {
    if (!('id' in currentSort) || !('suffix' in currentSort)) {
      delete searchParams['currentSort'];
    }
  }
};

const deleteDefaultCurrentSort = (searchParams: Record<string, any>): void => {
  const { currentSort } = searchParams;
  if (currentSort?.isDefault !== undefined) {
    delete searchParams['currentSort'];
  }
};

export const SearchProvider = <T,>({
  indexPrefix,
  initialSearchState,
  children,
  onSearchStateChange,
  noUrlChanges,
}: SearchProviderProps<T>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldReplaceRef = useRef(false);

  const stateFromSearchParams = useMemo(
    () => searchStateFromLocationSearch(location.search),
    [location.search]
  );

  const [searchState, setSearchState] = useState<JaneSearchState<T>>(
    buildSearchState(merge(stateFromSearchParams, initialSearchState))
  );

  const mappingInstantSearchState = useMemo(
    () => isInstantSearchState(parseSearch(location.search)),
    [location.search]
  );

  useEffect(() => {
    shouldReplaceRef.current = mappingInstantSearchState;
  }, [mappingInstantSearchState]);

  useEffect(() => {
    if (noUrlChanges) {
      return;
    }

    const searchStateForUrl = getSearchStateForUrl(searchState);
    const encodedSearchState = encodeQuery('', searchStateForUrl);
    if (location.search !== encodedSearchState) {
      navigate(
        {
          ...location,
          search: encodedSearchState,
        },
        { replace: shouldReplaceRef.current }
      );
    }
  }, [JSON.stringify(searchState)]);

  const setState = useCallback(
    (nextSearchState: Partial<JaneSearchState<T>>) => {
      setSearchState((prevSearchState) => {
        const searchState = {
          ...prevSearchState,
          ...nextSearchState,
        };

        onSearchStateChange?.(searchState);

        return searchState;
      });
    },
    [onSearchStateChange]
  );

  const resetSearchState = useCallback(
    (nextSearchState: Partial<JaneSearchState<T>> = {}) => {
      setSearchState(() => {
        onSearchStateChange?.(nextSearchState);

        return nextSearchState;
      });
    },
    [onSearchStateChange]
  );

  const instantSearchState = useMemo(
    () => searchStateToInstantSearchState(searchState, indexPrefix),
    [searchState]
  );

  useEffect(() => {
    setSearchState((prevSearchState) => {
      const nextSearchState = getSearchStateForUrl(stateFromSearchParams);

      if (!Object.keys(nextSearchState).length) {
        return nextSearchState;
      }
      const result = { ...prevSearchState, ...nextSearchState };
      deleteDefaultCurrentSort(result);

      return result;
    });
  }, [location.search]);

  const contextValue = useMemo(
    () => ({
      indexPrefix,
      instantSearchState,
      resetSearchState,
      searchState,
      setSearchState: setState,
    }),
    [indexPrefix, instantSearchState, resetSearchState, searchState, setState]
  );

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

// TODO(elliot): Remove these fns once we rip out instantsearch everywhere?
export const searchStateToInstantSearchState = <T,>(
  {
    currentSort,
    searchText,
    bucketFilters,
    rangeFilters,
    filters,
    page,
  }: JaneSearchState<T>,
  indexPrefix: string
): InstantSearchState => {
  const multiRange = bucketFilters
    ? Object.keys(bucketFilters).reduce((prev, filterKey) => {
        const values = bucketFilters[filterKey as keyof T];

        if (!values || !values.length) {
          return prev;
        }

        return { ...prev, [filterKey]: values[0] };
      }, {})
    : undefined;

  const range = rangeFilters
    ? Object.keys(rangeFilters).reduce((prev, filterKey) => {
        const rangeValue = rangeFilters[filterKey as keyof T];

        if (!rangeValue) {
          return prev;
        }

        return { ...prev, [filterKey]: rangeValue };
      }, {})
    : undefined;

  const refinementList = filters
    ? Object.keys(filters).reduce((prev, filterKey) => {
        const filterValues = filters[filterKey as keyof T];

        if (!filterValues || !filterValues.length) {
          return prev;
        }

        return { ...prev, [filterKey]: filterValues };
      }, {})
    : undefined;

  const sortBy = currentSort
    ? `${indexPrefix}${currentSort.suffix}${config.algoliaEnv}`
    : undefined;

  return {
    multiRange,
    page: page ? Number(page) : undefined,
    query: searchText ? searchText : '',
    range,
    refinementList,
    sortBy,
    currentSort,
  };
};

interface InstantSearchStateWithSearchText extends InstantSearchState {
  searchText?: string;
}

export const instantSearchStateToSearchState = <T,>({
  currentSort,
  multiRange,
  page,
  range,
  refinementList,
  query,
  searchText,
}: InstantSearchStateWithSearchText): JaneSearchState<T> => {
  const bucketFilters = multiRange
    ? Object.keys(multiRange).reduce((prev, filterKey) => {
        const rangeValue = multiRange[filterKey];

        if (!rangeValue) {
          return prev;
        }

        return { ...prev, [filterKey]: [rangeValue] };
      }, {})
    : undefined;

  const filters = refinementList
    ? Object.keys(refinementList).reduce((prev, filterKey) => {
        const filterValue = refinementList[filterKey];

        if (!filterValue) {
          return prev;
        }

        return {
          ...prev,
          // NOTE(elliot): to handle old url format ie. ?refinementList[root_types]=flower which gets parsed as a string instead of an array.
          [filterKey]: Array.isArray(filterValue) ? filterValue : [filterValue],
        };
      }, {})
    : undefined;

  const searchState: JaneSearchState<T> = {
    bucketFilters,
    filters,
    page,
    rangeFilters: range as JaneSearchState<T>['rangeFilters'],
    searchText: searchText || query,
  };

  if (currentSort) {
    searchState.currentSort = currentSort;
  }

  return searchState;
};

const isInstantSearchState = (searchState: Record<string, any>) =>
  'refinementList' in searchState ||
  'multiRange' in searchState ||
  'range' in searchState;
