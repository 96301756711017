/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const BankCircled64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM60 32C60 47.464 47.464 60 32 60C16.536 60 4 47.464 4 32C4 16.536 16.536 4 32 4C47.464 4 60 16.536 60 32Z"
      fill="#0E0E0E"
    />
    <path
      d="M30.1371 16.9806C31.3032 16.3668 32.6969 16.3668 33.8631 16.9806L45.628 23.1727C47.0138 23.902 46.4954 26.0001 44.9294 26.0001H19.0707C17.5047 26.0001 16.9863 23.902 18.3721 23.1727L30.1371 16.9806Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 28C30.1046 28 31 28.8954 31 30V38C31 39.1046 30.1046 40 29 40C27.8954 40 27 39.1046 27 38V30C27 28.8954 27.8954 28 29 28Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 28C36.1046 28 37 28.8954 37 30V38C37 39.1046 36.1046 40 35 40C33.8954 40 33 39.1046 33 38V30C33 28.8954 33.8954 28 35 28Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 44C46 45.1046 45.1046 46 44 46L20 46C18.8954 46 18 45.1046 18 44C18 42.8954 18.8954 42 20 42L44 42C45.1046 42 46 42.8954 46 44Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 28C24.1046 28 25 28.8954 25 30V38C25 39.1046 24.1046 40 23 40C21.8954 40 21 39.1046 21 38V30C21 28.8954 21.8954 28 23 28Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41 28C42.1046 28 43 28.8954 43 30V38C43 39.1046 42.1046 40 41 40C39.8954 40 39 39.1046 39 38V30C39 28.8954 39.8954 28 41 28Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  xxl: BankCircled64,
  xl: BankCircled64,
  lg: BankCircled64,
  md: BankCircled64,
  sm: BankCircled64,
  xxxl: BankCircled64,
};

export const BankCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bank-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
