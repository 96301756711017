import { useIframeScroll, useIsInIframe } from '@jane/shared-ecomm/hooks';
import type { CalloutVariants } from '@jane/shared-ecomm/types';
import { Box, Modal } from '@jane/shared/reefer';

import type { JaneAuthenticationProps } from '../authentication';
import { JaneAuthentication } from '../authentication';

export interface LogInModalProps
  extends Omit<JaneAuthenticationProps, 'screen' | 'setScreen'> {
  /** Location where the modal was opened, used for embedded modal styling */
  location?: CalloutVariants | 'header';

  /** Function called when modal is closed */
  onRequestClose(): void;

  /** Boolean for whether or not the modal is open */
  open: boolean;
}

export const LogInModal = ({
  location,
  open,
  onRequestClose,
  ...authenticationProps
}: LogInModalProps) => {
  const isInIframe = useIsInIframe();

  const useScroll =
    isInIframe &&
    open &&
    !!location &&
    ['header', 'cart', 'checkout'].includes(location);

  useIframeScroll(useScroll);

  return (
    <Modal
      variant="dialogue"
      open={open}
      overlayClose
      onRequestClose={onRequestClose}
      data-testid="log-in-modal"
      topOverride={isInIframe ? (useScroll ? '160px' : '350px') : undefined}
    >
      <Modal.Content>
        <Box maxWidth="297px">
          <JaneAuthentication {...authenticationProps} />
        </Box>
      </Modal.Content>
    </Modal>
  );
};
