import type { ReservationStore } from '@jane/shared/models';
import { Box, Flex, Form, PartnerLogo, Typography } from '@jane/shared/reefer';

import { ReviewForm } from './reviewForm';

export interface FormValues {
  rating?: number;
  review?: string;
}

export interface StoreReviewFormProps {
  /* Short description displayed under the store address. For reviewing an order, this would be the fulfillment method and date **/
  description?: string;

  /* The star rating input will render with this rating pre-selected (or none) **/
  initialRating?: number;

  onCancel: () => void;

  onSubmit: (values: FormValues) => void;

  store: ReservationStore;
}

export const StoreReviewForm = ({
  description,
  initialRating,
  onCancel,
  onSubmit,
  store,
}: StoreReviewFormProps) => {
  return (
    <Box width="100%">
      <Typography variant="header-bold" branded textAlign="center" mb={24}>
        Review store
      </Typography>

      <Flex alignItems="center">
        <PartnerLogo
          image={store.photo}
          variant="store"
          name={`${store.name} logo`}
        />

        <Box ml={16}>
          <Typography variant="body-bold">{store.name}</Typography>
          <Typography>{store.address}</Typography>
          {description && (
            <Typography color="grays-mid">{description}</Typography>
          )}
        </Box>
      </Flex>

      <Form
        name="store-review-form"
        onSubmit={(data: FormValues) => onSubmit(data)}
      >
        <ReviewForm onCancel={onCancel} initialRating={initialRating} />
      </Form>
    </Box>
  );
};
