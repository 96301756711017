import styled from '@emotion/styled';

import { Accordion } from '@jane/shared/components';
import { useGetStore } from '@jane/shared/data-access';
import type { Cart, StoreSpecial } from '@jane/shared/models';
import {
  Box,
  CheckIcon,
  Flex,
  PercentageIcon,
  ProfileCircledIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { useCustomerSelector } from '../../../../customer';
import {
  applicableCartTotalSpecial,
  applicableProductSpecials,
} from '../../../../lib/cart';
import { Divider } from '../cartDrawer/cartDrawer.styles';
import { SpecialsWrapper } from './cartDrawerSpecials.styles';
import { CustomerLoyaltyPoints } from './customerLoyaltyPoints/customerLoyaltyPoints';
import { CustomerLoyaltyPointsGuest } from './customerLoyaltyPoints/customerLoyaltyPointsGuest';
import {
  GroupDiscounts,
  groupSpecialsTitle,
} from './groupDiscounts/groupDiscounts';
import { PromoCodes } from './promoCodes/promoCodes';

interface CartDrawerSpecialsProps {
  headless?: boolean;
  specials: StoreSpecial[];
}

export const BoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  borderRadius: '25px',
  border: `1px solid ${theme.colors.grays.light}`,
  ...spacing({
    p: 56,
    mt: 24,
    mb: 12,
  }),
}));

export const CartDrawerSpecials = ({
  specials,
  headless,
}: CartDrawerSpecialsProps) => {
  const isMobile = useMobileMediaQuery({});
  const {
    cart: cartState,
    phone: customerPhone,
    id: customerId,
    queryPromoCode,
    disableAuthFeaturesState,
  } = useCustomerSelector(
    ({
      cart: { queryPromoCode, cart },
      customer: { id, phone },
      embeddedApp: { disableAuthFeatures },
    }) => ({
      cart,
      id,
      phone,
      queryPromoCode,
      disableAuthFeaturesState: disableAuthFeatures,
    })
  );

  const disableAuthFeatures = disableAuthFeaturesState || false;
  const cart = cartState as Cart;
  const { data: store } = useGetStore(cart?.store.id);

  const {
    cart_discount_special_id,
    discounted_subtotal,
    reservation_mode,
    loyalty_points_stacking_enabled,
  } = cart;

  const specialsLabel = store.store_compliance_language_settings?.['specials'];
  const productSpecials = applicableProductSpecials(cart, specials);

  const groupSpecials = specials.filter(
    (special) =>
      special.special_type === 'qualified_group' &&
      special.reservation_modes &&
      special.reservation_modes[reservation_mode]
  );

  const cartTotalSpecial = applicableCartTotalSpecial(
    specials,
    cart_discount_special_id
  );
  const appliedSpecials = cartTotalSpecial
    ? productSpecials.concat([cartTotalSpecial])
    : productSpecials;

  const appliedPromoCodeSpecial = appliedSpecials.find((s) => s.promo_code);

  const appliedGroupSpecial = appliedSpecials.find(
    (s) => s.special_type === 'qualified_group'
  );

  const shouldDisplayLoyaltyPoints = () => {
    return Boolean(customerPhone && store.crm_integration && !!customerId);
  };

  return (
    <SpecialsWrapper flexDirection="column" headless={headless}>
      {headless ? (
        <BoxWrapper>
          <Typography variant="header-bold" mb={24}>
            Apply{' '}
            {store?.store_compliance_language_settings?.['promo_code'] ||
              'promo code'}
          </Typography>
          <PromoCodes
            headless={headless}
            reservationMode={reservation_mode}
            userGroupSpecialId={appliedGroupSpecial?.id}
          />
        </BoxWrapper>
      ) : (
        <Accordion.Item
          id="promo-code"
          defaultExpanded={queryPromoCode !== undefined}
        >
          <Accordion.Heading px={isMobile ? 24 : 40} py={20}>
            <Flex alignItems="center">
              <Flex flexDirection="column">
                <Flex>
                  <div>
                    <PercentageIcon />
                  </div>
                  <Typography variant="body-bold" ml={16}>
                    Apply{' '}
                    {store?.store_compliance_language_settings?.[
                      'promo_code'
                    ] || 'promo code'}
                  </Typography>
                </Flex>
                {appliedPromoCodeSpecial && (
                  <Flex alignItems="center" mt={8}>
                    <CheckIcon size="sm" color="success" mr={8} />
                    <Typography>{appliedPromoCodeSpecial.title}</Typography>
                  </Flex>
                )}
              </Flex>
              <Flex ml="auto">
                <Accordion.Indicator iconSize="md" />
              </Flex>
            </Flex>
          </Accordion.Heading>
          <Accordion.Content px={isMobile ? 24 : 40} pb={24} destroyOnClose>
            <PromoCodes
              reservationMode={reservation_mode}
              userGroupSpecialId={appliedGroupSpecial?.id}
            />
          </Accordion.Content>
        </Accordion.Item>
      )}
      {!disableAuthFeatures && groupSpecials.length > 0 && headless && (
        <BoxWrapper>
          <Typography
            variant="header-bold"
            mb={24}
            css={{ wordBreak: 'break-word' }}
          >
            {groupSpecialsTitle(groupSpecials, specialsLabel)}
          </Typography>
          <GroupDiscounts
            userGroupSpecialId={appliedGroupSpecial?.id}
            headless={headless}
            groupSpecials={groupSpecials}
          />
          {appliedGroupSpecial && (
            <Flex alignItems="center" mt={8}>
              <CheckIcon size="sm" color="success" mr={8} />
              <Typography>{appliedGroupSpecial.title}</Typography>
            </Flex>
          )}
        </BoxWrapper>
      )}
      {!disableAuthFeatures && groupSpecials.length > 0 && !headless && (
        <>
          <Divider />
          <Accordion.Item id="group-discount">
            <Accordion.Heading px={isMobile ? 24 : 40} py={20}>
              <Flex alignItems="center" justifyContent="space-between">
                <Flex flexDirection="column">
                  <Flex alignItems="center">
                    <div>
                      <ProfileCircledIcon />
                    </div>
                    <Typography
                      variant="body-bold"
                      ml={16}
                      css={{ wordBreak: 'break-word' }}
                    >
                      {groupSpecialsTitle(groupSpecials, specialsLabel)}
                    </Typography>
                  </Flex>
                  {appliedGroupSpecial && (
                    <Flex alignItems="center" mt={8}>
                      <CheckIcon size="sm" color="success" mr={8} />
                      <Typography>{appliedGroupSpecial.title}</Typography>
                    </Flex>
                  )}
                </Flex>
                <Flex pl={16}>
                  <Accordion.Indicator iconSize="md" />
                </Flex>
              </Flex>
            </Accordion.Heading>
            <Accordion.Content px={isMobile ? 24 : 40} destroyOnClose>
              <GroupDiscounts
                userGroupSpecialId={appliedGroupSpecial?.id}
                groupSpecials={groupSpecials}
              />
            </Accordion.Content>
          </Accordion.Item>
        </>
      )}

      {!!store?.crm_integration && !shouldDisplayLoyaltyPoints() && (
        <>
          {!headless && <Divider />}
          <CustomerLoyaltyPointsGuest
            isHeadless={headless}
            reservationMode={reservation_mode}
            storeRewardLabel={
              store?.store_compliance_language_settings?.['store_reward']
            }
          />
        </>
      )}

      {shouldDisplayLoyaltyPoints() && !headless && (
        <>
          <Divider />
          <CustomerLoyaltyPoints
            cartUuid={cart.uuid}
            phone={customerPhone}
            loyaltyPointsStacking={loyalty_points_stacking_enabled}
            postDiscountSubtotal={discounted_subtotal}
            appliedRedemptions={cart.crm_redemptions}
            reservationMode={reservation_mode}
            store={store}
          />
        </>
      )}

      {shouldDisplayLoyaltyPoints() && headless && (
        <BoxWrapper>
          <Typography
            variant="header-bold"
            mb={24}
            css={{ wordBreak: 'break-word' }}
          >
            Use store rewards
          </Typography>
          <CustomerLoyaltyPoints
            headless={true}
            cartUuid={cart.uuid}
            phone={customerPhone}
            loyaltyPointsStacking={loyalty_points_stacking_enabled}
            postDiscountSubtotal={discounted_subtotal}
            appliedRedemptions={cart.crm_redemptions}
            reservationMode={reservation_mode}
            store={store}
          />
        </BoxWrapper>
      )}
    </SpecialsWrapper>
  );
};
