/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ProfileCircled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10ZM15.0005 14C16.2147 13.0878 17 11.6356 17 10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10C7 11.6356 7.78534 13.0878 8.99952 14C7.78534 14.9122 7 16.3644 7 18H9C9 16.3431 10.3431 15 12 15C13.6569 15 15 16.3431 15 18H17C17 16.3644 16.2147 14.9122 15.0005 14Z"
      fill="#0E0E0E"
    />
    <circle cx={12} cy={12} r={11} stroke="#0E0E0E" strokeWidth={2} />
  </svg>
);

const SIZE_MAP = {
  md: ProfileCircled24,
  sm: ProfileCircled24,
  lg: ProfileCircled24,
  xl: ProfileCircled24,
  xxl: ProfileCircled24,
  xxxl: ProfileCircled24,
};

export const ProfileCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'profile-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
