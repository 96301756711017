/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Account24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11706_30085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.001C18.6274 24.001 24 18.6284 24 12.001C24 5.37356 18.6274 0.000976562 12 0.000976562C5.37258 0.000976562 0 5.37356 0 12.001C0 18.6284 5.37258 24.001 12 24.001ZM5.29289 15.2939C4.90237 15.6844 4.90237 16.3176 5.29289 16.7081C8.99713 20.4123 15.0029 20.4123 18.7071 16.7081C19.0976 16.3176 19.0976 15.6844 18.7071 15.2939C18.3166 14.9033 17.6834 14.9033 17.2929 15.2939C14.3697 18.2171 9.63029 18.2171 6.70711 15.2939C6.31658 14.9033 5.68342 14.9033 5.29289 15.2939ZM10 9.00098C10 10.1055 9.10457 11.001 8 11.001C6.89543 11.001 6 10.1055 6 9.00098C6 7.89641 6.89543 7.00098 8 7.00098C9.10457 7.00098 10 7.89641 10 9.00098ZM16 11.001C17.1046 11.001 18 10.1055 18 9.00098C18 7.89641 17.1046 7.00098 16 7.00098C14.8954 7.00098 14 7.89641 14 9.00098C14 10.1055 14.8954 11.001 16 11.001Z"
        fill="#5E1FF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_11706_30085">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Account24,
  sm: Account24,
  lg: Account24,
  xl: Account24,
  xxl: Account24,
  xxxl: Account24,
};

export const AccountIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'account-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
