import { useEffect, useRef, useState } from 'react';

import { useQuery } from '@jane/shared-ecomm/hooks';
import {
  Box,
  Button,
  DismissFilledIcon,
  SearchIcon,
  fakeButtonProps,
  getTextWithHighlights,
  handleEnterKey,
  useMobileMediaQuery,
  useWindowListener,
} from '@jane/shared/reefer';
import { Storage } from '@jane/shared/util';

import {
  FilterListButton,
  FilterListItem,
  StyledFilterList,
} from '../filter.styles';
import {
  ClearButtonWrapper,
  FilterSearchContainer,
  FilterToggleWrapper,
  NoClickEvent,
  SearchIconWrapper,
  StyledContent,
  StyledInput,
} from './filterSearch.styles';

export interface FilterSearchProps {
  /** Whether or not to enable the recent search */
  enableRecentSearches?: boolean;

  /** Function that handles resetting the search */
  onClear?: () => void;

  /** Function that handles the query search request */
  onSearch: (value: string) => void;

  /** Search Id */
  searchId?: string | number;

  /** Variant, sets various text and labels */
  variant?: 'brand' | 'product' | 'store';
}

export const FilterSearch = ({
  onSearch,
  onClear,
  searchId,
  enableRecentSearches = true,
  variant = 'product',
}: FilterSearchProps) => {
  const { query: urlQuery } = useQuery();
  const [queryString, setQueryString] = useState('');
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [recentSearchOpen, setRecentSearchOpen] = useState(false);
  const SEARCH_HISTORY_KEY = `searchHistory-${searchId}`;

  const isMobile = useMobileMediaQuery({});
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const history = (Storage.get(SEARCH_HISTORY_KEY) as string[]) || [];
  const filteredHistoryItems = history
    .filter(
      (search: string, index: number) => history.indexOf(search) === index
    )
    .filter((search: string) => search !== '');

  const updateSearchHistory = (latestSearch: string) => {
    const workingHistory = history.length > 3 ? history.slice(1) : history;
    const updatedHistory = workingHistory.concat(latestSearch);

    Storage.set(SEARCH_HISTORY_KEY, updatedHistory);
  };

  const emptyQuery = queryString.length === 0;
  const recentSearchContent = !emptyQuery || history.length > 0;

  const openFilterSearch = () => {
    setSearchBarOpen(true);
    enableRecentSearches && setRecentSearchOpen(true);
  };

  const handleSearch = (value: string) => {
    if (value) {
      onSearch(value);
      enableRecentSearches && updateSearchHistory(value);
    } else {
      onClear && onClear();
    }

    setQueryString('');
    setSearchBarOpen(false);
  };

  const handleSubmit = () => {
    handleSearch(queryString);
  };

  const handleClickEvents = (event: MouseEvent) => {
    const domPath = event.composedPath && event.composedPath();

    const eventTarget = domPath ? domPath[0] : (event.target as HTMLElement);

    const clickingInFilterSearch = ref.current?.contains(eventTarget as Node);

    // no query? close
    if (!clickingInFilterSearch && emptyQuery) {
      setSearchBarOpen(false);
    }

    // close recent searches on click away
    if (!clickingInFilterSearch && recentSearchOpen) {
      setRecentSearchOpen(false);

      if (emptyQuery) {
        setSearchBarOpen(false);
      }
    }

    // open recent search if searchBar is open
    if (clickingInFilterSearch && !recentSearchOpen && enableRecentSearches) {
      setRecentSearchOpen(true);
    }
  };

  const handleClear = () => {
    setQueryString('');
    recentSearchOpen
      ? inputRef?.current && inputRef.current.focus()
      : setSearchBarOpen(false);
  };

  // pulls down search query passed from global search
  useEffect(() => {
    if (urlQuery) {
      onSearch(urlQuery);
    }
  }, [urlQuery, onSearch]);

  useWindowListener('click', handleClickEvents);

  return (
    <FilterSearchContainer ref={ref}>
      <FilterToggleWrapper
        aria-label={`Open ${variant} search`}
        searchBarOpen={searchBarOpen}
        onClick={openFilterSearch}
        onKeyUp={(event) => handleEnterKey(event, openFilterSearch)}
        {...fakeButtonProps}
      >
        <Button.Icon
          icon={
            <NoClickEvent>
              <SearchIcon />
            </NoClickEvent>
          }
          variant="tertiary"
        />
      </FilterToggleWrapper>

      {searchBarOpen && (
        <>
          <Box
            position="relative"
            {...(recentSearchOpen && {
              'aria-controls': 'recent-searches',
            })}
          >
            <form action="#" onSubmit={handleSubmit} autoComplete="off">
              <Box position="relative">
                <StyledInput
                  autoFocus
                  type="search"
                  name={`${variant}Search`}
                  title={`${variant}Search`}
                  placeholder={`Search ${variant}s`}
                  onChange={(e) => setQueryString(e.target.value)}
                  value={queryString}
                  ref={inputRef}
                />
                <SearchIconWrapper className="filter-search__icon">
                  <SearchIcon mr={4} color="inherit" />
                </SearchIconWrapper>

                <ClearButtonWrapper
                  hasQuery={queryString.length > 0}
                  onClick={handleClear}
                  onKeyUp={(event) => handleEnterKey(event, handleClear)}
                  {...fakeButtonProps}
                  aria-label="Clear search input"
                >
                  <NoClickEvent>
                    <DismissFilledIcon />
                  </NoClickEvent>
                </ClearButtonWrapper>
              </Box>
            </form>
          </Box>
          {!isMobile && recentSearchOpen && recentSearchContent && (
            <StyledContent
              id="recent-searches"
              data-testid="recent-searches"
              onClick={(e) => {
                // Prevent clicks within popover content from triggering the
                // "Click away" behavior that closes the recent searches.
                e.stopPropagation();
              }}
            >
              <StyledFilterList>
                {queryString && (
                  <FilterListItem key="search-for">
                    <FilterListButton
                      onClick={() => {
                        handleSearch(queryString);

                        setSearchBarOpen(false);
                        setRecentSearchOpen(false);
                      }}
                      value={queryString}
                    >
                      Search for "{queryString}"
                    </FilterListButton>
                  </FilterListItem>
                )}
                {filteredHistoryItems.reverse().map((search: string) => (
                  <FilterListItem key={search}>
                    <FilterListButton
                      onClick={() => {
                        handleSearch(search);

                        setSearchBarOpen(false);
                        setRecentSearchOpen(false);
                      }}
                      value={search}
                    >
                      {queryString
                        ? getTextWithHighlights(search, queryString)
                        : search}
                    </FilterListButton>
                  </FilterListItem>
                ))}
              </StyledFilterList>
            </StyledContent>
          )}
        </>
      )}
    </FilterSearchContainer>
  );
};
