import styled from '@emotion/styled';
import type {
  FunctionComponent,
  ReactNode,
  RefCallback,
  RefObject,
} from 'react';
import {
  forwardRef,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useIsRecommendedSortEnabled } from '@jane/dm/internal';
import { useSearchContext } from '@jane/search/providers';
import type { AlgoliaProduct, Hit, JaneSearchState } from '@jane/search/types';
import { FilterSortId } from '@jane/search/types';
import {
  PRODUCTS_PER_PAGE_CAROUSEL,
  PRODUCTS_PER_PAGE_TABLE,
  makeSeeAllLinkSearchState,
} from '@jane/search/util';
import { useMenuProductGridData } from '@jane/shared-ecomm/hooks';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { filterAds } from '@jane/shared-ecomm/util';
import {
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_MOBILE_CARD_WIDTH,
  LoadingWrapper,
} from '@jane/shared/components';
import type {
  MenuProduct,
  Store,
  StoreSpecial,
  Zone,
} from '@jane/shared/models';
import { Typography } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';
import {
  getAppliedWeightFilter,
  postMessageToIframeParent,
} from '@jane/shared/util';

import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { useIsCurrentSortRecommended } from '../../hooks/smartSort/useIsCurrentSortRecommended';
import BucketFooter from './bucketFooter';
import BucketHeader from './bucketHeader';
import HitProductCard from './hitProductCard';
import { useStoreMenuContext } from './providers/storeMenuProvider';

const MENU_PRODUCT_GRID_GAP = '24px 24px';

export const Count: FunctionComponent<{
  children?: ReactNode;
  underline?: boolean;
}> = ({ children, underline }) => (
  <Typography
    color="grays-mid"
    css={underline && { textDecoration: 'underline' }}
    variant="body"
  >
    {children}
  </Typography>
);

const MenuProductsGridItem = styled.div<{ firstColumn: boolean }>`
  grid-column: ${({ firstColumn }) =>
    firstColumn ? 1 : undefined}; // ensure ads remain in first column
`;

export const MenuProductsGrid = styled.div<{
  listView: boolean;
}>(
  ({ listView }) =>
    !listView && {
      display: 'grid',
      gridGap: MENU_PRODUCT_GRID_GAP,
      justifyContent: 'flex-start',
      gridAutoRows: '1fr',
      gridAutoFlow: 'row dense',
      overflowX: 'auto',
      // if changing how columns are defined, please ensure that useGetNumColumnsInCssGrid still works
      gridTemplateColumns: `repeat(2, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
      [mediaQueries.tablet('min')]: {
        // if changing how columns are defined, please ensure that useGetNumColumnsInCssGrid still works
        gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_DESKTOP_CARD_WIDTH}px, 1fr))`,
        height: '100%',
      },
    }
);

interface Props {
  filterExistsOnAlgoliaRootTypes: boolean;
  hasMore: boolean;
  hits?: Hit<MenuProduct>[];
  indexName?: string;
  kindValues: string[];
  lastBucket: boolean;
  listView: boolean;
  name: string;
  numColumns: number;
  numHits: number | undefined;
  outerSearchState: JaneSearchState<AlgoliaProduct>;
  paginationCount: number;
  placementIndex: number;
  refine: () => void;
  setPaginationCount: (arg: number) => void;
  specials?: StoreSpecial[];
  store: Store;
  storeHeaderHeight: number;
  zone: Zone;
}

const ProductKindBucketTable = (
  {
    hasMore,
    hits,
    indexName,
    listView,
    name,
    numHits,
    kindValues,
    outerSearchState,
    refine,
    specials,
    store,
    filterExistsOnAlgoliaRootTypes,
    paginationCount,
    placementIndex,
    setPaginationCount,
    storeHeaderHeight,
    zone,
    numColumns,
  }: Props,
  emptyGridRef: RefCallback<HTMLElement>
) => {
  const { appMode } = useStoreMenuContext();
  const [expanded, setExpanded] = useState(false);
  const names = kindValues.length > 1 ? kindValues : [kindValues[0] || name];
  const seeAllLinkSearchState = makeSeeAllLinkSearchState(
    names,
    outerSearchState
  );

  const topRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const {
    searchState: { currentSort },
  } = useSearchContext();
  const isRecommendedSortEnabled = useIsRecommendedSortEnabled(store, appMode);
  const isCurrentSortRecommended = useIsCurrentSortRecommended({
    currentSort,
    isTable: !listView,
    store,
  });
  const isCurrentSortDefault = !currentSort || currentSort.isDefault;
  const isCurrentSortAToZ =
    currentSort?.id === FilterSortId.AZ ||
    (isCurrentSortDefault && !isRecommendedSortEnabled);

  useLayoutEffect(() => {
    if (paginationCount === 1) {
      window.scrollTo(0, storeHeaderHeight - 100);
    }
  }, [paginationCount, storeHeaderHeight]);

  useEffect(() => {
    window.scrollTo(0, storeHeaderHeight);
  }, [filterExistsOnAlgoliaRootTypes, storeHeaderHeight]);

  const onLoadMore = (isActualClickEvent?: boolean) => {
    setExpanded(true);
    refine();

    if (isActualClickEvent) {
      setPaginationCount(paginationCount + 1);
      track({ event: EventNames.ClickedShowMore, category: name });
    }
  };

  const onCollapse = () => {
    postMessageToIframeParent({
      messageType: 'shrinkNextResize',
    });
    setPaginationCount(1);
    scrollToTopOfBucket();
    setExpanded(false);
  };

  const scrollToTopOfBucket = () => {
    if (topRef && topRef.current) {
      if (isEmbeddedMode) {
        postMessageToIframeParent({
          messageType: 'scrollToBucketTop',
          payload: topRef.current.offsetTop - 100,
        });

        return;
      }
      window.scrollTo(0, topRef.current.offsetTop - 100);
    }
  };

  const sortHits = (hits: Hit<MenuProduct>[]): Hit<MenuProduct>[] => {
    let sorted = hits || [];
    if (isCurrentSortAToZ && kindValues.includes('best_selling')) {
      sorted = hits.sort(
        (a, b) => (a.best_seller_rank || 0) - (b.best_seller_rank || 0)
      );
    }
    return expanded
      ? sorted
      : sorted.slice(
          0,
          filterExistsOnAlgoliaRootTypes
            ? PRODUCTS_PER_PAGE_TABLE
            : PRODUCTS_PER_PAGE_CAROUSEL
        );
  };

  const gridRef = useRef(null);
  const gridData = useMenuProductGridData(gridRef, hits?.length);

  const hitsToRender = useMemo(() => {
    const result = sortHits([...hits]);
    return isCurrentSortRecommended ? result : filterAds(gridData, result);
  }, [hits, gridData]);

  if (!hits || numHits < 1) {
    return <MenuProductsGrid listView={listView} ref={emptyGridRef} />;
  }

  return (
    <div ref={topRef}>
      <LoadingWrapper
        variant={listView ? 'carousel-list' : 'carousel-grid'}
        isLoading={typeof numHits !== 'number'}
      >
        <BucketHeader
          count={numHits}
          expanded={expanded}
          name={name}
          onCollapse={onCollapse}
          placementIndex={placementIndex}
          seeAllLinkSearchState={seeAllLinkSearchState}
          showSeeAll={listView && !filterExistsOnAlgoliaRootTypes}
          store={store}
        />

        <MenuProductsGrid listView={listView} ref={gridRef}>
          {hitsToRender.map((hit, index) => {
            return (
              <MenuProductsGridItem
                key={`${hit?.flight ? 'inline-ad-' : 'mp-'}${
                  hit.id ?? hit.product_id ?? hit.objectID
                }`}
                firstColumn={!isCurrentSortRecommended && !!hit?.flight}
              >
                <HitProductCard
                  appliedWeightFilter={getAppliedWeightFilter(outerSearchState)}
                  bucketName={name}
                  specials={specials}
                  hit={hit}
                  listView={listView}
                  searchState={outerSearchState || {}}
                  store={store}
                  index={index}
                  algoliaIndexName={indexName}
                  zone={zone}
                  numColumns={numColumns}
                />
              </MenuProductsGridItem>
            );
          })}
        </MenuProductsGrid>

        <BucketFooter
          expanded={expanded}
          filterExistsOnAlgoliaRootTypes={filterExistsOnAlgoliaRootTypes}
          hasMore={hasMore}
          loadedCount={hitsToRender.length}
          name={name}
          numberHitsRendered={hitsToRender.length}
          onLoadMore={onLoadMore}
          onScrollToTop={scrollToTopOfBucket}
          paginationCount={paginationCount}
          placementIndex={placementIndex}
          seeAllLinkSearchState={seeAllLinkSearchState}
          store={store}
          totalCount={numHits}
        />
      </LoadingWrapper>
    </div>
  );
};

export default memo(forwardRef(ProductKindBucketTable));
