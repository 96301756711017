import { useLocation } from 'react-router-dom';

import { useJaneUser } from '@jane/shared/data-access';
import { Button, Flex, Form, Typography, telRegex } from '@jane/shared/reefer';

export interface MoreInfoFormData {
  firstName?: string;
  lastName?: string;
  phone?: number | string;
}
export interface MoreInfoScreenProps {
  /** Function called when 'Cancel' button is clicked */
  onDoneClick(): void;

  /** Function called on form submit */
  onSubmit(values: MoreInfoFormData): void;
}

export const MoreInfoScreen = ({
  onDoneClick,
  onSubmit,
}: MoreInfoScreenProps) => {
  const { state } = useLocation();
  const preAuthCustomer = state?.preAuthCustomer;
  const { data: userData } = useJaneUser();

  return (
    <Form name="user-information" onSubmit={onSubmit}>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Typography variant="header-bold" textAlign="center" branded>
          Let's make sure we have your information.
        </Typography>
        <Form.NumberField
          width="100%"
          disableMobileInputStyling
          allowLeadingZeros
          autocomplete="off"
          label="Phone number"
          maskFormat="(###) ###-####"
          name="phone"
          my={24}
          pattern={telRegex}
          defaultValue={
            userData?.user.phone ? parseInt(userData.user.phone) : undefined
          }
          required
        />
        <Form.TextField
          label="First name"
          name="firstName"
          width="100%"
          disableMobileInputStyling
          defaultValue={userData?.user.first_name || preAuthCustomer?.firstName}
          required
          mb={24}
        />
        <Form.TextField
          label="Last name"
          name="lastName"
          width="100%"
          disableMobileInputStyling
          defaultValue={userData?.user.last_name || preAuthCustomer?.lastName}
          required
          mb={24}
        />
        <Form.SubmitButton full variant="primary" label="Continue" mb={16} />
        <Button full variant="secondary" label="Cancel" onClick={onDoneClick} />
      </Flex>
    </Form>
  );
};
