import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import type { LoginCredentials, User } from '@jane/shared/models';
import { Flex, Link } from '@jane/shared/reefer';

import { setLoginValue } from '../../common/redux/login';
import { TextInputField } from '../../component-library/text-input';
import { useCustomerDispatch } from '../../customer/dispatch';
import { openCart } from '../../customer/redux/cart';
import { logInError, logInSuccess } from '../../customer/redux/customer';
import { useCustomerSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { CustomerSource } from '../../sources/customer';
import type { ShowLoginProps } from './common';
import {
  CustomFieldError,
  PrimaryLoginButton,
  SUBMIT_ERROR_TEXT,
  SubmitErrorMessage,
} from './common';
import LoginSecondaryAction from './secondaryAction';

type Props = {
  embeddedMode: boolean;
  nextPathname: string | null;
  onLoginSuccess?: (user: User) => void;
} & ShowLoginProps;

const EmailError = ({ setShowLogin }: ShowLoginProps) => {
  const theme = useTheme();

  return (
    <CustomFieldError>
      We cannot find an account with that email address. Would you like to&nbsp;
      <button
        css={{ color: theme.colors.brand.purple.main }}
        onClick={() => (setShowLogin ? setShowLogin(false) : null)}
      >
        sign up
      </button>
      ?
    </CustomFieldError>
  );
};

const PasswordError = ({
  forgotPasswordPath,
}: {
  forgotPasswordPath: string;
}) => (
  <CustomFieldError>
    Wrong password. Try again or click&nbsp;
    <Link color="error" to={forgotPasswordPath}>
      Forgot password
    </Link>
    &nbsp;to reset it.
  </CustomFieldError>
);

const LoginForm = ({
  embeddedMode,
  nextPathname,
  setShowLogin,
  onLoginSuccess,
}: Props) => {
  const { state } = useLocation() as { state?: { from?: string } };
  const navigate = useNavigate();
  const { email, nickname, id } = useCustomerSelector(get('customer'));
  const loginState = useCustomerSelector(get('login'));
  const dispatch = useCustomerDispatch();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const forgotPasswordPath = embeddedMode
    ? paths.embeddedForgotPassword()
    : paths.forgotPassword();

  interface Errors {
    email?: string;
    password?: string;
  }

  const validate = (values: any) => {
    const errors: Errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  const renderEmailError = () => {
    return <EmailError setShowLogin={setShowLogin} />;
  };

  const renderPasswordError = () => {
    return <PasswordError forgotPasswordPath={forgotPasswordPath} />;
  };

  const onSubmit = (user: LoginCredentials) => {
    setErrorMessage(undefined);
    const emailFieldVal = user.email;

    return CustomerSource.logIn(user).then((response) => {
      const { body, headers } = response;
      const { user, errors } = body;
      dispatch(setLoginValue({ name: 'email', value: emailFieldVal }));
      if (errors) {
        if (errors.error !== 'Invalid Email or password.') {
          setErrorMessage(errors.error);
        }

        dispatch(logInError(errors.error));
        return errors.validations;
      }

      dispatch(logInSuccess({ user, headers, nextPathname, navigate }));
      onLoginSuccess && onLoginSuccess(user);

      if (state?.from === 'cartDrawer') {
        dispatch(openCart());
      }
    });
  };

  return (
    <Flex flexDirection="column">
      <Form
        initialValues={{
          email: email || loginState?.user?.email || '',
          password: '',
        }}
        onSubmit={onSubmit}
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          submitFailed,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextInputField
              name="email"
              type="email"
              label="Email address"
              id="email"
              autoComplete="email"
              renderSubmitError={renderEmailError}
            />
            <TextInputField
              name="password"
              type="password"
              label="Password"
              id="password"
              renderSubmitError={renderPasswordError}
              autoComplete="current-password"
            />
            <Flex justifyContent="flex-end" mt={12}>
              <Link to={forgotPasswordPath}>Forgot password?</Link>
            </Flex>

            <Flex flexDirection="column" mt={20}>
              <PrimaryLoginButton
                onClick={handleSubmit}
                type="submit"
                data-testid="login-submit-btn"
                disabled={submitting}
                label="Sign in"
                loading={submitting}
              />
              {!errorMessage &&
                (hasSubmitErrors || (submitFailed && hasValidationErrors)) && (
                  <SubmitErrorMessage>{SUBMIT_ERROR_TEXT}</SubmitErrorMessage>
                )}
              {errorMessage && (
                <SubmitErrorMessage>{errorMessage}</SubmitErrorMessage>
              )}
            </Flex>
          </form>
        )}
      />

      <LoginSecondaryAction
        customerKnown={!!id}
        nickname={nickname || ''}
        embeddedMode={embeddedMode}
      />
    </Flex>
  );
};

export default LoginForm;
