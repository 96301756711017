import styled from '@emotion/styled';

import { Drawer } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const DrawerFooter = styled(Drawer.Footer)<{ fixedFooter?: boolean }>(
  ({ fixedFooter }) => ({
    ...(fixedFooter
      ? {
          [mediaQueries.desktop('sm', 'max')]: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 1,
          },
        }
      : {}),
  })
);
