/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Filters24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3C17.8638 3 19.4299 4.27477 19.874 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.874C19.4299 9.72523 17.8638 11 16 11C14.1362 11 12.5701 9.72523 12.126 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H12.126C12.5701 4.27477 14.1362 3 16 3ZM16 9C17.1046 9 18 8.10457 18 7C18 5.89543 17.1046 5 16 5C14.8954 5 14 5.89543 14 7C14 8.10457 14.8954 9 16 9Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 17C2 17.5523 2.44772 18 3 18H4.12602C4.57006 19.7252 6.13616 21 8 21C9.86384 21 11.4299 19.7252 11.874 18H21C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16H11.874C11.4299 14.2748 9.86384 13 8 13C6.13616 13 4.57006 14.2748 4.12602 16H3C2.44772 16 2 16.4477 2 17ZM10 17C10 15.8954 9.10457 15 8 15C6.89543 15 6 15.8954 6 17C6 18.1046 6.89543 19 8 19C9.10457 19 10 18.1046 10 17Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Filters24,
  sm: Filters24,
  lg: Filters24,
  xl: Filters24,
  xxl: Filters24,
  xxxl: Filters24,
};

export const FiltersIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'filters-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
