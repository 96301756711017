import { api, generateTrackingUrls, generateUrl } from '../api';
import {
  TopOfMenuRowProduct as RecommendedPDPRowProduct,
  TopOfMenuRowClient,
} from '../topOfMenuRow';
import type {
  FetchTopOfMenuRowApiResponse,
  TopOfMenuRowProductProps,
  TopOfMenuRowRenderPayload,
} from '../topOfMenuRow';
import { zoneStorePdp } from '../types/zones';
import { typedJSONParse } from '../utils/typedJsonParse';
import type {
  CreateRecommendedPDPRowProps,
  RecommendedPDPRowProps,
} from './recommendedPDPRow.types';

export class RecommendedPDPRow {
  public title = 'Discover additional products' as const;
  public products: RecommendedPDPRowProduct[];

  private renderEndpoint: string;
  private renderPayload: TopOfMenuRowRenderPayload;
  private hasBeenRendered: boolean;

  constructor(props: RecommendedPDPRowProps) {
    this.products = props.products.map(
      (productProps) => new RecommendedPDPRowProduct(productProps)
    );
    this.renderPayload = props.renderPayload;
    this.renderEndpoint = props.renderEndpoint;
    this.hasBeenRendered = props.hasBeenRendered ?? false;
  }

  public render(): Promise<void> {
    if (this.hasBeenRendered) {
      return Promise.resolve();
    }

    return api.post(this.renderEndpoint, this.renderPayload).then(() => {
      this.hasBeenRendered = true;
    });
  }

  public serialize(): RecommendedPDPRowProps {
    return {
      hasBeenRendered: this.hasBeenRendered,
      products: this.products.map((product) => product.serialize()),
      renderEndpoint: this.renderEndpoint,
      renderPayload: this.renderPayload,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): RecommendedPDPRow {
    const { products, renderPayload, renderEndpoint } =
      typedJSONParse<RecommendedPDPRowProps>(serialized);

    return new RecommendedPDPRow({
      products,
      renderEndpoint,
      renderPayload,
    });
  }
}

export class RecommendedPDPRowClient extends TopOfMenuRowClient {
  private generatePDPUrl(options: CreateRecommendedPDPRowProps): string {
    const url = `/v1/stores/${options.storeId}/sponsored`;

    const searchParams = new URLSearchParams({
      app_mode: options.appMode,
      jane_device_id: options.identifier.jdid,
      mp_distinct_id: options.identifier.jdid,
      zone: zoneStorePdp,
    });

    searchParams.set('excluded_brand_names', options.currentBrandName);

    if (options.filters) {
      const filterRootTypes = options.filters.rootTypes?.join(',');

      if (filterRootTypes) {
        searchParams.set('current_root_types', filterRootTypes);
      }

      const filterLineages = options.filters.categories?.join(',');

      if (filterLineages) {
        searchParams.set('current_lineages', filterLineages);
      }
    }

    return generateUrl(options.endpoint, `${url}?${searchParams.toString()}`, {
      apiKey: options.apiKey,
      source: options.source,
      version: options.version,
    });
  }

  public async createRecommendedPDPRow(
    props: CreateRecommendedPDPRowProps
  ): Promise<RecommendedPDPRow> {
    const url = this.generatePDPUrl(props);

    const response = (await api.get(url)) as FetchTopOfMenuRowApiResponse;
    const { clickEndpoint, impressionEndpoint } = generateTrackingUrls(
      props.endpoint,
      { apiKey: props.apiKey, source: props.source, version: props.version }
    );

    const products: TopOfMenuRowProductProps[] =
      response.products?.map((product) => ({
        attributes: {},
        clickEndpoint,
        clickPayload: this.generateProductClickPayload(
          product,
          response.flight?.kevel_token,
          props.identifier
        ),
        productId: product.product_id,
      })) ?? [];

    const renderPayload = this.generateRenderPayload(
      response.products ?? [],
      response.flight?.kevel_token ?? '',
      props.identifier
    );

    return new RecommendedPDPRow({
      products,
      renderEndpoint: impressionEndpoint,
      renderPayload,
    });
  }
}
