import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getJwt } from '@jane/shared/auth';
import type { User } from '@jane/shared/models';

import { janeApi } from '../api';
import { userQueryKeys, userUrls } from './user.util';

interface UserData {
  user: Partial<User>;
}

const updateUser = async (user: Partial<User>) =>
  await janeApi.patch(userUrls.users(), user, getJwt());

export const useUpdateJaneUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: Partial<User>) => updateUser(user),
    onSuccess: (result) => {
      queryClient.setQueryData(userQueryKeys.janeUser(), (data?: UserData) => ({
        user: {
          ...(data?.user || {}),
          ...(result as UserData).user,
        },
      }));
    },
  });
};
