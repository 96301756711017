import type { SelectBankAccount } from '@jane/shared-ecomm/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { AeropayBankAccount } from '@jane/shared/models';
import type { MarginProperties } from '@jane/shared/reefer';
import {
  Box,
  ConditionalWrapper,
  Flex,
  JaneLogo,
  JanePayLogo,
  Link,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import { BankCircledIcon } from './bankIcon';

export interface BankAccountDetailsProps extends MarginProperties {
  /** Bank account passed to the component for displaying its details and selected status if "selected" flag is on */
  bankAccount: AeropayBankAccount;
  /** When true, the Jane Pay logo will be replaced with "Powered by Jane" */
  hideJanePayBranding?: boolean;
  /** Function passed used for selecting a bank account */
  onSelect?: SelectBankAccount;
  /** Flag that indicates if the component should display which bank account is selected, and enable the selection of another one */
  selectable?: boolean;
}

/**
 * Display a user's Aeropay bank account details
 */
export const BankAccountDetails = ({
  bankAccount,
  hideJanePayBranding = false,
  onSelect = () => null,
  selectable = false,
  ...marginProps
}: BankAccountDetailsProps) => {
  const isMobile = useTabletMediaQuery({ width: 'max' });
  const showJanePay = useFlag(FLAGS.janePay);
  return (
    <Flex grow alignItems="center" {...marginProps}>
      <BankCircledIcon name={bankAccount.bank_name} />

      <ConditionalWrapper
        condition={showJanePay && bankAccount?.is_selected}
        wrapper={(children) => (
          <Flex
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            width="100%"
          >
            {children}
          </Flex>
        )}
      >
        <Box ml={16}>
          {bankAccount.bank_name && (
            <Typography variant="body-bold">{bankAccount.bank_name}</Typography>
          )}

          {bankAccount.name && (
            <Typography variant="body">{bankAccount.name}</Typography>
          )}

          {selectable && (
            <Flex>
              {bankAccount.is_selected ? (
                <Typography variant="body">Primary account</Typography>
              ) : (
                <Link
                  onClick={() =>
                    onSelect({ bankAccountId: bankAccount.bank_account_id })
                  }
                >
                  Set as primary account
                </Link>
              )}{' '}
            </Flex>
          )}
        </Box>

        {showJanePay && bankAccount?.is_selected && !hideJanePayBranding && (
          <JanePayLogo
            mt={isMobile ? 4 : 0}
            ml={isMobile ? 16 : 'auto'}
            mr={isMobile ? 'auto' : 16}
            height="24px"
          />
        )}

        {showJanePay && bankAccount?.is_selected && hideJanePayBranding && (
          <Flex
            alignItems="center"
            ml={isMobile ? 16 : 'auto'}
            mr={isMobile ? 'auto' : 16}
            mt={isMobile ? -4 : 0}
          >
            <Typography
              variant={isMobile ? 'mini-bold' : 'body'}
              mr={4}
              mt={isMobile ? 8 : 0}
            >
              Powered By
            </Typography>

            <JaneLogo
              removeSafeSpace
              variant="secondary"
              size={isMobile ? 'xs' : 'sm'}
            />
          </Flex>
        )}
      </ConditionalWrapper>
    </Flex>
  );
};
