import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

import type { DeepReadonly } from '@jane/shared/models';
import { isEmbeddedMode } from '@jane/shared/util';

import type { CustomerReducerState } from './redux/customer';
import type { SearchReduxState } from './redux/search';
import type { CustomerState } from './redux/types';
import type { UsersState } from './redux/users';

export const useCustomerSelector: TypedUseSelectorHook<CustomerState> =
  useSelector;

export const isEmbeddedModeSelector = ({
  embeddedApp: { appMode },
}: CustomerState) => isEmbeddedMode(appMode);

export const hasUserSelector = ({ id }: CustomerReducerState) => Boolean(id);

export const inProgressStatuses = [
  'verification',
  'staff_member_review',
  'preparing',
  'delivery_started',
  'delivery_finished',
  'ready_for_pickup',
];

export const reservationsInProgressSelector = (
  state: DeepReadonly<UsersState>
) =>
  state.reservations.filter((reservation) =>
    inProgressStatuses.includes(reservation.status)
  );

export const appModeSelector = (state: CustomerState) =>
  state.embeddedApp.appMode;

const MILES_TO_METERS: Record<SearchReduxState['searchRadius'], number> = {
  '5': 8047,
  '10': 16093,
  '15': 24140,
  '20': 32187,
  '25': 40234,
  '30': 48280,
  '35': 56327,
};
export const METERS_PER_MILE = 1609.34;
export const searchRadiusInMetersSelector = ({
  search,
}: CustomerState | { search: SearchReduxState }) =>
  search.searchRadius
    ? MILES_TO_METERS[search.searchRadius] ||
      Math.ceil(METERS_PER_MILE * Number(search.searchRadius))
    : undefined;

export const headlessDisablePrepaymentSelector = (state: CustomerState) =>
  state.embeddedApp.appMode === 'headless' &&
  state.headlessApp.options?.disablePrepayment;
