import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { fetchCartToppers } from '../../data-access/fetchCartToppers';
import type {
  FetchCartToppersParams,
  FetchCartToppersResponse,
} from '../../data-access/fetchCartToppers';

export interface UseCartToppersParams extends FetchCartToppersParams {
  fetchOnce: boolean;
}

export const useFetchCartToppers = ({
  appMode,
  cartProductIds,
  janeDeviceId,
  fetchOnce,
  maxProducts,
  storeId,
}: UseCartToppersParams) => {
  const newParams = {
    appMode,
    cartProductIds: cartProductIds.sort(),
    janeDeviceId,
    maxProducts,
    storeId,
  };
  const [initialParams] = useState<FetchCartToppersParams>(newParams);
  const params = fetchOnce && initialParams ? initialParams : newParams;
  return useQuery<FetchCartToppersResponse>({
    queryFn: () => fetchCartToppers(params),
    queryKey: ['cart-toppers', params],
    refetchOnWindowFocus: false,
    retry: false,
  });
};
