import noop from 'lodash/noop';
import round from 'lodash/round';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetCoordinates } from '@jane/search/data-access';
import type { JaneSearchState } from '@jane/search/types';
import {
  DEFAULT_COORDINATES,
  isValidCountryParam,
  isValidStateParam,
  paramToCoord,
  paramToPlace,
} from '@jane/search/util';
import { encodeQuery, parseSearch } from '@jane/shared/util';

import { getSearchStateForUrl } from '../searchProvider';

export interface MapLocation {
  city?: string;
  country?: string;
  lat?: number;
  long?: number;
  state?: string;
  zoom?: number;
}

interface DispensariesContext<T> {
  mapLocation?: MapLocation;
  resetMapLocation?: (nextMapLocation?: MapLocation) => void;
  resetSearchState: (nextSearchState?: Partial<JaneSearchState<T>>) => void;
  searchState: JaneSearchState<T>;
  setMapLocation: Dispatch<SetStateAction<MapLocation>>;
  setSearchState: (searchState: Partial<JaneSearchState<T>>) => void;
}

const buildSearchState = <T,>(
  initialSearchParams: JaneSearchState<T> = {}
): JaneSearchState<T> => ({
  filters: {},
  searchText: '',
  ...initialSearchParams,
});

export const DispensariesContext = createContext<DispensariesContext<any>>({
  mapLocation: {
    city: '',
    country: '',
    lat: 0,
    long: 0,
    state: '',
    zoom: 0,
  },
  resetMapLocation: noop,
  resetSearchState: noop,
  searchState: buildSearchState<any>(),
  setMapLocation: noop,
  setSearchState: noop,
});

export const useDispensariesContext = () => useContext(DispensariesContext);

type DispensariesProviderProps = PropsWithChildren;

/**
 * DispensariesProvider manages the search state for the dispensaries page
 * This page supports searching by place, ex /dispensaries/country/state/city,
 * or by coordinates, ex /dispensaries/coordinates/lat/long
 */

export const DispensariesProvider = <T,>({
  children,
}: DispensariesProviderProps) => {
  const pageUrl = useLocation();
  const navigate = useNavigate();

  const stateFromSearchParams = useMemo(
    () => parseSearch(pageUrl.search),
    [pageUrl.search]
  );
  const [searchState, setSearchState] = useState<JaneSearchState<T>>(
    buildSearchState(stateFromSearchParams)
  );
  const [searchLocation, setSearchLocation] = useState<string | null>(null);

  const {
    country,
    state,
    city,
    lat: paramsLat,
    long: paramsLong,
    zoom: paramsZoom,
  } = useParams<{
    city: string;
    country: string;
    lat: string;
    long: string;
    state: string;
    zoom: string;
  }>();

  const isValidCountry = country && isValidCountryParam(country);
  const isValidState = state && isValidStateParam(state);

  const isSearchingByPlace =
    Boolean(city || country || state) && isValidCountry;
  const isSearchingByCoords = Boolean(paramsLat && paramsLong);

  const defaultLat = paramsLat
    ? paramToCoord(paramsLat)
    : isSearchingByPlace
    ? 0
    : DEFAULT_COORDINATES.lat;

  const defaultLong = paramsLong
    ? paramToCoord(paramsLong)
    : isSearchingByPlace
    ? 0
    : DEFAULT_COORDINATES.long;

  const mapZoom =
    isSearchingByCoords || !isSearchingByPlace || Boolean(city)
      ? 10
      : state
      ? 6
      : 1;

  const [mapLocation, setMapLocation] = useState<MapLocation>({
    city,
    country,
    lat: defaultLat,
    long: defaultLong,
    state,
    zoom: mapZoom,
  });

  useEffect(() => {
    if (paramsLat && paramsLong) {
      setSearchLocation(null);
      setMapLocation({
        lat: paramToCoord(paramsLat),
        long: paramToCoord(paramsLong),
        zoom: Number(paramsZoom),
      });
    }
  }, [paramsLat, paramsLong, paramsZoom]);

  useEffect(() => {
    if (!isValidCountry) {
      navigate('/dispensaries');
    }

    if (state && !isValidState) {
      navigate('/dispensaries');
    }

    const locationQuery = `${country || ''},${state || ''},${city || ''}`;
    if (isSearchingByPlace) {
      setSearchLocation(locationQuery);
    }
  }, [city, state, country, isSearchingByPlace, isValidCountry, isValidState]);

  const { data: placeCoordinates } = useGetCoordinates(searchLocation);

  useEffect(() => {
    if (!isSearchingByCoords) {
      if (placeCoordinates) {
        if (placeCoordinates.features.length > 0) {
          const currentLocation = placeCoordinates?.features[0];

          // round coordinates to limit minor lat/long changes
          setMapLocation({
            city: city ? paramToPlace(city) : undefined,
            country: country ? paramToPlace(country) : undefined,
            lat: round(currentLocation.center[1], 5),
            long: round(currentLocation.center[0], 5),
            state: state ? paramToPlace(state) : undefined,
            zoom: mapZoom,
          });
        }
      }
    }
  }, [placeCoordinates, city, country, state, paramsLat, paramsLong]);

  useEffect(() => {
    const searchStateForUrl = getSearchStateForUrl(searchState);
    const encodedSearchState = encodeQuery('', searchStateForUrl);
    if (pageUrl.search !== encodedSearchState) {
      navigate({
        ...pageUrl,
        search: encodedSearchState,
      });
    }
  }, [JSON.stringify(searchState)]);

  const setState = useCallback(
    (nextSearchState: Partial<JaneSearchState<T>>) => {
      setSearchState((prevSearchState) => ({
        ...prevSearchState,
        ...nextSearchState,
      }));
    },
    []
  );

  const resetSearchState = useCallback(
    (nextSearchState: Partial<JaneSearchState<T>> = {}) => {
      setSearchState(() => nextSearchState);
    },
    []
  );

  const resetMapLocation = useCallback(() => {
    setSearchLocation('');
    setMapLocation({
      city: undefined,
      country: undefined,
      lat: DEFAULT_COORDINATES.lat,
      long: DEFAULT_COORDINATES.long,
      state: undefined,
      zoom: 10,
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      mapLocation,
      resetMapLocation,
      resetSearchState,
      searchState,
      setMapLocation,
      setSearchState: setState,
    }),
    [
      resetSearchState,
      searchState,
      setState,
      mapLocation,
      setMapLocation,
      resetMapLocation,
    ]
  );

  return (
    <DispensariesContext.Provider value={contextValue}>
      {children}
    </DispensariesContext.Provider>
  );
};
