import type { ReactNode } from 'react';

import { useGetStore } from '@jane/shared/data-access';
import type { PendingCartProduct } from '@jane/shared/models';
import { Flex, Typography } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { useCustomerSelector } from '../../../../customer';
import type { CartLineItems } from '../../../../lib/getCartLineItems';
import insertIf from '../../../../lib/insertIf';
import { reservationModeLabel } from '../../../../lib/store';
import { SummaryWrapper } from './cartDrawerSummary.styles';

interface CartDrawerSummaryProps {
  cartLineItems: CartLineItems;
}

interface LineItem {
  amount: number;
  id: string;
  label: string;
  text?: string | ReactNode;
}

const MaxSpecialsDisclaimer = ({
  maxSpecialsPerCart,
}: {
  maxSpecialsPerCart: number | null;
}) => {
  if (maxSpecialsPerCart) {
    const pluralizedLabel = maxSpecialsPerCart > 1 ? 'specials' : 'special';
    return (
      <Typography color="grays-mid" as="p" my={8} variant="mini">
        This store supports {maxSpecialsPerCart + ' ' + pluralizedLabel + ' '}
        per order. Don't worry, we've already selected the {
          pluralizedLabel
        }{' '}
        that saves you the most.
      </Typography>
    );
  }
};

export const CartDrawerSummary = ({
  cartLineItems,
}: CartDrawerSummaryProps) => {
  const { cart } = useCustomerSelector(
    ({ cart: { deliveryAddress, cart } }) => ({
      cart,
      deliveryAddress,
    })
  );
  const {
    preDiscountSubtotal,
    deliveryFee,
    discountTotal,
    serviceFee,
    storeTax,
    salesTax,
    tipAmount,
  } = cartLineItems;

  const {
    reservation_mode: reservationMode,
    max_specials_per_cart: maxSpecialsPerCart,
    max_specials_per_cart_hit: maxSpecialsPerCartHit,
  } = cart;

  const { data: store } = useGetStore(cart?.store.id);
  const { delivery, tax_included } = store;

  const totalItems = cart.products.reduce(
    (prev: number, curr: PendingCartProduct) => prev + curr.count,
    0
  );

  const applyDeliveryFee = delivery && reservationMode === 'delivery';

  const lineItems: LineItem[] = [
    {
      id: 'service-fee',
      label: 'Estimated service fee',
      amount: serviceFee || 0,
    },
    ...insertIf(applyDeliveryFee, {
      id: 'delivery-fee',
      label: `Estimated ${reservationModeLabel(store, 'delivery')} fee`,
      amount: deliveryFee || 0,
    }),
    ...insertIf(storeTax > 0, {
      id: 'localTaxes',
      label: 'Estimated local tax',
      amount: storeTax,
    }),
    ...insertIf(!tax_included, {
      id: 'taxes',
      label: 'Estimated sales tax',
      amount: salesTax,
    }),
    ...insertIf(tipAmount > 0, {
      id: 'tipAmount',
      label: 'Delivery tip',
      amount: tipAmount,
    }),
    ...insertIf(discountTotal > 0, {
      id: 'savings',
      label: 'Estimated savings',
      amount: -discountTotal,
    }),
  ];

  return (
    <SummaryWrapper flexDirection="column">
      <Flex justifyContent="space-between">
        <Typography color="grays-dark">Items ({totalItems})</Typography>
        <Typography color="grays-dark">
          {formatCurrency(preDiscountSubtotal)}
        </Typography>
      </Flex>
      {lineItems.map((item) => (
        <Flex mt={8} justifyContent="space-between" key={item.id}>
          <Typography color="grays-dark">{item.label}</Typography>
          <Typography color="grays-dark">
            {item.amount ? formatCurrency(item.amount) : 'Free'}
          </Typography>
        </Flex>
      ))}

      {maxSpecialsPerCartHit && (
        <MaxSpecialsDisclaimer maxSpecialsPerCart={maxSpecialsPerCart} />
      )}
    </SummaryWrapper>
  );
};
