import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';

import { config } from '@jane/shared/config';
import type { Id, Source } from '@jane/shared/models';
import {
  tCustomerReservationDetails,
  tCustomerReservationHistory,
  tGuestCustomerReservationDetails,
} from '@jane/shared/models';

import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

export const reservationHistory = () => ({
  url: `${config.apiPath}/users/reservation_history`,
  requireAuth: true,
  type: t.interface({
    reservations: t.array(tCustomerReservationHistory),
  }),
});

export const storeReservationHistory = (storeId: Id) => ({
  url: `${config.apiPath}/users/store_reservation_history?store_id=${storeId}`,
  requireAuth: true,
  type: t.interface({
    reservations: t.array(tCustomerReservationHistory),
  }),
});

export const reservationDetails = (cartId: Id) => ({
  url: `${config.apiPath}/users/reservations/${cartId}/details`,
  requireAuth: true,
  type: t.interface({
    reservation: tCustomerReservationDetails,
  }),
});

export const guestReservationDetails = (uuid: string) => ({
  url: `${config.apiPath}/users/reservations/${uuid}/guest_details`,
  type: t.interface({
    guestReservation: tGuestCustomerReservationDetails,
  }),
});

const requestSource = <T extends Record<string, unknown>>({
  url,
  type,
  requireAuth,
}: Source<T>) =>
  request(url, { method: 'GET', requireAuth }).then((data) =>
    pipe(
      decodeType({
        data,
        type,
        source: { url },
      }),
      fold(
        (error) => {
          throw error;
        },
        (response) => response
      )
    )
  );

export const getUserReservationHistory = () =>
  requestSource(reservationHistory());

export const getUserStoreReservationHistory = (storeId: Id) =>
  requestSource(storeReservationHistory(storeId));

export const getUserReservationDetails = (cartId: Id) =>
  requestSource(reservationDetails(cartId));

export const getGuestUserReservationDetails = (uuid: string) =>
  requestSource(guestReservationDetails(uuid));
