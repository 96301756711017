import styled from '@emotion/styled';
import { useCallback, useState } from 'react';

import { useCartToppers } from '@jane/dm/internal';
import { FilterSortId } from '@jane/search/types';
import { useGetStore } from '@jane/shared/data-access';
import type { StoreSpecial } from '@jane/shared/models';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Flex,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import {
  appModeSelector,
  useCustomerSelector,
} from '../../../customer/selectors';
import { MENU_PRODUCTS_BY_PRICE_ASC } from '../../../lib/algoliaIndices';
import { get } from '../../../redux-util/selectors';
import { ErrorBoundary } from '../../errorBoundary';
import ItemsCarousel from '../../itemsCarousel';
import { ITEM_MARGIN_AMOUNT } from '../../itemsCarousel/carouselHelper';
import HitProductCard from '../../storeMenu/hitProductCard';

const Container = styled(Flex)(({ theme }) => ({
  ...spacing({ mt: 16, px: 24 }),
  backgroundColor: theme.colors.grays.white,
}));

interface Props {
  specials?: StoreSpecial[];
}
export const CartToppers = ({ specials }: Props) => {
  const appMode = useCustomerSelector(appModeSelector);
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { cart } = useCustomerSelector(get('cart'));
  const storeId = cart?.store.id;
  const { data: store } = useGetStore(storeId);
  const isDesktop = useDesktopMediaQuery({});
  const [carouselIndex, setCarouselIndex] = useState(0);

  const showArrows = isDesktop || appMode === 'headless';
  const [rightArrowDisabled, setRightArrowDisabled] = useState(true);
  const onShowRightArrow = useCallback(
    (showRightArrow: boolean) => setRightArrowDisabled(!showRightArrow),
    []
  );

  const cartProductIds = cart.products.map((product) => product.id);
  const cartToppers = useCartToppers({
    appMode,
    cartProductIds,
    janeDeviceId,
    storeId,
  });

  if (!store || !cartToppers.length) return null;

  return (
    <ErrorBoundary>
      <Container flexDirection="column" pb={20}>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mt={40}
          mb={24}
        >
          <Typography branded variant="header-bold">
            Add to your order
          </Typography>
          {showArrows && (
            <Flex gap={16}>
              <Button.Icon
                variant="tertiary"
                icon={<ArrowLeftIcon />}
                disabled={carouselIndex === 0}
                onClick={() => setCarouselIndex(carouselIndex - 1)}
              />
              <Button.Icon
                variant="tertiary"
                icon={<ArrowRightIcon />}
                disabled={rightArrowDisabled}
                onClick={() => setCarouselIndex(carouselIndex + 1)}
              />
            </Flex>
          )}
        </Flex>
        <ItemsCarousel
          controlledIndex={{
            currentIndex: showArrows ? carouselIndex : null,
            onShowRightArrow,
          }}
          items={cartToppers}
          itemMargin={ITEM_MARGIN_AMOUNT}
          itemRenderer={({ item: cartTopper, index }) => (
            <HitProductCard
              algoliaIndexName={MENU_PRODUCTS_BY_PRICE_ASC}
              appliedWeightFilter={''}
              bucketName="cart-toppers"
              carouselView={true}
              columnPosition={index}
              hit={cartTopper.menuProduct}
              key={`cart-topper-${cartTopper.menuProduct.product_id}`}
              listView={false}
              searchState={{
                currentSort: {
                  id: FilterSortId.PriceAsc,
                  suffix: 'by-price-asc',
                },
              }}
              specials={specials}
              store={store}
              zone="cart"
            />
          )}
        />
      </Container>
    </ErrorBoundary>
  );
};
