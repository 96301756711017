import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useQuery } from '@jane/shared-ecomm/hooks';
import { useGetStore } from '@jane/shared/data-access';
import type { DeliveryValidation, StoreSpecial } from '@jane/shared/models';
import {
  Box,
  Button,
  Flex,
  Link,
  Typography,
  useDrawerContext,
} from '@jane/shared/reefer';
import { formatCurrency, postMessageToIframeParent } from '@jane/shared/util';

import { useCustomerDispatch, useCustomerSelector } from '../../../../customer';
import {
  closeCart,
  fixUnavailableCheckout,
  refreshCart,
} from '../../../../customer/redux/cart';
import { paths } from '../../../../lib/routes';
import { CartSource } from '../../../../sources/cart';
import { DrawerFooter } from './cartDrawerFooter.styles';

interface CartDrawerFooterProps {
  /** @private internal flag to configure headless styles to be initially tested in Bloom */
  bloomUserExperience?: boolean;
  checkoutDisabled: boolean;
  deliveryValidationData: DeliveryValidation;
  handleSubmit: () => void;
  headless?: boolean;
  headlessGuestCheckoutDisabled?: boolean;
  headlessLoginUrl?: string;
  specials: StoreSpecial[];
  total: number;
}

export const CartDrawerFooter = ({
  bloomUserExperience = false,
  checkoutDisabled,
  handleSubmit,
  headless = false,
  headlessGuestCheckoutDisabled = false,
  headlessLoginUrl = '',
  total,
  specials,
}: CartDrawerFooterProps) => {
  const location = useLocation();
  const { onRequestClose } = useDrawerContext();
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  const query = useQuery();
  const { special_id } = useParams();
  const { appMode, cart } = useCustomerSelector(
    ({ cart: { cart }, embeddedApp: { appMode } }) => ({
      appMode,
      cart,
    })
  );
  const { data: store } = useGetStore(cart?.store.id);
  const onSubmit = async () => {
    const { unavailable_product_ids } =
      await CartSource.getUnavailableProductIds(cart.uuid);
    if (checkoutDisabled) return;

    if (isEmpty(unavailable_product_ids)) {
      handleSubmit();
    } else {
      await dispatch(fixUnavailableCheckout());
      dispatch(refreshCart(cart));
    }
  };

  const getContinueShoppingUrl = () => {
    const { fromSpecialId } = query;
    const fromSpecial = find(specials, {
      id: parseInt(special_id) || parseInt(fromSpecialId),
    });

    if (appMode === 'default' || appMode === 'brandEmbed') {
      return fromSpecial
        ? paths.special(fromSpecial, store)
        : paths.store(store);
    }

    return paths.embeddedMenu();
  };

  const handleContinueShopping = () => {
    const path = getContinueShoppingUrl();
    const noNavigationRequired = matchRoutes([{ path }], location);

    if (headless) {
      onRequestClose();
      postMessageToIframeParent({
        messageType: 'analyticsEvent',
        payload: {
          name: 'drawerContinueShopping',
        },
      });
      return;
    }

    // if we're already on the menu/specials page, just request the close (which includes the animation),
    if (noNavigationRequired) {
      onRequestClose();
      return;
    }

    // otherwise immediately close the drawer and navigate.
    dispatch(closeCart());
    navigate(path);
  };

  return (
    <DrawerFooter fixedFooter={bloomUserExperience} noPadding>
      <Box background="grays-white" px={24} pb={24}>
        <Flex flexDirection="column">
          <Flex mt={16} justifyContent="space-between">
            <Typography branded variant="header-bold">
              Estimated Total
            </Typography>
            <Typography
              branded
              variant="header-bold"
              data-testid="estimated-total"
            >
              {formatCurrency(total)}
            </Typography>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            {headlessGuestCheckoutDisabled && headlessLoginUrl ? (
              <Button
                full
                href={headlessLoginUrl}
                target="_parent"
                label="Sign in to checkout"
                my={16}
              ></Button>
            ) : (
              <Button
                onClick={onSubmit}
                label="Proceed to Checkout"
                full
                disabled={checkoutDisabled}
                my={16}
              />
            )}
            <Link typography="body" onClick={handleContinueShopping}>
              {bloomUserExperience
                ? 'Keep shopping this store'
                : 'Continue shopping'}
            </Link>
          </Flex>
        </Flex>
      </Box>
    </DrawerFooter>
  );
};
