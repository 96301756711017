import { useEffect } from 'react';

import type { Id, ReservationMode } from '@jane/shared/models';
import { Box } from '@jane/shared/reefer';

import {
  useCustomerDispatch,
  useCustomerSelector,
} from '../../../../../customer';
import { updateQueryPromoCode } from '../../../../../customer/redux/cart';
import {
  applyPromoCode,
  resetPromoCodeForm,
} from '../../../../../customer/redux/promoCodeForm';
import { get } from '../../../../../redux-util/selectors';
import { SubmittableTextInput } from './SubmittableTextInput';

interface PromoCodesProps {
  headless?: boolean;
  reservationMode: ReservationMode;
  userGroupSpecialId?: Id;
}

export const PromoCodes = ({
  headless,
  reservationMode,
  userGroupSpecialId,
}: PromoCodesProps) => {
  const { validated, isValid } = useCustomerSelector(get('promoCodeForm'));
  const cartState = useCustomerSelector(get('cart'));
  const dispatch = useCustomerDispatch();

  useEffect(() => {
    if (cartState?.queryPromoCode) {
      dispatch(
        applyPromoCode({
          promoCode: cartState.queryPromoCode,
          reservationMode,
          userGroupSpecialId,
        })
      );
      dispatch(updateQueryPromoCode(null));
    }
  }, []);

  return (
    <Box pl={headless ? 0 : 40}>
      <SubmittableTextInput
        name="promo_code"
        placeholder="Enter code here"
        submitLabel="Apply"
        onReset={() => dispatch(resetPromoCodeForm())}
        onSubmit={(value) =>
          dispatch(
            applyPromoCode({
              promoCode: value,
              reservationMode,
              userGroupSpecialId,
            })
          )
        }
        validated={validated}
        hasValidationError={!isValid}
        validationMessage={isValid ? 'Success' : 'Not Valid'}
      />
    </Box>
  );
};
