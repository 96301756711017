import { useIsRecommendedSortEnabled } from '@jane/dm/internal';
import type { CurrentSort } from '@jane/search/types';
import { FilterSortId } from '@jane/search/types';
import type { Store } from '@jane/shared/models';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';

interface Props {
  currentSort: CurrentSort | undefined;
  isTable: boolean;
  store: Store;
}
export const useIsCurrentSortRecommended = ({
  currentSort,
  isTable,
  store,
}: Props): boolean => {
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const isRecommendedSortEnabled = useIsRecommendedSortEnabled(store, appMode);
  if (!isTable) {
    return false;
  }
  if (!isRecommendedSortEnabled) {
    return false;
  }
  if (currentSort?.id === FilterSortId.Recommended) {
    return true;
  }
  const isCurrentSortDefault = !currentSort || currentSort.isDefault;
  return Boolean(isTable && isCurrentSortDefault);
};
