import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getJwt } from '@jane/shared/auth';
import { janeApi } from '@jane/shared/data-access';
import type {
  AeropayBankAccount,
  AeropayWhiteLabelUser,
} from '@jane/shared/models';

import type {
  BankAccountId,
  BankAccountResponse,
  CreateAeropayUser,
  LinkBankAccountProps,
  UserId,
} from './aeropay.types';
import { queryKeys, urls } from './aeropay.util';

const confirmJaneGoldUser = async () =>
  await janeApi.patch(
    urls.aeropayAccounts,
    {
      requires_gold_confirmation: false,
    },
    getJwt()
  );

export const useConfirmJaneGoldUser = ({ janeUserId }: UserId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: confirmJaneGoldUser,
    onSuccess: () => {
      queryClient.setQueryData(
        queryKeys.aeropayUser({ janeUserId }),
        (data) => {
          return {
            ...(data as AeropayWhiteLabelUser),
            requires_gold_confirmation: false,
          };
        }
      );
    },
  });
};

const createAeropayUser = async (aeropayAccountInfo: CreateAeropayUser) =>
  await janeApi.post(urls.aeropayAccounts, aeropayAccountInfo, getJwt());

export const useCreateAeropayUser = ({ janeUserId }: UserId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: CreateAeropayUser) => createAeropayUser(variables),
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.aeropayUser({ janeUserId }), () => {
        return {
          ...(data as AeropayWhiteLabelUser),
        };
      });
    },
  });
};

const linkAeropayBankAccount = async ({
  aeropayUserId,
  aeropayUserPassword,
}: LinkBankAccountProps) =>
  await janeApi.post<BankAccountResponse>(
    urls.bankConnection,
    {
      user_id: aeropayUserId,
      user_password: aeropayUserPassword,
    },
    getJwt()
  );

export const useLinkAeropayBankAccount = ({ janeUserId }: UserId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: LinkBankAccountProps) =>
      linkAeropayBankAccount(variables),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.aeropayUser({ janeUserId }),
      }),
  });
};

const selectAeropayBankAccount = async ({ bankAccountId }: BankAccountId) =>
  await janeApi.post<BankAccountResponse>(
    urls.selectBankAccount,
    {
      bank_account_id: bankAccountId,
    },
    getJwt()
  );

export const useSelectAeropayBankAccount = ({ janeUserId }: UserId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: BankAccountId) =>
      selectAeropayBankAccount(variables),
    onSuccess: (_, variables: BankAccountId) => {
      queryClient.setQueryData(
        queryKeys.aeropayUser({ janeUserId }),
        (data: unknown) => {
          return {
            ...(data as AeropayWhiteLabelUser),
            bank_accounts: (data as AeropayWhiteLabelUser).bank_accounts.map(
              (bankAccount: AeropayBankAccount) => ({
                ...bankAccount,
                is_selected:
                  bankAccount.bank_account_id === variables.bankAccountId,
              })
            ),
          };
        }
      );
    },
  });
};

const deleteAeropayBankAccount = async ({ bankAccountId }: BankAccountId) =>
  await janeApi.delete<BankAccountResponse>(
    `${urls.deleteBankAccount}?bank_account_id=${bankAccountId}`,
    getJwt()
  );

export const useDeleteAeropayBankAccount = ({ janeUserId }: UserId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: BankAccountId) =>
      deleteAeropayBankAccount(variables),
    onSuccess: (_, variables: BankAccountId) => {
      queryClient.setQueryData(
        queryKeys.aeropayUser({ janeUserId }),
        (data: unknown) => {
          return {
            ...(data as AeropayWhiteLabelUser),
            bank_accounts: (data as AeropayWhiteLabelUser).bank_accounts.filter(
              (bankAccount: AeropayBankAccount) =>
                bankAccount.bank_account_id !== variables.bankAccountId
            ),
          };
        }
      );
    },
  });
};
