import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import pluralise from 'pluralise';

import { generateCustomMenuRowTitle } from '@jane/dm/internal';
import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import { EventNames } from '@jane/shared-ecomm/tracking';
import type { AdData, Store } from '@jane/shared/models';
import {
  Box,
  Flex,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { generateCustomLabel, titleCase } from '@jane/shared/util';

import { SvgIcon } from '../../component-library/svg-icon';
import { isNoStore } from '../../customer/redux/store';
import { appModeSelector, useCustomerSelector } from '../../customer/selectors';
import { usePDPDetector } from '../../hooks/usePDPDetector';
import { SponsoredLabel } from './ad';
import { SeeAllLinkWrapper } from './productKindBucketCarousel';
import { Count } from './productKindBucketTable';

const CountAndSeeAll = styled(Flex)<{ isAd?: boolean }>({
  position: 'relative',
});

const CollapseButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface Props {
  count: number;
  expanded?: boolean;
  flightProps?: AdData['flight'];
  isAd?: boolean;
  name: string;
  onCollapse?: () => void;
  placementIndex: number;
  seeAllLinkSearchState: JaneSearchState<AlgoliaProduct>;
  showSeeAll?: boolean;
  store: Store;
}

const BucketHeader = ({
  count,
  seeAllLinkSearchState,
  store,
  expanded,
  onCollapse,
  name,
  showSeeAll,
  placementIndex,
  isAd,
  flightProps,
}: Props) => {
  const isDesktop = useDesktopMediaQuery({});
  const appMode = useCustomerSelector(appModeSelector);
  const isPDP = usePDPDetector();
  const theme = useTheme();

  if (isPDP) {
    showSeeAll = false;
  }

  const getLabel = () => {
    const customRowDisplayName = store.custom_product_type_labels[name];

    // NOTE(elliot): If jane defined rows' custom labels are the default ie. best_selling or sale, then it should get title cased.
    const customRow =
      !JANE_DEFINED_ROWS.includes(customRowDisplayName) && customRowDisplayName;

    const fallback = () => {
      if (isAd) {
        return generateCustomMenuRowTitle({
          flight: flightProps,
          storeState: store.state,
        });
      } else {
        return customRow || titleCase(name);
      }
    };

    return generateCustomLabel({
      appMode,
      attribute: name,
      fallback: fallback(),
      store,
    });
  };

  const storeId = store && !isNoStore(store) ? store.id.toString() : undefined;

  return (
    <Flex
      pb={32}
      px={4}
      flexDirection={isAd ? 'column' : 'row'}
      justifyContent="space-between"
    >
      {isAd && (
        <Flex justifyContent="space-between">
          <SponsoredLabel isDesktop={isDesktop} textColor="grays-mid">
            Sponsored
          </SponsoredLabel>

          {!isPDP && (
            <SeeAllLinkWrapper
              searchState={seeAllLinkSearchState}
              store={store}
              flightProps={flightProps}
              trackingInfo={{
                category: name,
                creativeIds: flightProps?.creative_ids,
                event: EventNames.ClickedSeeAll,
                flightId: flightProps?.id,
                linkLocation: 'see all link',
                numberOfItems: count,
                placementIndex,
                productBrandId: flightProps?.product_brand.id,
                storeId,
              }}
            >
              {`See All ${name}`}
            </SeeAllLinkWrapper>
          )}
        </Flex>
      )}

      {isPDP && isAd ? (
        <Typography as="h2" color="grays-black" branded variant="header-bold">
          You may also like
        </Typography>
      ) : (
        <Typography color="grays-black" as="h2" branded variant="header-bold">
          <SeeAllLinkWrapper
            searchState={seeAllLinkSearchState}
            store={store}
            ariaLabel={isAd ? 'sponsored content' : 'see all'}
            flightProps={flightProps}
            trackingInfo={{
              category: name,
              creativeIds: flightProps?.creative_ids,
              event: EventNames.ClickedSeeAll,
              flightId: flightProps?.id,
              linkLocation: 'bucket title',
              numberOfItems: count,
              placementIndex,
              productBrandId: flightProps?.product_brand.id,
              storeId,
            }}
            variant="minimal"
          >
            {getLabel()}
          </SeeAllLinkWrapper>
        </Typography>
      )}

      {!isAd && (
        <CountAndSeeAll
          alignItems="center"
          isAd={isAd}
          shrink={0}
          pt={isAd ? 16 : null}
        >
          <Count>{`${count} ${pluralise.withCount(count, 'Item')}`}</Count>

          {(expanded || showSeeAll) && (
            <Box mx={8}>
              <Count>|</Count>
            </Box>
          )}

          {expanded ? (
            <CollapseButton onClick={onCollapse}>
              <SvgIcon
                icon="times"
                color={theme.colors.primary.main}
                size={18}
              />
            </CollapseButton>
          ) : showSeeAll ? (
            <SeeAllLinkWrapper
              searchState={seeAllLinkSearchState}
              store={store}
              flightProps={flightProps}
              trackingInfo={{
                category: name,
                creativeIds: flightProps?.creative_ids,
                event: EventNames.ClickedSeeAll,
                flightId: flightProps?.id,
                linkLocation: 'see all link',
                numberOfItems: count,
                placementIndex,
                productBrandId: flightProps?.product_brand.id,
                storeId,
              }}
            >
              See All
            </SeeAllLinkWrapper>
          ) : null}
        </CountAndSeeAll>
      )}
    </Flex>
  );
};

export default BucketHeader;
