import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { request } from '../lib/request';

interface User {
  app_mode_at_registration?: string;
  current_password?: string;
  password?: string;
  password_confirmation?: string;
  store_id_at_registration?: Id;
}

type UserProps = {
  user: User;
};

export class UsersSource {
  static urlRoot = `${config.apiPath}/users`;

  static update(user: UserProps) {
    return request(this.urlRoot, {
      body: JSON.stringify(user),
      method: 'PUT',
    });
  }

  static cancelReservation(id: Id) {
    return request(`${this.urlRoot}/reservations/${id}`, {
      method: 'DELETE',
    });
  }

  static createReview(
    reservationId: Id,
    review: { body: string | undefined; rating: number }
  ) {
    return request(`${this.urlRoot}/reservations/${reservationId}/reviews`, {
      body: JSON.stringify(review),
      method: 'POST',
    });
  }

  static prepayReservation({
    token,
    reservation,
  }: {
    reservation: { id: Id };
    token: string;
  }) {
    return request(`${this.urlRoot}/reservations/${reservation.id}/prepay`, {
      body: JSON.stringify({ token }),
      method: 'POST',
    });
  }
}
