import type { ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';

import type { AppMode } from '@jane/shared/types';

interface EcommAppContextProps {
  appMode: AppMode;
}

export const EcommAppContext = React.createContext<EcommAppContextProps>({
  appMode: 'default',
});

export const EcommAppProvider = ({
  appMode,
  children,
}: EcommAppContextProps & { children: ReactNode }) => {
  const value = useMemo(() => {
    return { appMode };
  }, [appMode]);

  return (
    <EcommAppContext.Provider value={value}>
      {children}
    </EcommAppContext.Provider>
  );
};

export const useEcommApp = () => useContext(EcommAppContext);
