/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const BagFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11706_30087)">
      <path
        d="M23.3787 21.5149C23.6943 22.7772 22.7396 24 21.4384 24H2.56155C1.26041 24 0.305694 22.7772 0.621268 21.5149L1.94029 16.2389C1.97995 16.0802 2 15.9173 2 15.7538V7C2 5.89543 2.89543 5 4 5H19.7949C20.8818 5 21.7697 5.86798 21.7944 6.95456L21.9949 15.7769C21.9983 15.9252 22.0182 16.0726 22.0541 16.2165L23.3787 21.5149Z"
        fill="#5E1FF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C10.3423 2 9 3.34228 9 5H7C7 2.23772 9.23772 0 12 0C14.7623 0 17 2.23772 17 5H15C15 3.34228 13.6577 2 12 2Z"
        fill="#5E1FF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_11706_30087">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: BagFilled24,
  sm: BagFilled24,
  lg: BagFilled24,
  xl: BagFilled24,
  xxl: BagFilled24,
  xxxl: BagFilled24,
};

export const BagFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bag-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
