import { api, generateTrackingUrls, generateUrl } from '../api';
import type { AdProduct } from '../types/adProduct';
import type { JaneDMIdentifiers } from '../types/config';
import type { Brand } from '../types/flight';
import { zoneStoreMenu } from '../types/zones';
import { typedJSONParse } from '../utils/typedJsonParse';
import type {
  CreateTopOfMenuRowProps,
  FetchTopOfMenuRowApiResponse,
  TopOfMenuRowClickPayload,
  TopOfMenuRowProductClickPayload,
  TopOfMenuRowProductProps,
  TopOfMenuRowProps,
  TopOfMenuRowRenderPayload,
} from './topOfMenuRow.types';

export class TopOfMenuRowProduct {
  protected clickPayload: TopOfMenuRowProductClickPayload;
  protected clickEndpoint: string;
  public productId: number;
  public attributes: Record<string, any>;

  constructor(props: TopOfMenuRowProductProps) {
    this.productId = props.productId;
    this.clickEndpoint = props.clickEndpoint;
    this.clickPayload = props.clickPayload;
    this.attributes = props.attributes;
  }

  public setAttributes(attributes: Record<string, any>) {
    this.attributes = attributes;
  }

  /**
   * Product Click Handler
   */
  public click(): Promise<void> {
    return api.post(this.clickEndpoint, this.clickPayload);
  }

  public serialize(): TopOfMenuRowProductProps {
    return {
      attributes: this.attributes,
      clickEndpoint: this.clickEndpoint,
      clickPayload: this.clickPayload,
      productId: this.productId,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): TopOfMenuRowProduct {
    return new TopOfMenuRowProduct(
      typedJSONParse<TopOfMenuRowProductProps>(serialized)
    );
  }
}

export class TopOfMenuRow {
  public title: string | undefined;
  public products: TopOfMenuRowProduct[];
  public creativeIds: number[];

  protected filterBrands: Brand[] | null | undefined;
  protected flightBrand: Brand | undefined;

  private clickEndpoint: string;
  private clickPayload: TopOfMenuRowClickPayload;

  private renderEndpoint: string;
  private renderPayload: TopOfMenuRowRenderPayload;
  private hasBeenRendered: boolean;

  constructor(props: TopOfMenuRowProps) {
    this.products = props.products.map(
      (product) => new TopOfMenuRowProduct(product)
    );
    this.clickPayload = props.clickPayload;
    this.renderPayload = props.renderPayload;
    this.title = props.title;
    this.clickEndpoint = props.clickEndpoint;
    this.renderEndpoint = props.renderEndpoint;
    this.creativeIds = props.creativeIds;
    this.filterBrands = props.filterBrands;
    this.flightBrand = props.flightBrand;
    this.hasBeenRendered = props.hasBeenRendered ?? false;
  }

  public serialize(): TopOfMenuRowProps {
    return {
      clickEndpoint: this.clickEndpoint,
      clickPayload: this.clickPayload,
      creativeIds: this.creativeIds,
      filterBrands: this.filterBrands,
      flightBrand: this.flightBrand,
      hasBeenRendered: this.hasBeenRendered,
      products: this.products.map((product) => product.serialize()),
      renderEndpoint: this.renderEndpoint,
      renderPayload: this.renderPayload,
      title: this.title,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): TopOfMenuRow {
    return new TopOfMenuRow(typedJSONParse<TopOfMenuRowProps>(serialized));
  }

  public click(): Promise<void> {
    return api.post(this.clickEndpoint, this.clickPayload);
  }

  public render(): Promise<void> {
    if (this.hasBeenRendered) {
      return Promise.resolve();
    }

    return api.post(this.renderEndpoint, this.renderPayload).then(() => {
      this.hasBeenRendered = true;
    });
  }

  /**
   * When the requested instance does not return products, this method
   * is used to determine if the TopOfMenuRow should still be rendered.
   * This method is for internal use only.
   * @private
   */
  public isRowInFilterBrands(previousRow: TopOfMenuRow | undefined): boolean {
    if (!previousRow?.filterBrands) return true;

    return previousRow.filterBrands.some(
      (filterBrand) => filterBrand.id === this.flightBrand?.id
    );
  }
}

export class TopOfMenuRowClient {
  protected generateProductClickPayload(
    product: AdProduct,
    kevelToken: string | undefined,
    identifier: JaneDMIdentifiers
  ): TopOfMenuRowProductClickPayload {
    return {
      adToken: product.ad_token,
      clickKind: 'product_click',
      janeDeviceId: identifier.jdid,
      kevelToken: kevelToken,
      myHighD: product.my_high_d,
    };
  }

  private generateClickPayload(
    kevelToken: string,
    identifier: JaneDMIdentifiers
  ): TopOfMenuRowClickPayload {
    return {
      clickKind: 'see_all',
      janeDeviceId: identifier.jdid,
      kevelToken,
    };
  }

  protected generateRenderPayload(
    adProducts: AdProduct[],
    kevelToken: string,
    identifier: JaneDMIdentifiers
  ): TopOfMenuRowRenderPayload {
    const positionedProductIds =
      adProducts?.map((product) => product.product_id) ?? [];
    const adTokens =
      adProducts
        ?.map((product) => product.ad_token)
        .filter((productId): productId is string => Boolean(productId)) ?? [];
    const myHighDs =
      adProducts
        ?.map((product) => product.my_high_d)
        .filter((myHighD): myHighD is string => Boolean(myHighD)) ?? [];

    return {
      adTokens,
      distinctId: identifier.mixpanelDistinctId ?? identifier.jdid,
      janeDeviceId: identifier.jdid,
      kevelToken,
      myHighDs,
      positionedProductIds,
      userId: identifier.userId,
    };
  }

  private generateUrl(options: CreateTopOfMenuRowProps): string {
    const url = `/v1/stores/${options.storeId}/sponsored`;

    const searchParams = new URLSearchParams({
      app_mode: options.appMode,
      jane_device_id: options.identifier.jdid,
      mp_distinct_id:
        options.identifier.mixpanelDistinctId ?? options.identifier.jdid,
      zone: zoneStoreMenu,
    });

    if (options.currentCreativeIds && options.currentCreativeIds.length > 0) {
      searchParams.set(
        'current_creative_ids',
        options.currentCreativeIds.join(',')
      );
    }

    if (options.filters) {
      const filterRootTypes = options.filters.rootTypes?.join(',');

      if (filterRootTypes) {
        searchParams.set('current_root_types', filterRootTypes);
      }

      const filterLineages = options.filters.categories?.join(',');

      if (filterLineages) {
        searchParams.set('current_lineages', filterLineages);
      }
    }

    return generateUrl(options.endpoint, `${url}?${searchParams.toString()}`, {
      apiKey: options.apiKey,
      source: options.source,
      version: options.version,
    });
  }

  public async createTopOfMenuRow(
    props: CreateTopOfMenuRowProps
  ): Promise<TopOfMenuRow> {
    const url = this.generateUrl(props);

    const response = (await api.get(url)) as FetchTopOfMenuRowApiResponse;

    const { clickEndpoint, impressionEndpoint } = generateTrackingUrls(
      props.endpoint,
      { apiKey: props.apiKey, source: props.source, version: props.version }
    );

    const products: TopOfMenuRowProductProps[] =
      response.products?.map((product) => ({
        attributes: {},
        clickEndpoint,
        clickPayload: this.generateProductClickPayload(
          product,
          response.flight?.kevel_token,
          props.identifier
        ),
        productId: product.product_id,
      })) ?? [];

    const clickPayload = this.generateClickPayload(
      response.flight?.kevel_token ?? '',
      props.identifier
    );

    const renderPayload = this.generateRenderPayload(
      response.products ?? [],
      response.flight?.kevel_token ?? '',
      props.identifier
    );

    const title =
      response.flight?.custom_title ?? response.flight?.product_brand.name;

    return new TopOfMenuRow({
      clickEndpoint,
      clickPayload,
      creativeIds: response?.flight?.creative_ids ?? [],
      filterBrands: response?.filter_brands,
      flightBrand: response?.flight?.product_brand,
      products,
      renderEndpoint: impressionEndpoint,
      renderPayload,
      title,
    });
  }
}
