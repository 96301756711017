import { Fragment } from 'react';

import { Accordion } from '@jane/shared/components';
import { Flex, Typography } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { useSource } from '../../../../hooks/useSource';
import { getCurrentStoreSpecials } from '../../../../sources/storeSpecials';
import { SpecialsTag } from '../cartDrawerItems/specialsTag';

export const CartDrawerDiscountTerms = ({
  appliedSpecials,
  freeDeliveryMinimum,
  storeId,
  storeName,
  specialLabel,
}: {
  appliedSpecials?: number[];
  freeDeliveryMinimum?: number;
  specialLabel?: string | null;
  storeId: string | number;
  storeName?: string;
}) => {
  const { data: specialsData } = useSource(getCurrentStoreSpecials(storeId));
  const specials = specialsData?.specials || [];

  const specialsTerms = appliedSpecials
    .map((specialId) => specials.find((special) => special.id === specialId))
    .filter((special) => !!special);

  const hasSpecialsLabel = specialLabel && specialLabel?.trim().length > 0;

  return (
    <Flex mb={8} flexDirection="column">
      <Accordion>
        <Accordion.Item id="1">
          <Accordion.Heading>
            <Typography mt={8}>
              Some <span css={{ textDecoration: 'underline' }}>conditions</span>{' '}
              may apply to{' '}
              {hasSpecialsLabel ? specialLabel : 'specials and discounts'}
              <Flex ml={4} inline alignItems="center" as="span">
                <Accordion.Indicator size={10} />
              </Flex>
            </Typography>
          </Accordion.Heading>
          <Accordion.Content destroyOnClose>
            <Typography mt={8}>
              {hasSpecialsLabel ? specialLabel : 'Promotional discounts'} are
              only available while supplies last and are subject to any
              restrictions associated with the{' '}
              {hasSpecialsLabel ? specialLabel : 'promotion'}. Orders must be
              submitted and picked up/delivered within the timeframe that the
              promotion is active. Any{' '}
              {hasSpecialsLabel ? specialLabel : 'discounts'} will be applied by
              the dispensary {storeName && `(${storeName})`} at the time of
              payment, and the dispensary retains ultimate authority for
              verifying and applying the{' '}
              {hasSpecialsLabel ? specialLabel : 'discount'}.
            </Typography>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      {specialsTerms.length > 0 && (
        <Flex flexDirection="column" mt={8}>
          <Typography variant="body-bold">
            {hasSpecialsLabel ? specialLabel : 'Promotional discounts'}
          </Typography>
          {specialsTerms.map(({ title, terms }, i) => {
            return (
              <Fragment key={`title-${i}`}>
                {title && <SpecialsTag title={title} />}
                {terms && <Typography variant="mini">{terms}</Typography>}
              </Fragment>
            );
          })}
        </Flex>
      )}

      {!!freeDeliveryMinimum && (
        <Flex flexDirection="column" mt={8}>
          <SpecialsTag
            title={`Free delivery if you spend ${formatCurrency(
              freeDeliveryMinimum
            )}`}
          />
        </Flex>
      )}
    </Flex>
  );
};
