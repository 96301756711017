import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

interface RegistrationValues {
  email: string;
  nickname?: string;
  password: string;
  phone: string;
}

interface JaneAuthenticationErrors {
  login?: string;
  register?: string[];
  resetPassword?: string;
}

interface Authentication {
  appleLoaded: boolean;
  defaultLogin: (credentials: { email: string; password: string }) => void;
  errors: JaneAuthenticationErrors | null;
  googleLoaded: boolean;
  logOut: () => void;
  onAppleClick: () => void;
  onGoogleClick: () => void;
  register: (newUser: RegistrationValues) => void;
  resetError: () => void;
  resetPassword: (user: { email: string }) => Promise<boolean>;
  showAppleLogin: boolean;
  showGoogleLogin: boolean;
}

const AuthenticationContext = createContext<Authentication>(
  {} as Authentication
);

interface Props {
  children: ReactNode;
  value: Authentication;
}

export const AuthenticationProvider = ({ children, value }: Props) => {
  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within an AuthenticationProvider'
    );
  }

  return context;
};
