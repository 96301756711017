import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { JaneAuthenticationErrors } from '@jane/shared-ecomm/components';
import { trackCustomerRegistration } from '@jane/shared-ecomm/tracking';
import { setJwt } from '@jane/shared/auth';
import { config } from '@jane/shared/config';
import { userQueryKeys } from '@jane/shared/data-access';
import type { User } from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import { trackError } from '@jane/shared/util';

import {
  openModal,
  showSuccessNotification,
} from '../../common/redux/application';
import { setLoginValue } from '../../common/redux/login';
import { useAppleSDK } from '../../components/login/components/appleLogin/useAppleSDK';
import type {
  AppleAuthError,
  AppleAuthResponse,
} from '../../components/login/components/appleLogin/useAppleSDK';
import { useCustomerDispatch, useCustomerSelector } from '../../customer';
import {
  authenticateAppleCode,
  authenticateGoogleCode,
  logInError,
  logInSuccess,
  registerSuccess,
} from '../../customer/redux/customer';
import { validationsToErrors } from '../../lib/form';
import { get } from '../../redux-util/selectors';
import { CustomerSource } from '../../sources/customer';
import type { RegisterSuccessResponse } from '../../sources/registration';
import { RegistrationSource } from '../../sources/registration';
import { ResetPasswordRequestSource } from '../../sources/resetPasswordRequest';

interface RegistrationValues {
  email: string;
  nickname?: string;
  password: string;
  phone: string;
}

interface UseAuthProps {
  isModal?: boolean;
  onLoginSuccess?: () => void;
}

export const useAuth = ({
  onLoginSuccess,
  isModal = true,
}: UseAuthProps = {}) => {
  const { state } = useLocation() as { state: { nextPathname?: string } };
  const navigate = useNavigate();
  const runtimeConfig = useRuntimeConfig();
  const queryClient = useQueryClient();
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [errors, setErrors] = useState<JaneAuthenticationErrors | null>(null);

  const nextPathname = isModal ? null : state?.nextPathname || '/';

  const {
    isLoaded: appleLoaded,
    initAuth: loadAppleScript,
    signIn,
  } = useAppleSDK();

  const { appMode, partnerId, partnerName } = useCustomerSelector(
    get('embeddedApp')
  );

  // google SSO may be implemented for frameless embed
  // apple has not bee implemented yet
  const showGoogleLogin =
    !runtimeConfig.isFramelessEmbedMode ||
    !!runtimeConfig.ssoCredentials['google_oauth2'];
  const showAppleLogin = !runtimeConfig.isFramelessEmbedMode;

  useEffect(() => {
    if (!googleLoaded) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      document.body.appendChild(script);
      setGoogleLoaded(true);
    }

    if (!appleLoaded) {
      loadAppleScript();
    }
  }, [googleLoaded, appleLoaded]);

  const dispatch = useCustomerDispatch();

  const handleResetError = () => {
    setErrors(null);
  };

  const handleLoginSuccess = (user: User) => {
    queryClient.setQueryData(userQueryKeys.janeUser(), { user });
    onLoginSuccess && onLoginSuccess();
  };

  const handleDefaultLogin = async (credentials: {
    email: string;
    password: string;
  }) => {
    handleResetError();
    return CustomerSource.logIn(credentials).then((response) => {
      const { body, headers } = response;
      const { user, errors } = body;

      dispatch(setLoginValue({ name: 'email', value: credentials.email }));
      if (errors) {
        setErrors({ login: errors.error });

        return dispatch(logInError(errors.error));
      }

      dispatch(
        logInSuccess({
          user,
          headers,
          nextPathname,
          navigate: isModal ? false : navigate,
        })
      );
      handleLoginSuccess(user);
    });
  };

  const handleLogOut = () => {
    return dispatch(openModal({ name: 'logout' }));
  };

  const handleRegisterSuccess = useCallback(
    (response: RegisterSuccessResponse) => {
      const { headers, body } = response;

      setJwt(headers['Authorization']);
      trackCustomerRegistration({
        customer: body.user,
        partnerChannel: appMode,
        partnerId,
        partnerName,
      });
      dispatch(registerSuccess(body.user));
      handleLoginSuccess(body.user);
    },
    [appMode, dispatch, partnerId, partnerName]
  );

  const handleRegister = async (newUser: RegistrationValues) => {
    handleResetError();

    return RegistrationSource.register({
      app_mode_at_registration: 'default',
      ...newUser,
    }).then((response) => {
      const { body } = response;

      if ('errors' in body) {
        dispatch(setLoginValue({ name: 'email', value: newUser.email }));

        setErrors({
          register: Object.values(validationsToErrors(body.errors.validations)),
        });
      }

      return handleRegisterSuccess(response as RegisterSuccessResponse);
    });
  };

  const handleGoogleLogin = (code: unknown) => {
    dispatch(
      authenticateGoogleCode({
        code,
        onAuthSuccess: handleLoginSuccess,
        nextPathname,
        navigate: isModal ? false : navigate,
      })
    );
  };

  const handleGoogleClick = () => {
    const google = (window as any).google as any;
    const ssoCredentials = runtimeConfig.ssoCredentials;

    const googleClientId = ssoCredentials['google_oauth2'];
    const clientId = googleClientId || config.googleClientId;
    const embedConfigId = runtimeConfig.embedConfigId;

    const requestConfig: any = {
      client_id: clientId,
      scope: 'profile email',
      callback: (response) => {
        try {
          handleGoogleLogin(response);
        } catch (error) {
          setErrors({ login: 'Google login failed' });
          trackError(new Error('Google login failed'), {
            googleError: error,
          });
        }
      },
    };
    if (embedConfigId) {
      requestConfig.state = `embedConfigId+${embedConfigId}`;
    }

    google.accounts.oauth2.initCodeClient(requestConfig).requestCode();
  };

  const handleAppleLogin = (authMetadata: AppleAuthResponse) => {
    dispatch(
      authenticateAppleCode({
        authMetadata,
        onAuthSuccess: handleLoginSuccess,
        nextPathname,
        navigate: isModal ? false : navigate,
      })
    );
  };

  const handleAppleClick = async () => {
    const data = await signIn();

    if ((data as AppleAuthResponse).authorization) {
      handleAppleLogin(data as AppleAuthResponse);
    } else if ((data as AppleAuthError).shouldLogError) {
      setErrors({ login: 'Apple login failed' });
      trackError(new Error('Apple login failed'), {
        appleError: (data as AppleAuthError).error,
      });
    }
  };

  const handleResetPassword = async ({ email }: { email: string }) => {
    handleResetError();
    return ResetPasswordRequestSource.create('customer', email).then(
      (response) => {
        const { success, errors } = response;

        if (errors && errors.error) {
          setErrors({ resetPassword: errors.error });
          return false;
        } else {
          dispatch(showSuccessNotification(success && success.message));
          return true;
        }
      }
    );
  };

  return {
    appleLoaded,
    defaultLogin: handleDefaultLogin,
    errors,
    googleLoaded,
    logOut: handleLogOut,
    onAppleClick: handleAppleClick,
    onGoogleClick: handleGoogleClick,
    register: handleRegister,
    resetError: handleResetError,
    resetPassword: handleResetPassword,
    showAppleLogin,
    showGoogleLogin,
  };
};
