/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bank48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1955 6.60982C24.4329 6.2785 23.5671 6.2785 22.8045 6.60982L5 14.3456V17.0001H8.98647L9 17H14H19H24H29H34H39L39.0135 17.0001H43V14.3456L25.1955 6.60982ZM4 19.0001H8V37H5C4.44772 37 4 37.4477 4 38V43C4 43.5523 4.44772 44 5 44H43C43.5523 44 44 43.5523 44 43V38C44 37.4477 43.5523 37 43 37H40V19.0001H44C44.5523 19.0001 45 18.5524 45 18.0001V13.6897C45 13.2915 44.7637 12.9313 44.3985 12.7726L25.9925 4.77548C24.7215 4.22328 23.2785 4.22328 22.0075 4.77547L3.60151 12.7726C3.23627 12.9313 3 13.2915 3 13.6897V18.0001C3 18.5524 3.44772 19.0001 4 19.0001ZM10 37V19.0001H13L13 37H10ZM15 37L15 19.0001H18L18 37H15ZM20 37L20 19.0001H23L23 37H20ZM25 37L25 19.0001H28L28 37H25ZM30 37L30 19.0001H33L33 37H30ZM35 37L35 19.0001H38V37H35ZM39 39H34H29H24H19H14H9H6V42H42V39H39ZM24 13C25.1046 13 26 12.1046 26 11C26 9.89543 25.1046 9 24 9C22.8954 9 22 9.89543 22 11C22 12.1046 22.8954 13 24 13Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  xl: Bank48,
  lg: Bank48,
  md: Bank48,
  sm: Bank48,
  xxl: Bank48,
  xxxl: Bank48,
};

export const BankIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bank-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
