import { api, generateTrackingUrls, generateUrl } from '../api';
import type { Params } from '../api';
import type { JaneDMIdentifiers } from '../types/config';
import {
  placementCartToppers,
  placementMenuInlineTable,
  placementRecommendedRow,
} from '../types/placements';
import { SdkError } from '../utils/sdkError';
import { typedJSONParse } from '../utils/typedJsonParse';
import type {
  CreateSmartSortProps,
  FetchSmartSortApiResponse,
  FetchSmartSortBody,
  FetchSmartSortNextPageApiResponse,
  FetchSmartSortNextPageBody,
  FetchSmartSortProduct,
  GeneratedProducts,
  SmartSortCachedProductProps,
  SmartSortClickPayload,
  SmartSortImpressPayload,
  SmartSortNextPageProps,
  SmartSortProductProps,
  SmartSortProps,
} from './smartSort.types';

export class SmartSortProduct {
  public objectId: string;
  public attributes: Record<string, any>;
  public isSponsored: boolean;

  private impressUrl: string;
  private impressPayload: SmartSortImpressPayload;
  private clickUrl: string;
  private clickPayload: SmartSortClickPayload;
  private hasBeenImpressed: boolean;

  constructor(props: SmartSortProductProps) {
    this.objectId = props.objectId;
    this.attributes = props.attributes;
    this.impressUrl = props.impressUrl;
    this.impressPayload = props.impressPayload;
    this.clickPayload = props.clickPayload;
    this.clickUrl = props.clickUrl;
    this.isSponsored = props.isSponsored;
    this.hasBeenImpressed = props.hasBeenImpressed ?? false;
  }

  public impress(): Promise<void> {
    if (this.hasBeenImpressed) {
      return Promise.resolve();
    }

    return api.post(this.impressUrl, this.impressPayload).then(() => {
      this.hasBeenImpressed = true;
    });
  }

  public click(): Promise<void> {
    return api.post(this.clickUrl, this.clickPayload);
  }

  public serialize(): SmartSortProductProps {
    return {
      attributes: this.attributes,
      clickPayload: this.clickPayload,
      clickUrl: this.clickUrl,
      hasBeenImpressed: this.hasBeenImpressed,
      impressPayload: this.impressPayload,
      impressUrl: this.impressUrl,
      isSponsored: this.isSponsored,
      objectId: this.objectId,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): SmartSortProduct {
    return new SmartSortProduct(
      typedJSONParse<SmartSortProductProps>(serialized)
    );
  }
}

export class SmartSortCachedProduct {
  public objectId: string;
  public isSponsored: boolean;

  private impressUrl: string;
  private impressPayload: SmartSortImpressPayload;
  private clickUrl: string;
  private clickPayload: SmartSortClickPayload;

  constructor(props: SmartSortCachedProductProps) {
    this.objectId = props.objectId;
    this.impressUrl = props.impressUrl;
    this.impressPayload = props.impressPayload;
    this.clickPayload = props.clickPayload;
    this.clickUrl = props.clickUrl;
    this.isSponsored = props.isSponsored;
  }

  public serialize(): SmartSortCachedProductProps {
    return {
      clickPayload: this.clickPayload,
      clickUrl: this.clickUrl,
      impressPayload: this.impressPayload,
      impressUrl: this.impressUrl,
      isSponsored: this.isSponsored,
      objectId: this.objectId,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): SmartSortCachedProduct {
    return new SmartSortCachedProduct(
      typedJSONParse<SmartSortCachedProductProps>(serialized)
    );
  }
}

export class SmartSort {
  public products: SmartSortProduct[];
  public searchFacets?: Record<string, Record<string, number>>;
  public totalProducts: number;
  public title: string | undefined;

  private cachedProducts: SmartSortCachedProduct[];
  private nextPageProps: SmartSortNextPageProps;
  private nextPageUrl: string;

  constructor(props: SmartSortProps) {
    this.searchFacets = props.searchFacets;
    this.nextPageUrl = props.nextPageUrl;
    this.nextPageProps = props.nextPageProps;
    this.products = props.products.map((p) => new SmartSortProduct(p));
    this.cachedProducts = props.cachedProducts.map(
      (p) => new SmartSortCachedProduct(p)
    );
    this.totalProducts = this.products.length + this.cachedProducts.length;
    this.title = props.title;
  }

  public hasNextPage(): boolean {
    return this.cachedProducts.length > 0;
  }

  public async nextPage(): Promise<void> {
    const nextPageProducts = this.cachedProducts.slice(
      0,
      this.nextPageProps.pageSize
    );
    const body: FetchSmartSortNextPageBody = {
      object_ids: nextPageProducts.map((p) => p.objectId),
      search_attributes: this.nextPageProps.searchAttributes,
      store_id: this.nextPageProps.storeId,
    };

    const resp = (await api.post(
      this.nextPageUrl,
      body
    )) as FetchSmartSortNextPageApiResponse;

    const mapResult = new Map(
      resp.products.map((obj) => [obj.object_id, obj.search_attributes])
    );

    nextPageProducts.forEach((p) => {
      const attributes = mapResult.get(p.objectId);
      if (attributes !== undefined) {
        this.products.push(
          new SmartSortProduct({
            ...p.serialize(),
            attributes: attributes,
          })
        );
      }
    });

    this.cachedProducts = this.cachedProducts.slice(
      this.nextPageProps.pageSize
    );
  }

  public serialize(): SmartSortProps {
    return {
      cachedProducts: this.cachedProducts.map((p) => p.serialize()),
      nextPageProps: this.nextPageProps,
      nextPageUrl: this.nextPageUrl,
      products: this.products.map((p) => p.serialize()),
      searchFacets: this.searchFacets,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): SmartSort {
    return new SmartSort(typedJSONParse<SmartSortProps>(serialized));
  }
}

export class SmartSortClient {
  private generateProducts(
    products: FetchSmartSortProduct[] | null,
    impressUrl: string,
    clickUrl: string,
    identifier: JaneDMIdentifiers
  ): GeneratedProducts {
    const result: GeneratedProducts = {
      cachedProducts: [],
      products: [],
    };

    products?.forEach((responseProduct) => {
      const product: SmartSortCachedProductProps = {
        clickPayload: {
          adToken: responseProduct.ad_token,
          janeDeviceId: identifier.jdid,
          kevelToken: responseProduct.kevel_token,
          myHighD: responseProduct.my_high_d,
          productId: responseProduct.product_id,
        },
        clickUrl: clickUrl,
        impressPayload: {
          adToken: responseProduct.ad_token,
          distinctId: identifier.mixpanelDistinctId ?? identifier.jdid,
          janeDeviceId: identifier.jdid,
          kevelToken: responseProduct.kevel_token,
          myHighD: responseProduct.my_high_d,
          productId: responseProduct.product_id,
        },
        impressUrl: impressUrl,
        isSponsored: !!responseProduct.flight_id,
        objectId: responseProduct.object_id,
      };

      if (responseProduct.search_attributes !== undefined) {
        result.products.push({
          ...product,
          attributes: responseProduct.search_attributes,
        });
      } else {
        result.cachedProducts.push(product);
      }
    });

    return result;
  }

  private buildFetchSmartSortBody(
    props: CreateSmartSortProps
  ): FetchSmartSortBody {
    if (props.placement === placementCartToppers) {
      const { appMode, storeId, identifier, placement } = props;
      return {
        app_mode: appMode,
        jane_device_id: identifier.jdid,
        placement,
        store_id: storeId,
      };
    } else if (props.placement === placementRecommendedRow) {
      const {
        placement,
        appMode,
        storeId,
        identifier,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
      } = props;

      return {
        app_mode: appMode,
        jane_device_id: identifier.jdid,
        placement,
        search_attributes: searchAttributes,
        search_filter: searchFilter,
        search_optional_filters: searchOptionalFilters,
        store_id: storeId,
      };
    } else if (props.placement === placementMenuInlineTable) {
      const {
        placement,
        appMode,
        storeId,
        identifier,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        disableAds,
        maxProducts,
        numColumns,
        pageSize,
        searchFacets,
        searchSort,
      } = props;

      return {
        app_mode: appMode,
        disable_ads: disableAds,
        jane_device_id: identifier.jdid,
        max_products: maxProducts,
        num_columns: numColumns,
        page_size: pageSize,
        placement,
        search_attributes: searchAttributes,
        search_facets: searchFacets,
        search_filter: searchFilter,
        search_optional_filters: searchOptionalFilters,
        search_query: searchQuery,
        search_sort: searchSort,
        store_id: storeId,
      };
    } else {
      throw new SdkError(
        `Invalid Smart Sort Placement: ${(props as any).placement}`
      );
    }
  }

  public async createSmartSort(
    props: CreateSmartSortProps
  ): Promise<SmartSort> {
    const path = `/v2/smart`;
    const params: Params = {
      apiKey: props.apiKey,
      source: props.source,
      version: props.version,
    };
    const url = generateUrl(props.endpoint, path, params);
    const nextPagePath = `/v2/smartpage`;
    const nextPageUrl = generateUrl(props.endpoint, nextPagePath, params);

    const body = this.buildFetchSmartSortBody(props);

    const response = (await api.post(url, body)) as FetchSmartSortApiResponse;

    const { clickEndpoint: clickUrl, impressionEndpoint: impressUrl } =
      generateTrackingUrls(props.endpoint, params);

    const { search_facets: searchFacets, products: responseProducts } =
      response;
    const { products, cachedProducts } = this.generateProducts(
      responseProducts,
      impressUrl,
      clickUrl,
      props.identifier
    );

    return new SmartSort({
      cachedProducts: cachedProducts,
      nextPageProps: {
        pageSize: body.page_size ?? 60,
        searchAttributes: body.search_attributes,
        storeId: body.store_id,
      },
      nextPageUrl: nextPageUrl,
      products: products,
      searchFacets,
      ...(props.placement === placementRecommendedRow && {
        title: props.title,
      }),
    });
  }
}
