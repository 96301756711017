import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import truncate from 'lodash/truncate';

import type {
  AppMode,
  BrandCondition,
  BulkWeightCondition,
  KindCondition,
  MenuProduct,
  PriceId,
  ProductThresholdConditions,
  ReservationModes,
  SpecialConditions,
  SpecialType,
  Store,
  StoreSpecial,
  WeightCondition,
} from '@jane/shared/models';
import {
  QUALIFIED_GROUP_TYPE_MAP,
  format,
  formatCurrency,
  isBefore,
  to,
} from '@jane/shared/util';

import titleCase, { titleize } from './titleCase';

export const WEIGHT_OPTIONS = [
  { label: '1/2 G', value: 'half_gram' },
  { label: '1 G', value: 'gram' },
  { label: '2 G', value: 'two_gram' },
  { label: '1/8 OZ', value: 'eighth_ounce' },
  { label: '1/4 OZ', value: 'quarter_ounce' },
  { label: '1/2 OZ', value: 'half_ounce' },
  { label: '1 OZ', value: 'ounce' },
];

const WEEKEND_DAYS = ['saturday', 'sunday'];

const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

export const willStart = (special: StoreSpecial) =>
  special.enabled && special.start_date && isBefore(special.start_date);

export const getStartDisplay = (startDate: string | null | undefined) =>
  startDate ? `Starting ${to(startDate)}` : '';

export const getEnabledDaysDisplay = (special: StoreSpecial) => {
  const enabled = daysEnabled(special);

  if (arrayMatch(enabled, [...WEEKDAYS, ...WEEKEND_DAYS])) return 'Everyday';
  if (arrayMatch(enabled, WEEKEND_DAYS)) return 'Weekends';
  if (arrayMatch(enabled, WEEKDAYS)) return 'Weekdays';

  return enabled.map((day) => `${capitalize(day)}s`).join(', ');
};

const formatDate = (date: string) => format(date, 'M/D/YYYY');

export const getEnabledDatesDisplay = ({
  start_date,
  end_date,
}: StoreSpecial) => {
  if (start_date && end_date)
    return `${formatDate(start_date)} - ${formatDate(end_date)}`;
  if (start_date) return `Starts: ${formatDate(start_date)}`;
  if (end_date) return `Expires: ${formatDate(end_date)}`;

  return '';
};

const enabledOnDay = (special: StoreSpecial) => (day: string) =>
  (special as any)[`enabled_${day}`];

const arrayMatch = <T>(a: T[], b: T[]) => isEqual([...a].sort(), [...b].sort());

const daysEnabled = (special: StoreSpecial) =>
  [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ].filter(enabledOnDay(special));

const parseKindConditions = (
  kindConditions: readonly KindCondition[] | undefined
) =>
  kindConditions && kindConditions.length
    ? kindConditions.map((c) => {
        const brandSubtypeText = c.brand_subtype
          ? ` - ${titleCase(c.brand_subtype)}`
          : '';
        const lineageText = c.lineage ? ` - ${titleCase(c.lineage)}` : '';
        const rootSubtypeText = c.root_subtype
          ? ` - ${titleCase(c.root_subtype)}`
          : '';
        return `${titleCase(
          c.kind
        )}${rootSubtypeText}${brandSubtypeText}${lineageText}`;
      })
    : ['All Products'];

const parseBrandConditions = (
  brandConditions: readonly BrandCondition[] | undefined
) =>
  brandConditions && brandConditions.length ? brandConditions : ['All Brands'];

const parseWeightConditions = (
  weightConditions: readonly WeightCondition[] | undefined
) =>
  weightConditions && weightConditions.length
    ? weightConditions.map((weightCondition) => {
        const weightOption = WEIGHT_OPTIONS.find(
          (wo) => wo.value === weightCondition
        );
        return weightOption ? weightOption.label : '';
      })
    : ['All Weights'];

const parseBulkWeightConditions = (
  bulkWeightConditions?: readonly BulkWeightCondition[]
) => {
  if (!(bulkWeightConditions && bulkWeightConditions.length > 0)) {
    return ['All Weights'];
  }

  return compact(
    bulkWeightConditions.map((weightCondition) => {
      const weightOption = WEIGHT_OPTIONS.find(
        (wo) => wo.value === weightCondition.price_id
      );
      if (!weightOption) return null;

      const label = weightOption.label;
      const min = weightCondition.minimum_price;
      const max = weightCondition.maximum_price;

      if (!(min || max)) return label;
      const minMax = minMaxText(min, max);
      return `${label} ${minMax}`;
    })
  );
};

const minMaxText = (
  min: number | null | undefined,
  max: number | null | undefined
) => {
  const minText = min ? `Min: $${min}` : '';
  const maxText = max ? `Max: $${max}` : '';
  if (minText.length && maxText) return `${minText} - ${maxText}`;
  if (minText) return minText;
  return maxText;
};

const parseProductThresholdConditions = (
  thresholdConditions: ProductThresholdConditions | undefined
) => {
  if (thresholdConditions) {
    const min = thresholdConditions.minimum_price;
    const max = thresholdConditions.maximum_price;
    if (!(min || max)) {
      return [undefined];
    }
    return min && max
      ? [`Min: $${min}`, `Max: $${max}`]
      : min
      ? [`Min: $${min}`]
      : [`Max: $${max}`];
  }

  return [undefined];
};

const parseLineageConditions = (lineageCondition?: string) => {
  if (lineageCondition) {
    return [titleize(lineageCondition)];
  }
  return ['All Lineages'];
};

export const reservationModesDisplay = (
  reservationModes: ReservationModes | null
) => {
  if (!reservationModes) return ['Pickup', 'Delivery'];
  const applicableModes = pickBy(reservationModes, (value, _) => value as any);
  return keys(applicableModes).map((mode) => titleCase(mode));
};

export const getConditionDisplay = (conditions: SpecialConditions) => {
  if (conditions.cart_total)
    return [`Cart Total More Than $${conditions.cart_total.threshold}`];
  if (conditions.qualified_group)
    return [
      conditions.qualified_group.type === 'senior'
        ? `Seniors ${conditions.qualified_group.required_age} and over`
        : capitalize(conditions.qualified_group.type),
    ];
  if (conditions.product) {
    if (isEmpty(conditions.product)) return ['All Products'];

    if (
      conditions.product.included_product_ids &&
      conditions.product.included_product_ids.length
    ) {
      return [
        `Individual Products: ${conditions.product.included_product_ids.length}`,
      ].concat(parseWeightConditions(conditions.product.weights));
    }

    return parseKindConditions(conditions.product.kinds)
      .concat(
        parseBrandConditions(conditions.product.brands),
        parseWeightConditions(conditions.product.weights),
        parseProductThresholdConditions(
          conditions.product.product_threshold
        ) as string[],
        parseLineageConditions(conditions.product.lineage)
      )
      .filter(Boolean);
  }

  if (conditions.bulk_pricing) {
    if (isEmpty(conditions.bulk_pricing))
      return ['Bulk Pricing', 'All Products'];

    if (
      conditions.bulk_pricing.included_product_ids &&
      conditions.bulk_pricing.included_product_ids.length
    ) {
      return [
        'Bulk Pricing',
        `Individual Products: ${conditions.bulk_pricing.included_product_ids.length}`,
      ].concat(parseBulkWeightConditions(conditions.bulk_pricing.bulk_weights));
    }

    return parseKindConditions(conditions.bulk_pricing.kinds)
      .concat(
        parseBrandConditions(conditions.bulk_pricing.brands),
        parseBulkWeightConditions(conditions.bulk_pricing.bulk_weights),
        parseLineageConditions(conditions.bulk_pricing.lineage)
      )
      .filter(Boolean);
  }

  return null;
};

const typeAmountText = (special: StoreSpecial) => {
  const {
    discount_type,
    discount_dollar_amount,
    discount_percent,
    discount_target_price,
  } = special;

  if (discount_type === 'percent') {
    return `${discount_percent}% off`;
  }

  if (discount_type === 'target_price') {
    return `Target price: ${formatCurrency(discount_target_price)}`;
  }

  return `${formatCurrency(discount_dollar_amount)} off`;
};

export const detailText = ({
  special,
}: {
  special: StoreSpecial;
}): readonly string[] => {
  const { conditions, reservation_modes } = special;

  const conditionBadges = getConditionDisplay(conditions) || [];

  const reservationModeBadges = reservationModesDisplay(reservation_modes);

  const typeAmountBadge = typeAmountText(special);

  const dayBadge = getEnabledDaysDisplay(special);

  const dateBadge = getEnabledDatesDisplay(special);

  return compact(
    flatten(
      conditionBadges.concat(
        typeAmountBadge,
        dayBadge,
        dateBadge,
        reservationModeBadges
      )
    )
  );
};

export const doesSpecialApply = (
  appMode: AppMode,
  special: StoreSpecial,
  menuProduct: MenuProduct,
  weight?: PriceId
) => {
  const isProductSpecial = special.special_type === 'product';

  // safeguard against group special
  if (!isProductSpecial) return false;

  if (!weight) {
    // whitelist special based on the BE endpoint as the source of truth
    return menuProduct.special_id === special.id;
  }

  const specialWeights = special.conditions.product?.weights || [];
  return (
    menuProduct.applicable_special_ids?.includes(special.id) &&
    (specialWeights.length === 0 ||
      specialWeights.includes(weight as WeightCondition))
  );
};

export const formatDiscountAmount = ({
  discount_type,
  discount_percent,
  discount_dollar_amount,
  discount_target_price,
}: {
  discount_dollar_amount: number;
  discount_percent: number;
  discount_target_price: number;
  discount_type: string;
}) => {
  switch (discount_type) {
    case 'percent':
      return `${discount_percent}%`;
    case 'dollar_amount':
      return `$${discount_dollar_amount}`;
    case 'target_price':
      return `$${discount_target_price}`;
    default:
      throw new Error(`unknown special discount type: ${discount_type}`);
  }
};

export const formatTitle = (special: StoreSpecial) => {
  const {
    special_type,
    title,
    conditions,
    discount_type,
    discount_percent,
    discount_dollar_amount,
    discount_target_price,
  } = special;

  if (special_type === 'qualified_group' && conditions.qualified_group) {
    const discountDetails = formatDiscountAmount({
      discount_type,
      discount_percent,
      discount_dollar_amount,
      discount_target_price,
    });

    return `${
      conditions.qualified_group.type === 'medical'
        ? 'Medical patients'
        : QUALIFIED_GROUP_TYPE_MAP[conditions.qualified_group.type]
    } – ${discountDetails} OFF`;
  }

  return title;
};

const abbreviateLabel = (label: string, type: string) => {
  const matches = label.match(/(?<dollar>\$?)(?<rate>\d+.?\d+)(?<percent>%?)/i);

  if (isNil(matches)) return 'Deal';

  if (matches.groups['dollar']) {
    if (type === 'target_price') {
      return `$${matches.groups['rate']}`;
    } else {
      return `$${matches.groups['rate']} OFF`;
    }
  } else {
    return `${Math.round(parseFloat(matches.groups['rate']))}% OFF`;
  }
};

export const getDiscountLabel = (
  special: StoreSpecial,
  store: Partial<Store>
) => {
  switch (special.special_type) {
    case 'bundle':
      return store?.store_compliance_language_settings?.['bundle']
        ? truncate(
            capitalize(store.store_compliance_language_settings['bundle']),
            { length: 10 }
          )
        : 'Bundle';
    default:
      return abbreviateLabel(special.discount_amount, special.discount_type);
  }
};

export const getSpecialType = (
  specialType: SpecialType,
  store: Partial<Store>
) => {
  switch (specialType) {
    case 'cart_total':
      return 'Cart Total';
    case 'product':
      return 'Product';
    case 'bundle':
      return (
        capitalize(store?.store_compliance_language_settings?.['bundle']) ||
        'Bundle'
      );
    case 'bulk_pricing':
      return 'Bulk';
    default:
      return 'Deal';
  }
};
