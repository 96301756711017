import indexOf from 'lodash/indexOf';
import sortBy from 'lodash/sortBy';

import type { FilterSelectItem } from '@jane/search/types';
import type { Store } from '@jane/shared/models';

import { PRODUCT_ROOT_TYPES } from './constants';

/**
 * This method accepts filters, with a store's custom rows, row ranking and storeId.
 *
 * If a filter is not included in the custom rows, or product root types, it means the row
 * is hidden and the filter shouldn't be rendered.
 *
 * The storeId is required to remove it from the value to check against customRow items,
 * ex value will be BASE-68, while customRow value will be just BASE
 *  */

export const sortAndFilterCategories = (
  filters: FilterSelectItem[],
  customRanking?: Store['custom_product_type_ranking'],
  customRows?: Store['custom_rows'],
  storeId?: Store['id']
): FilterSelectItem[] => {
  return storeId
    ? sortBy(filters, ({ value }) =>
        indexOf(customRanking, value.replace(`-${storeId}`, ''))
      ).filter(
        ({ value }) =>
          PRODUCT_ROOT_TYPES.includes(value) ||
          (customRows && customRows.includes(value.replace(`-${storeId}`, '')))
      )
    : filters;
};
