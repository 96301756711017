import countBy from 'lodash/countBy';
import { useEffect, useState } from 'react';

import { trackLoadedCrmPoints } from '@jane/shared-ecomm/tracking';
import { Accordion } from '@jane/shared/components';
import type {
  CrmDetails,
  CrmIntegration,
  CrmRedemption,
  CrmReward,
  Id,
  ReservationMode,
  Store,
} from '@jane/shared/models';
import { useMobileMediaQuery } from '@jane/shared/reefer';

import {
  useCustomerDispatch,
  useCustomerSelector,
} from '../../../../../../lib/customer';
import {
  applyCrmRedemption,
  removeCrmRedemption,
} from '../../../../../../lib/customer/redux/cart';
import { useSource } from '../../../../../../lib/hooks/useSource';
import { getCrmIntegration } from '../../../../../../lib/sources/crmIntegration';
import { crmDiscountAmount } from '../../../../../lib/cart';
import { reservationModeLabel } from '../../../../../lib/store';
import {
  getCachedAuthCode,
  removeCachedAuthCode,
  setCachedAuthCode,
} from '../../util/cacheKeys';
import { CustomerLoyaltyPointsContent } from './customerLoyaltyPointsContent';
import { CustomerLoyaltyPointsAccordionHeader } from './customerLoyaltyPointsHeader';

interface LoyaltyPointsProps {
  appliedRedemptions?: readonly CrmRedemption[];
  cartUuid: string;
  headless?: boolean;
  loyaltyPointsStacking: boolean;
  phone: string | null;
  postDiscountSubtotal: number;
  reservationMode: ReservationMode;
  store: Store;
}

const NUM_REWARDS_TO_SHOW = 4;

const flattenRewards = (
  jane_redeemable_rewards: CrmReward[],
  store_redeemable_rewards: CrmReward[]
) => {
  const mapRewards = (rewards: CrmReward[]) =>
    rewards.map(({ reward_name, point_value }) => ({
      reward_name,
      point_value,
    }));
  return mapRewards(jane_redeemable_rewards).concat(
    mapRewards(store_redeemable_rewards)
  );
};

const sortRewards = (rewards: CrmReward[]) =>
  rewards
    .sort(
      (a, b) =>
        a.point_value - b.point_value ||
        a.reward_name.localeCompare(b.reward_name)
    )
    .slice(0, NUM_REWARDS_TO_SHOW);

const getAvailablePoints = (
  points: CrmIntegration['points'],
  appliedRedemptions: readonly CrmRedemption[]
) => {
  if (points === 0) return 0;
  const appliedPoints = appliedRedemptions.reduce(
    (sum, redemption) => (sum += redemption.point_value),
    0
  );

  return points - appliedPoints;
};

const onlineRedeemableRewards = (
  jane_redeemable: CrmReward[],
  availablePoints: number,
  postDiscountSubtotal: number,
  appliedRedemptionCounts: { [crm_reward_id: string]: number }
) => {
  const currentlyRedeemable = jane_redeemable.filter((reward) => {
    const discountAmount = crmDiscountAmount(postDiscountSubtotal, reward);

    const countsValid = reward.max_count
      ? (appliedRedemptionCounts[reward.crm_reward_id] || 0) < reward.max_count
      : true;
    const pointsValid =
      availablePoints >= reward.point_value &&
      discountAmount > 0 &&
      discountAmount <= postDiscountSubtotal;

    return countsValid && pointsValid;
  });
  return sortRewards(currentlyRedeemable);
};

export const CustomerLoyaltyPoints = ({
  cartUuid,
  headless = false,
  loyaltyPointsStacking = true,
  phone,
  postDiscountSubtotal,
  appliedRedemptions = [],
  reservationMode,
  store,
}: LoyaltyPointsProps) => {
  const { id: storeId, name: storeName } = store;
  const isMobile = useMobileMediaQuery({});

  const dispatch = useCustomerDispatch();
  const isApplyingCrmRedemption = useCustomerSelector(
    (state) => state.cart.isApplyingCrmRedemption
  );

  const storeRewardLabel =
    store?.store_compliance_language_settings?.['store_reward'];
  const storeReservationModeLabel = reservationModeLabel(
    store,
    reservationMode
  );
  const storeLoyaltyPointsLabel =
    store?.store_compliance_language_settings?.['loyalty_point'];

  const cacheArgs = { phone, storeId };
  const [authCode, setAuthCode] = useState<string | undefined>(
    getCachedAuthCode(cacheArgs)
  );

  const email = useCustomerSelector(({ customer }) => customer.email);
  // TODO(elliot): Add some cache busting if this auth code for some reason results in an error
  const { loading, data } = useSource(
    getCrmIntegration(storeId, phone, email, authCode)
  );

  const crmDetails: CrmDetails = {
    crmId: data?.integration_type === 'email' ? email : phone,
    integration_type: data?.integration_type,
  };

  useEffect(() => {
    const cachedAuthCode = getCachedAuthCode(cacheArgs);
    const setCache = !cachedAuthCode || cachedAuthCode !== authCode;
    if (
      authCode &&
      data?.crm_integration?.wallet_state === 'unlocked' &&
      setCache
    ) {
      setCachedAuthCode(cacheArgs, authCode);
    }
  }, [authCode, data?.crm_integration?.wallet_state]);

  useEffect(() => {
    if (!data?.crm_integration) return;
    const {
      crm_integration: { signed_up },
    } = data;

    if (!signed_up) {
      removeCachedAuthCode(cacheArgs);
    }
  }, [data?.crm_integration?.signed_up]);

  useEffect(() => {
    if (!data?.crm_integration) return;
    const {
      crm_integration: {
        crm_provider,
        signed_up,
        points,
        jane_redeemable_rewards,
        store_redeemable_rewards,
      },
    } = data;

    trackLoadedCrmPoints({
      storeHasProvider: true,
      memberPoints: signed_up ? points : null,
      crmProvider: crm_provider,
      crmRewards: flattenRewards(
        jane_redeemable_rewards,
        store_redeemable_rewards
      ),
    });
  }, [data]);

  if (!data?.crm_integration) return null;

  const {
    crm_integration: {
      crm_provider,
      points,
      jane_redeemable_rewards,
      store_redeemable_rewards,
      max_redemption_count,
    },
  } = data;

  const availablePoints = getAvailablePoints(points, appliedRedemptions);
  const appliedRedemptionCounts = countBy(appliedRedemptions, 'crm_reward_id');

  const maxRedemptionCountExceeded = Boolean(
    max_redemption_count && appliedRedemptions.length >= max_redemption_count
  );
  const redeemableOnline = maxRedemptionCountExceeded
    ? []
    : onlineRedeemableRewards(
        jane_redeemable_rewards,
        availablePoints,
        postDiscountSubtotal,
        appliedRedemptionCounts
      );
  const redeemableInStore = sortRewards(store_redeemable_rewards);

  const noRewards = !(redeemableOnline.length || redeemableInStore.length);

  const handleRemoveCrmRedemption = (id: string) => {
    dispatch(removeCrmRedemption(id as Id, crm_provider));
  };

  const handleApplyDiscount = (reward: CrmReward) => {
    if (isApplyingCrmRedemption) {
      return;
    }
    dispatch(
      applyCrmRedemption({
        crm_reward_id: reward.crm_reward_id,
        point_value: reward.point_value,
        reward: {
          type: reward.reward?.type,
          amount: reward.reward.amount,
        },
        reward_name: reward.reward_name,
        cart_uuid: cartUuid,
        crm_member_points: points,
        crm_provider: reward.crm_provider,
        redemption_url: reward.redemption_payload?.url,
        redemption_checksum: reward.redemption_payload?.checksum,
      })
    );
  };

  return headless ? (
    <CustomerLoyaltyPointsContent
      cartUuid={cartUuid}
      crmIntegration={data.crm_integration}
      error={data.error}
      handleApplyDiscount={handleApplyDiscount}
      headless={headless}
      loyaltyPointsStacking={loyaltyPointsStacking}
      maxRedemptionCountExceeded={maxRedemptionCountExceeded}
      noRewards={noRewards}
      onUnlockWalletSubmit={setAuthCode}
      crmDetails={crmDetails}
      redeemableInStore={redeemableInStore}
      redeemableOnline={redeemableOnline}
      reservationMode={storeReservationModeLabel}
      storeLoyaltyPointsLabel={storeLoyaltyPointsLabel}
      storeName={storeName}
      unlockLoading={Boolean(authCode) && loading}
    />
  ) : (
    <Accordion.Item id="store-rewards" defaultExpanded>
      <CustomerLoyaltyPointsAccordionHeader
        loading={loading}
        crmIntegration={data.crm_integration}
        availablePoints={availablePoints}
        appliedRedemptions={appliedRedemptions}
        storeRewardLabel={storeRewardLabel}
        handleRemoveCrmRedemption={handleRemoveCrmRedemption}
      />
      <Accordion.Content px={isMobile ? 24 : 40} pb={24} destroyOnClose>
        <CustomerLoyaltyPointsContent
          cartUuid={cartUuid}
          crmIntegration={data.crm_integration}
          error={data.error}
          handleApplyDiscount={handleApplyDiscount}
          loyaltyPointsStacking={loyaltyPointsStacking}
          maxRedemptionCountExceeded={maxRedemptionCountExceeded}
          noRewards={noRewards}
          onUnlockWalletSubmit={setAuthCode}
          crmDetails={crmDetails}
          redeemableInStore={redeemableInStore}
          redeemableOnline={redeemableOnline}
          reservationMode={storeReservationModeLabel}
          storeLoyaltyPointsLabel={storeLoyaltyPointsLabel}
          storeName={storeName}
          unlockLoading={Boolean(authCode) && loading}
        />
      </Accordion.Content>
    </Accordion.Item>
  );
};
